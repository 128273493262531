import React, { useEffect, useState } from "react"
import api from "api"
// import { useHarsco } from "shared"
import {
  CURRENT_PROFILE,
  GET_PROGRESS_STEP,
  SET_ACTIVE_STEP,
  SET_PROGRESS_STEP,
  SET_PROGRESS_STEP_ONLY,
  ALL_ODORS,
} from "store/reducer/actionType"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import {
  getBooleanValues,
  getObjectValues,
  getValues,
  // getOptions,
} from "routes/Utilities/util"
import { useHistory } from "react-router-dom"

let wastecharacterenhancer =
  (Component) =>
  ({ ...props }) => {
    const history = useHistory()
    const [showError, setShowError] = useState(false)
    const layersOpts = {
      id: 1,
      options: [
        // {
        //   l: "N/A",
        //   v: 0,
        // },
        {
          l: "Single Layered",
          v: 1,
        },
        {
          l: "Bi-Layered",
          v: 2,
        },
        {
          l: "Multi-Layered",
          v: 3,
        },
      ],
    }
    const viscosityOpts = {
      id: 2,
      options: [
        {
          l: "N/A",
          v: "0",
          id: 0,
        },
        {
          l: "Low(Water)",
          v: "1",
          id: 1,
        },
        {
          l: "Medium(Oil)",
          v: "2",
          id: 2,
        },
        {
          l: "High(Tar/Honey)",
          v: "3",
          id: 3,
        },
      ],
    }
    let specificGravityOpts = {
      id: 3,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "<0.8",
          v: 1,
        },
        {
          l: ">=0.8, <1",
          v: 2,
        },
        {
          l: ">=1, <1.2",
          v: 3,
        },
        {
          l: ">=1.2",
          v: 4,
        },
      ],
    }
    let flashPointOpts = {
      id: 4,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "<73",
          v: 1,
        },
        {
          l: "73-100",
          v: 2,
        },
        {
          l: "100",
          v: 3,
        },
        {
          l: "101-140",
          v: 4,
        },
        {
          l: "141-200",
          v: 5,
        },
        {
          l: ">200",
          v: 6,
        },
      ],
    }
    let corrosivityOpts = {
      id: 5,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "<=2.0",
          v: 1,
        },
        {
          l: "2.01-4.0",
          v: 2,
        },
        {
          l: "4.01-10.0",
          v: 3,
        },
        {
          l: "10.01-12.49",
          v: 4,
        },
        {
          l: ">=12.5",
          v: 5,
        },
      ],
    }
    let btulbOpts = {
      id: 6,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "<2000",
          v: 1,
        },
        {
          l: "2000-4999",
          v: 2,
        },
        {
          l: "5000-9999",
          v: 3,
        },
        {
          l: ">10000",
          v: 4,
        },
      ],
    }
    let boilingPointOpts = {
      id: 7,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "< 95°F(35°C)",
          v: 1,
        },
        {
          l: "> 95°F(35°C)",
          v: 2,
        },
      ],
    }
    // const allOdors = useSelector((state) => state.profileDetails.allOdors)
    let odoroptions = [
      {
        l: "None",
        v: "0",
        id: 0,
      },
      {
        l: "Mild",
        v: "1",
        id: 1,
      },
      {
        l: "Strong",
        v: "2",
        id: 2,
      },
    ]
    let odorOpts = {
      id: 8,
      options: odoroptions,
      // getOptions(allOdors, "odorDesc", "odorId", "l", "v", "id"),
    }
    let tocOpts = {
      id: 9,
      options: [
        {
          l: "N/A",
          v: 1,
        },
        {
          l: "<1%",
          v: 2,
        },
        {
          l: "1-10%",
          v: 3,
        },
        {
          l: ">10%",
          v: 4,
        },
      ],
    }

    const getAllOdors = async () => {
      try {
        let res = await api.g_getAllOdors()
        if (!res) return
        dispatch({ type: ALL_ODORS, payload: res })
      } catch (error) {}
    }

    useEffect(() => {
      getAllOdors()
    }, [])

    const initValues = {
      layered: { l: "Select Layers", v: null },
      viscosity: { l: "N/A", v: "0", id: 0 },
      specificGravity: { l: "N/A", v: 0 },
      specificGravityActual: "",
      flashPointFlag: { l: "N/A", v: 0 },
      flashpointActual: "",
      flashpointScale: "",
      phFlag: { l: "N/A", v: 0 },
      phExactValue: "",
      btuPerLbs: { l: "N/A", v: 0 },
      btuActual: "",
      boilingPointFlag: { l: "N/A", v: 0 },
      boilingPtActual: "",
      boilingScale: "",
      solidPct: "",
      sludgesPct: "",
      freeLiquidsPct: "",
      powderFlag: false,
      monolithFlag: false,
      debris: false,
      compressedGas: false,
      waterPercentage: "",
      pumpableWasteFlag: false,
      odorFlag: { l: "", v: "", id: null },
      colorDesc: "",
      halogensPct: "",
      noneFlag: false,
      odorDesc: "",
      wasteCharNoSelectionFlag: false,
      voc100Ppm: false,
      voc500Ppm: false,
      subjectToSubpartCcFlag: false,
      tocPercentage: { l: "N/A", v: 1 },
    }

    const [initialValues, setInitialValues] = useState(initValues)
    const { id, type } = useParams()
    const dispatch = useDispatch()
    const currentProfile = useSelector(
      (state) => state.profileDetails?.currentProfile
    )
    let valSchema = {
      viscosity: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string().nullable(),
          id: Yup.number().nullable().required("Viscosity is required"),
        })
        .nullable()
        .required("Please select the Viscosity"),
      layered: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number(),
        })
        .nullable()
        .required("Please select the Layer"),
      specificGravity: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number().nullable(),
        })
        .nullable()
        .required("Please select the Specific Gravity"),
      specificGravityActual: Yup.string().test(
        "validate",
        "Value should be in selected range",
        (data, specificGravityActual) => {
          const specificGravityField = data
          if (
            specificGravityActual?.parent?.specificGravity?.l === "N/A" ||
            specificGravityField === undefined ||
            specificGravityField === null
          ) {
            return true
          }
          return specificGravityValidate(
            specificGravityActual?.parent?.specificGravity,
            specificGravityField
          )
        }
      ),
      flashPointFlag: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number().nullable(),
        })
        .nullable()
        .required("Please select the Flash Point"),
      flashpointActual: Yup.string().test(
        "validate",
        "Value should be in selected range",
        (data, flashpointActual1) => {
          const flashPointField = data
          if (
            flashpointActual1?.parent?.flashPointFlag?.l === "N/A" ||
            flashPointField === undefined ||
            flashPointField === null
          ) {
            return true
          }
          return flashPointValidate(
            flashpointActual1?.parent?.flashPointFlag,
            flashPointField
            // flashpointActual1?.parent?.flashpointScale
          )
        }
      ),
      flashpointScale: Yup.string().test(
        "validate",
        "Degree is required",
        (data, flashpointScale1) => {
          const falshpointScaleField = data?.toString()?.trimEnd()
          if (
            flashpointScale1?.parent?.flashpointActual === null ||
            flashpointScale1?.parent?.flashpointActual === undefined ||
            flashpointScale1?.parent?.flashpointActual === ""
          ) {
            return true
          } else {
            if (
              String(flashpointScale1?.parent?.flashpointActual)?.length > 0
            ) {
              return falshpointScaleField !== undefined &&
                falshpointScaleField !== null &&
                falshpointScaleField !== ""
                ? true
                : false
            }
          }
        }
      ),
      phFlag: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number().nullable(),
        })
        .nullable()
        .required("Please select the Corrosivity (Ph)"),
      phExactValue: Yup.string().test(
        "validate",
        "Value should be in selected range",
        (data, phExactValue) => {
          const phFlagField = data
          if (
            phExactValue?.parent?.phFlag?.l === "N/A" ||
            phFlagField === undefined ||
            phFlagField === null
          ) {
            return true
          }
          return phValidate(phExactValue?.parent?.phFlag, phFlagField)
        }
      ),
      btuPerLbs: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number().nullable(),
        })
        .nullable()
        .required("Please select the BTU/LB"),
      btuActual: Yup.string().test(
        "validate",
        "Value should be in selected range",
        (data, btuActual) => {
          const btuField = data
          if (
            btuActual?.parent?.btuPerLbs?.l === "N/A" ||
            btuField === undefined ||
            btuField === null
          ) {
            return true
          }
          return btuValidate(btuActual?.parent?.btuPerLbs, btuField)
        }
      ),
      boilingPointFlag: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number().nullable(),
        })
        .nullable()
        .required("Please select the Boiling Point"),
      boilingScale: Yup.string(),
      // boilingScale: Yup.string().when("boilingPointFlag.l", {
      //   is: (val) => val !== "N/A",
      //   then: Yup.string().required("Please select degree"),
      //   otherwise: Yup.string(),
      // }),
      boilingPtActual: Yup.string().test(
        "validate",
        "Value should be in selected range",
        (data, boilingPtActual) => {
          const boilingPointField = data
          if (
            boilingPtActual?.parent?.boilingPointFlag?.l === "N/A" ||
            boilingPointField === undefined ||
            boilingPointField === null
          ) {
            return true
          }
          return boilingPointValidate(
            boilingPtActual?.parent?.boilingPointFlag,
            boilingPointField,
            boilingPtActual?.parent?.boilingScale
          )
        }
      ),
      solidPct: Yup.string()
        .test(
          "is-valid-range",
          "Invalid range format or values",
          function (value) {
            if (!value) return true
            const singleValuePattern = /^(0|[1-9][0-9]?|100)$/
            const rangePattern = /^(0|[1-9][0-9]?)\-([1-9][0-9]?|100)$/
            if (singleValuePattern.test(value)) {
              const singleValue = parseInt(value, 10)
              return singleValue >= 0 && singleValue <= 100
            } else if (rangePattern.test(value)) {
              const [start, end] = value.split("-").map(Number)
              return start < end && start >= 0 && end <= 100
            }
            return false
          }
        )
        .required("Solid % is Required"),
      sludgesPct: Yup.string()
        .test(
          "is-valid-range",
          "Invalid range format or values",
          function (value) {
            if (!value) return true
            const singleValuePattern = /^(0|[1-9][0-9]?|100)$/
            const rangePattern = /^(0|[1-9][0-9]?)\-([1-9][0-9]?|100)$/
            if (singleValuePattern.test(value)) {
              const singleValue = parseInt(value, 10)
              return singleValue >= 0 && singleValue <= 100
            } else if (rangePattern.test(value)) {
              const [start, end] = value.split("-").map(Number)
              return start < end && start >= 0 && end <= 100
            }
            return false
          }
        )
        .required("Sludges % is Required"),
      freeLiquidsPct: Yup.string()
        .test(
          "is-valid-range",
          "Invalid range format or values",
          function (value) {
            if (!value) return true
            const singleValuePattern = /^(0|[1-9][0-9]?|100)$/
            const rangePattern = /^(0|[1-9][0-9]?)\-([1-9][0-9]?|100)$/
            if (singleValuePattern.test(value)) {
              const singleValue = parseInt(value, 10)
              return singleValue >= 0 && singleValue <= 100
            } else if (rangePattern.test(value)) {
              const [start, end] = value.split("-").map(Number)
              return start < end && start >= 0 && end <= 100
            }
            return false
          }
        )
        .required("Free Liquids % is Required"),
      powderFlag: Yup.boolean(),
      // .when(
      //   ["solidPct", "sludgesPct", "freeLiquidsPct"],
      //   {
      //     is: (values) =>
      //       // (!values?.monolithFlag && !values?.debris && !values?.compressedGas) && (
      //       (  (values?.solidPct === "0" || values?.solidPct === 0 || values?.solidPct === "") &&
      //         (values?.sludgesPct === "0" || values?.sludgesPct === 0 || values?.sludgesPct === "") &&
      //         (values?.freeLiquidsPct === "0" || values?.freeLiquidsPct === 0 || values?.freeLiquidsPct === "")
      //       ),
      //     then: Yup.boolean().required("Atleast one of the above should be selected")
      //   }
      // ),
      monolithFlag: Yup.boolean(),
      // .when(
      //   ["solidPct", "sludgesPct", "freeLiquidsPct"],
      //   {
      //     is: (values) =>
      //       // (!values?.powderFlag && !values?.debris && !values?.compressedGas) && (
      //       ( (values?.solidPct === "0" || values?.solidPct === 0 || values?.solidPct === "") &&
      //         (values?.sludgesPct === "0" || values?.sludgesPct === 0 || values?.sludgesPct === "") &&
      //         (values?.freeLiquidsPct === "0" || values?.freeLiquidsPct === 0 || values?.freeLiquidsPct === "")
      //       ),
      //     then: Yup.boolean().required("Atleast one of the above should be selected")
      //   }
      // ),
      debris: Yup.boolean(),
      // .when(
      //   ["solidPct", "sludgesPct", "freeLiquidsPct"],
      //   {
      //     is: (values) =>
      //       // (!values?.monolithFlag && !values?.powderFlag && !values?.compressedGas) && (
      //       (  (values?.solidPct === "0" || values?.solidPct === 0 || values?.solidPct === "") &&
      //         (values?.sludgesPct === "0" || values?.sludgesPct === 0 || values?.sludgesPct === "") &&
      //         (values?.freeLiquidsPct === "0" || values?.freeLiquidsPct === 0 || values?.freeLiquidsPct === "")
      //       ),
      //     then: Yup.boolean().required("Atleast one of the above should be selected")
      //   }
      // ),
      compressedGas: Yup.boolean(),
      // .when(
      //   ["solidPct", "sludgesPct", "freeLiquidsPct"],
      //   {
      //     is: (values) =>
      //       // (values?.monolithFlag === false && values?.debris === false && values?.powderFlag === false) &&
      //       (
      //         (values?.solidPct === "0" || values?.solidPct === 0 || values?.solidPct === "") &&
      //         (values?.sludgesPct === "0" || values?.sludgesPct === 0 || values?.sludgesPct === "") &&
      //         (values?.freeLiquidsPct === "0" || values?.freeLiquidsPct === 0 || values?.freeLiquidsPct === "")
      //       ),
      //     then: Yup.boolean().required("Atleast one of the above should be selected")
      //   }
      // ),
      waterPercentage: Yup.number()
        .min(0, "Water Percentage must be at least 0")
        .max(100, "Water Percentage must be at most 100"),
      // .required("Water Percentage is required"),
      odorFlag: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string().nullable(),
          id: Yup.number().nullable().required("Odor is required"),
        })
        .nullable()
        .required("Please select the Odor"),
      colorDesc: Yup.string().required("Please enter the Color"),
      halogensPct: Yup.string().required(
        "Please enter the Halogens Percentage"
      ),
      pumpableWasteFlag: Yup.boolean(),
      noneFlag: Yup.boolean(),
      odorDesc: Yup.string().when("odorFlag", {
        is: (odorFlag) => odorFlag?.id === 1,
        then: Yup.string().required(
          "Odor Description is required when odorFlag is mild"
        ),
      }),
      wasteCharNoSelectionFlag: Yup.boolean(),
      voc100Ppm: Yup.boolean(),
      voc500Ppm: Yup.boolean(),
      subjectToSubpartCcFlag: Yup.boolean(),
      tocPercentage: Yup.object()
        .shape({
          l: Yup.string().nullable(),
          v: Yup.number().nullable(),
        })
        .nullable(),
    }

    const onSubmit = async (formData) => {
      let required_count = formData?.odorFlag?.v === "1" ? 14 : 13
      const payload = {
        profileRequestId: currentProfile?.profileRequestId || id,
        viscosity: formData?.viscosity?.v || "",
        layered: formData?.layered?.v,
        specificGravity: formData?.specificGravity?.v,
        specificGravityActual:
          formData?.specificGravityActual?.toString() || "",
        flashPointFlag: formData?.flashPointFlag?.v,
        flashpointActual: formData?.flashpointActual?.toString() || "",
        flashpointScale: formData?.flashpointScale || "",
        phFlag: formData?.phFlag?.v,
        phExactValue: formData?.phExactValue?.toString() || "",
        btuPerLbs: formData?.btuPerLbs?.v,
        btuActual: formData?.btuActual?.toString() || "",
        boilingPointFlag: formData?.boilingPointFlag?.v,
        boilingPtActual: formData?.boilingPtActual?.toString() || "",
        boilingScale: formData?.boilingScale || "",
        solidPct: `${formData?.solidPct}` || "",
        sludgesPct: `${formData?.sludgesPct}` || "",
        freeLiquidsPct: `${formData?.freeLiquidsPct}` || "",
        powderFlag: formData?.powderFlag,
        monolithFlag: formData?.monolithFlag ? 1 : 0,
        debris: formData?.debris,
        compressedGas: formData?.compressedGas,
        waterPercentage: formData?.waterPercentage || 0,
        odorFlag: formData?.odorFlag?.v || "",
        colorDesc: formData?.colorDesc || "",
        halogensPct: formData?.halogensPct || "",
        pumpableWasteFlag: formData?.pumpableWasteFlag ? 1 : 0,
        noneFlag: formData?.noneFlag,
        odorDesc: formData?.odorDesc || "",
        wasteCharNoSelectionFlag: formData?.wasteCharNoSelectionFlag,
        voc100Ppm: formData?.voc100Ppm ? 1 : 0,
        voc500Ppm: formData?.voc500Ppm ? 1 : 0,
        subjectToSubpartCcFlag: formData?.subjectToSubpartCcFlag || false,
        tocPercentage: formData?.tocPercentage?.v || 0,
      }
      let checksFlag =
        payload?.powderFlag ||
        payload?.monolithFlag ||
        payload?.debris ||
        payload?.compressedGas
      let solidRange = payload?.solidPct && payload?.solidPct.split("-")
      let sludgeRange = payload?.sludgesPct && payload?.sludgesPct.split("-")
      let freeLiquidsRange =
        payload?.freeLiquidsPct && payload?.freeLiquidsPct.split("-")
      let validateFlags =
        Number(solidRange[0]) > 0 ||
        Number(sludgeRange[0]) > 0 ||
        Number(freeLiquidsRange[0]) > 0 ||
        checksFlag
      let boilingPointError =
        payload?.boilingPointFlag !== 0 &&
        payload?.boilingPtActual !== undefined &&
        payload?.boilingPtActual !== null &&
        String(payload?.boilingPtActual).trim() !== "" &&
        (payload?.boilingScale === undefined ||
          payload?.boilingScale === null ||
          String(payload?.boilingScale).trim() === "")
          ? true
          : false
      try {
        if (validateFlags && !boilingPointError) {
          props.setShowAlert(false)
          let res = await api.p_saveWasteCharacteristics(payload)
          if (!res) return
          setProgress(formData, required_count)
          getProfileData(currentProfile?.profileRequestId)
        }
      } catch (err) {
        // dispatch({
        //   type: "SET_CHANGE_CUSTOMER",
        //   status: false,
        //   customer_name: changeCustomer?.customer,
        //   source: false,
        // })
      }
    }

    const onSubmitProgress = async (formData, progStep) => {
      let required_count = formData?.odorFlag?.v === "1" ? 14 : 13
      const payload = {
        profileRequestId: currentProfile?.profileRequestId || id,
        viscosity: formData?.viscosity?.v || "",
        layered: formData?.layered?.v,
        specificGravity: formData?.specificGravity?.v,
        specificGravityActual:
          formData?.specificGravityActual?.toString() || "",
        flashPointFlag: formData?.flashPointFlag?.v,
        flashpointActual: formData?.flashpointActual?.toString() || "",
        flashpointScale: formData?.flashpointScale || "",
        phFlag: formData?.phFlag?.v,
        phExactValue: formData?.phExactValue?.toString() || "",
        btuPerLbs: formData?.btuPerLbs?.v,
        btuActual: formData?.btuActual?.toString() || "",
        boilingPointFlag: formData?.boilingPointFlag?.v,
        boilingPtActual: formData?.boilingPtActual?.toString() || "",
        boilingScale: formData?.boilingScale || "",
        solidPct: `${formData?.solidPct}` || "",
        sludgesPct: `${formData?.sludgesPct}` || "",
        freeLiquidsPct: `${formData?.freeLiquidsPct}` || "",
        powderFlag: formData?.powderFlag,
        monolithFlag: formData?.monolithFlag ? 1 : 0,
        debris: formData?.debris,
        compressedGas: formData?.compressedGas,
        waterPercentage: formData?.waterPercentage || 0,
        odorFlag: formData?.odorFlag?.v || "",
        colorDesc: formData?.colorDesc || "",
        halogensPct: formData?.halogensPct || "",
        pumpableWasteFlag: formData?.pumpableWasteFlag ? 1 : 0,
        noneFlag: formData?.noneFlag,
        odorDesc: formData?.odorDesc || "",
        wasteCharNoSelectionFlag: formData?.wasteCharNoSelectionFlag,
        voc100Ppm: formData?.voc100Ppm ? 1 : 0,
        voc500Ppm: formData?.voc500Ppm ? 1 : 0,
        subjectToSubpartCcFlag: formData?.subjectToSubpartCcFlag || false,
        tocPercentage: formData?.tocPercentage?.v || 0,
      }
      let checksFlag =
        payload?.powderFlag ||
        payload?.monolithFlag ||
        payload?.debris ||
        payload?.compressedGas
      let validateFlags =
        payload?.solidPct?.trim() > 0 ||
        payload?.sludgesPct?.trim() > 0 ||
        payload?.freeLiquidsPct?.trim() > 0 ||
        checksFlag
      let boilingPointError =
        payload?.boilingPointFlag !== 0 &&
        payload?.boilingPtActual !== undefined &&
        payload?.boilingPtActual !== null &&
        String(payload?.boilingPtActual).trim() !== "" &&
        (payload?.boilingScale === undefined ||
          payload?.boilingScale === null ||
          String(payload?.boilingScale).trim() === "")
          ? true
          : false
      try {
        if (validateFlags && !boilingPointError) {
          props.setShowAlert(false)
          let res = await api.p_saveWasteCharacteristics(payload)
          if (!res) return
          setProgress(formData, required_count)
          getProfileData(currentProfile?.profileRequestId)
        }
      } catch (err) {}
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }
    const getProfileData = async (reqId) => {
      const req = {
        profileRequestId: id || reqId,
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }

    const goToProfilePages = (page) => {
      history.push(`/profiles/${page}`)
    }

    const onChange = (e, values) => {
      values = { ...values, [e.target.name]: e.target.value.trim() }
    }

    const specificGravityValidate = (sg, sg_actual) => {
      let isValid = true
      let sg_actual_trim =
        String(sg_actual).startsWith("<") || String(sg_actual).startsWith(">")
          ? String(sg_actual).slice(1)
          : sg_actual
      switch (sg?.v) {
        case 1:
          isValid = parseFloat(sg_actual_trim) < 0.8
          break
        case 2:
          isValid =
            parseFloat(sg_actual_trim) >= 0.8 && parseFloat(sg_actual_trim) < 1
          break
        case 3:
          isValid =
            parseFloat(sg_actual_trim) >= 1 && parseFloat(sg_actual_trim) < 1.2
          break
        case 4:
          isValid = parseFloat(sg_actual_trim) >= 1.2
          break
        case 0:
          isValid = true
          break
        default:
          isValid = false
      }
      return isValid
    }

    const farenheitToCelcius = (fahrenheit) => {
      return ((fahrenheit - 32) * 5) / 9
    }

    const flashPointValidate = (fp, fp_actual) => {
      let isValid = true
      let fp_actual_trim =
        String(fp_actual).startsWith("<") || String(fp_actual).startsWith(">")
          ? String(fp_actual).slice(1)
          : fp_actual
      switch (fp?.v) {
        case 1:
          isValid = parseFloat(fp_actual_trim) < 73
          // fp_scale === "F"
          //   ? fp_actual < 73
          //   : fp_actual < farenheitToCelcius(73)
          break
        case 2:
          isValid =
            parseFloat(fp_actual_trim) >= 73 && parseFloat(fp_actual_trim) < 100
          // fp_scale === "F"
          // ? fp_actual >= 73 && fp_actual < 100
          // : fp_actual >= farenheitToCelcius(73) &&
          //   fp_actual < farenheitToCelcius(100)
          break
        case 3:
          isValid = parseFloat(fp_actual_trim) === 100
          // fp_scale === "F"
          //   ? fp_actual == 100
          //   : fp_actual == farenheitToCelcius(100)
          break
        case 4:
          isValid =
            parseFloat(fp_actual_trim) >= 101 &&
            parseFloat(fp_actual_trim) <= 140
          // fp_scale === "F"
          //   ? fp_actual >= 101 && fp_actual <= 140
          //   : fp_actual >= farenheitToCelcius(101) &&
          //     fp_actual <= farenheitToCelcius(140)
          break
        case 5:
          isValid =
            parseFloat(fp_actual_trim) >= 141 &&
            parseFloat(fp_actual_trim) <= 200
          // fp_scale === "F"
          //   ? fp_actual >= 141 && fp_actual <= 200
          //   : fp_actual >= farenheitToCelcius(141) &&
          //     fp_actual <= farenheitToCelcius(200)
          break
        case 6:
          isValid = parseFloat(fp_actual_trim) > 200
          // fp_scale === "F"
          //   ? fp_actual > 200
          //   : fp_actual > farenheitToCelcius(200)
          break
        case 0:
          isValid = true
          break
        default:
          isValid = false
      }
      return isValid
    }

    const phValidate = (ph, ph_exact) => {
      let ph_exact_trim =
        String(ph_exact).startsWith("<") || String(ph_exact).startsWith(">")
          ? String(ph_exact).slice(1)
          : ph_exact
      let isValid = true
      switch (ph?.v) {
        case 1:
          isValid = parseFloat(ph_exact_trim) <= 2.0
          break
        case 2:
          isValid =
            parseFloat(ph_exact_trim) >= 2.01 &&
            parseFloat(ph_exact_trim) <= 4.0
          break
        case 3:
          isValid =
            parseFloat(ph_exact_trim) >= 4.01 &&
            parseFloat(ph_exact_trim) <= 10.0
          break
        case 4:
          isValid =
            parseFloat(ph_exact_trim) >= 10.01 &&
            parseFloat(ph_exact_trim) <= 12.49
          break
        case 5:
          isValid = parseFloat(ph_exact_trim) >= 12.5
          break
        case 0:
          isValid = true
          break
        default:
          isValid = false
      }
      return isValid
    }

    const btuValidate = (btu, btu_actual) => {
      let btu_actual_trim =
        String(btu_actual).startsWith("<") || String(btu_actual).startsWith(">")
          ? String(btu_actual).slice(1)
          : btu_actual
      let gtFlag = String(btu_actual).startsWith(">")
      let ltFlag = String(btu_actual).startsWith("<")
      let symbolValid = false
      let isValid = true
      switch (btu?.v) {
        case 1:
          symbolValid =
            gtFlag === true
              ? !(parseFloat(btu_actual_trim) >= 2000)
              : ltFlag === true
              ? parseFloat(btu_actual_trim) < 2000
              : false
          isValid =
            gtFlag === true || ltFlag === true
              ? symbolValid && parseFloat(btu_actual_trim) < 2000
              : parseFloat(btu_actual_trim) < 2000
          break
        case 2:
          symbolValid =
            gtFlag === true
              ? !(
                  parseFloat(btu_actual_trim) < 2000 ||
                  parseFloat(btu_actual_trim) > 4999
                )
              : ltFlag === true
              ? parseFloat(btu_actual_trim) > 2000 &&
                parseFloat(btu_actual_trim) <= 4999
              : false
          isValid =
            gtFlag === true || ltFlag === true
              ? symbolValid &&
                parseFloat(btu_actual_trim) >= 2000 &&
                parseFloat(btu_actual_trim) <= 4999
              : parseFloat(btu_actual_trim) >= 2000 &&
                parseFloat(btu_actual_trim) <= 4999
          break
        case 3:
          symbolValid =
            gtFlag === true
              ? !(
                  parseFloat(btu_actual_trim) < 5000 ||
                  parseFloat(btu_actual_trim) >= 9999
                )
              : ltFlag === true
              ? parseFloat(btu_actual_trim) > 5000 &&
                parseFloat(btu_actual_trim) <= 9999
              : false
          isValid =
            gtFlag === true || ltFlag === true
              ? symbolValid &&
                parseFloat(btu_actual_trim) >= 5000 &&
                parseFloat(btu_actual_trim) < 10000
              : parseFloat(btu_actual_trim) >= 5000 &&
                parseFloat(btu_actual_trim) < 10000
          break
        case 4:
          symbolValid =
            gtFlag === true
              ? !(parseFloat(btu_actual_trim) >= 10000)
              : ltFlag === true
              ? parseFloat(btu_actual_trim) <= 10000
              : false
          isValid =
            gtFlag === true || ltFlag === true
              ? symbolValid && parseFloat(btu_actual_trim) > 10000
              : parseFloat(btu_actual_trim) > 10000
          break
        case 0:
          isValid = true
          break
        default:
          isValid = false
      }
      symbolValid = false
      return isValid
    }

    const boilingPointValidate = (bp, bp_actual, bp_scale) => {
      let bp_actual_trim =
        String(bp_actual).startsWith("<") || String(bp_actual).startsWith(">")
          ? String(bp_actual).slice(1)
          : bp_actual
      let isValid = true
      switch (bp?.v) {
        case 1:
          // isValid =
          //   bp_scale === "F"
          //     ? bp_actual < 95
          //     : bp_actual < farenheitToCelcius(95)

          //validation, on type
          if (bp_scale === "F") isValid = parseFloat(bp_actual_trim) < 95
          else if (bp_scale === "C")
            isValid = parseFloat(bp_actual_trim) < farenheitToCelcius(95)
          else
            isValid =
              parseFloat(bp_actual_trim) < 95 ||
              parseFloat(bp_actual_trim) < farenheitToCelcius(95)
          break
        case 2:
          // isValid =
          //   bp_scale === "F"
          //     ? bp_actual > 95
          //     : bp_actual > farenheitToCelcius(95)

          //validation, on type
          if (bp_scale === "F") isValid = parseFloat(bp_actual_trim) > 95
          else if (bp_scale === "C")
            isValid = parseFloat(bp_actual_trim) > farenheitToCelcius(95)
          else
            isValid =
              parseFloat(bp_actual_trim) > 95 ||
              parseFloat(bp_actual_trim) > farenheitToCelcius(95)
          break
        case 0:
          isValid = true
          break
        default:
          isValid = false
      }
      return isValid
    }

    const backAction = () => {
      dispatch({ type: SET_ACTIVE_STEP, payload: 1 })
    }

    const getCodeOptions = (keys) => {
      switch (keys) {
        case "layered":
          return layersOpts?.options
        case "viscosity":
          return viscosityOpts?.options
        case "specificGravity":
          return specificGravityOpts?.options
        case "flashPointFlag":
          return flashPointOpts?.options
        case "phFlag":
          return corrosivityOpts?.options
        case "btuPerLbs":
          return btulbOpts?.options
        case "boilingPointFlag":
          return boilingPointOpts?.options
        case "odorFlag":
          return odorOpts?.options
        case "tocPercentage":
          return tocOpts?.options

        default:
          return []
      }
    }

    const setProgress = (data, length) => {
      let count = 0
      let non_requiredcount = 0
      for (var prop in initValues) {
        if (prop == "layered" || prop == "viscosity" || prop == "odorFlag") {
          if (data[prop]?.v) {
            count = count + 1
          }
        } else if (
          prop == "specificGravity" ||
          prop == "flashPointFlag" ||
          prop == "phFlag" ||
          prop == "btuPerLbs" ||
          prop == "boilingPointFlag"
        ) {
          if (data[prop]?.v >= 0) {
            count = count + 1
          }
        } else if (prop == "tocPercentage") {
          if (data[prop]?.v) {
            non_requiredcount = non_requiredcount + 1
          }
        } else if (
          prop == "halogensPct" ||
          prop == "colorDesc" ||
          prop == "solidPct" ||
          prop == "sludgesPct" ||
          prop == "freeLiquidsPct"
        ) {
          if (data[prop]) {
            count = count + 1
          }
        }
        // else if(prop == "solidPct" ||
        //   prop == "sludgesPct" ||
        //   prop == "freeLiquidsPct") {
        //     if(data[prop] || data[prop] >= 0){
        //       count = count + 1
        //     }
        // }
        else if (prop == "odorDesc") {
          if (data?.["odorFlag"]?.v == "1" && data[prop]) {
            count = count + 1
          }
        } else {
          if (data[prop]) {
            non_requiredcount = non_requiredcount + 1
          }
        }
      }
      dispatch({
        type: SET_PROGRESS_STEP,
        step: 2,
        progressStep: props.showAlert ? props.seekingStep + 1 : 3,
        percentage: (count / length) * 100,
        numerator: count,
        denominator: length,
      })
    }

    const calcProgress = async (count, leng) => {
      await dispatch({
        type: GET_PROGRESS_STEP,
        step: 2,
        progressStep: 2,
        percentage: (count / leng) * 100,
        numerator: count,
        denominator: leng,
      })
    }

    useEffect(() => {
      let data = {}
      if (currentProfile?.generatorId || type == "viewprofile") {
        let count = 0
        let non_requiredcount = 0
        let required_count = currentProfile?.["odorFlag"]?.v === "1" ? 14 : 13
        for (var prop in initValues) {
          if (
            prop == "powderFlag" ||
            prop == "monolithFlag" ||
            prop == "debris" ||
            prop == "compressedGas" ||
            prop == "pumpableWasteFlag" ||
            prop == "noneFlag" ||
            // prop == "wasteCharNoSelectionFlag" ||
            prop == "subjectToSubpartCcFlag"
          ) {
            data[prop] = getBooleanValues(
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]) {
              non_requiredcount = non_requiredcount + 1
            }
          } else if (
            prop === "wasteCharNoSelectionFlag" ||
            prop === "voc100ppm" ||
            prop === "voc500ppm"
          ) {
            // let flag = (currentProfile?.["voc100Ppm"] || currentProfile?.["voc500Ppm"]) ? false : true
            //   if(prop === "wasteCharNoSelectionFlag") {
            //     data[prop] = getBooleanValues(
            //     currentProfile,
            //     prop,
            //     flag
            //   )
            // }
            data[prop] = getBooleanValues(
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]) {
              non_requiredcount = non_requiredcount + 1
            }
          } else if (prop == "layered") {
            data[prop] = getObjectValues(
              getCodeOptions(prop),
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]?.v) {
              count = count + 1
            }
          } else if (prop == "viscosity" || prop == "odorFlag") {
            data[prop] = getObjectValues(
              getCodeOptions(prop),
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]?.v >= 0) {
              count = count + 1
            }
          } else if (
            prop == "specificGravity" ||
            prop == "flashPointFlag" ||
            prop == "phFlag" ||
            prop == "btuPerLbs" ||
            prop == "boilingPointFlag"
          ) {
            data[prop] = getObjectValues(
              getCodeOptions(prop),
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]?.v >= 0) {
              count = count + 1
            }
          } else if (prop == "tocPercentage") {
            data[prop] = getObjectValues(
              getCodeOptions(prop),
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]?.v) {
              non_requiredcount = non_requiredcount + 1
            }
          } else if (
            prop === "halogensPct" ||
            prop === "colorDesc" ||
            prop === "freeLiquidsPct" ||
            prop === "solidPct" ||
            prop === "sludgesPct"
          ) {
            let str = ""
            str = getValues(currentProfile, prop, initValues?.[prop])
            data[prop] = str.length > 0 ? str.trim() : str
            if (data[prop]) {
              count = count + 1
            }
          }
          // else if(
          //   prop === "freeLiquidsPct" ||
          //   prop === "solidPct" ||
          //   prop === "sludgesPct"
          // ) {
          //   let str = ""
          //     str = getValues(currentProfile, prop, initValues?.[prop])
          //     data[prop] = str.length > 0 ? str.trim() : str
          //     if (data[prop]) {
          //       count = count + 1
          // }
          else if (prop === "odorDesc") {
            let str = ""
            str = getValues(currentProfile, prop, initValues?.[prop])
            data[prop] = str.length > 0 ? str.trim() : str
            if (data?.["odorFlag"]?.v == "1" && data[prop]) {
              count = count + 1
            }
          } else if (
            prop === "specificGravityActual" ||
            prop === "flashpointActual" ||
            prop === "phExactValue" ||
            prop === "btuActual" ||
            prop === "boilingPtActual" ||
            prop === "flashpointScale" ||
            prop === "boilingScale"
          ) {
            let str = ""
            str = getValues(currentProfile, prop, initValues?.[prop])
            data[prop] = str.length > 0 ? str.trim() : str
            if (data[prop]) {
              non_requiredcount = non_requiredcount + 1
            }
          } else {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (data[prop]) {
              non_requiredcount = non_requiredcount + 1
            }
          }
        }
        setInitialValues(data)
        calcProgress(count, required_count)
      }
    }, [currentProfile?.generatorId])

    const cancelAction = () => {
      history.push(`/profiles`)
    }

    const handleAlerts = (values, errors, isValid, step) => {
      let checksFlag =
        values?.powderFlag ||
        values?.monolithFlag ||
        values?.debris ||
        values?.compressedGas
      let validateFlags =
        values?.solidPct > 0 ||
        values?.sludgesPct > 0 ||
        values?.freeLiquidsPct > 0 ||
        checksFlag
      if ((Object.keys(errors)?.length > 0 && !isValid) || !validateFlags) {
        setShowError(true)
        props.setShowAlert(true)
      } else {
        setShowError(false)
        dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: step + 1 })
        onSubmitProgress(values, step)
        props.setShowAlert(false)
      }
    }

    return (
      <Component
        {...props}
        {...{
          formik,
          onChange,
          backAction,
          goToProfilePages,
          odorOpts,
          tocOpts,
          layersOpts,
          showError,
          setShowError,
          btulbOpts,
          viscosityOpts,
          boilingPointOpts,
          specificGravityOpts,
          flashPointOpts,
          corrosivityOpts,
          cancelAction,
          handleAlerts,
        }}
      />
    )
  }

export default wastecharacterenhancer
