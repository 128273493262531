import React, { useEffect, useState } from "react"
import api from "api"
import {
  ALL_DOCUMENT_TYPES,
  ALL_EPAWASTE_CODES,
  ALL_FORM_CODES,
  ALL_FREQUENCY_TYPES,
  ALL_GENERATORS,
  ALL_NON_PROCESS_CODES,
  ALL_ORIGIN_CODES,
  ALL_PROCESS_CODES,
  ALL_PROFILE_LIST,
  // ALL_PROFILE_STATUS,
  ALL_SOURCE_CODES,
  ALL_VENDORS,
  CURRENT_PROFILE,
  GENERATOR_DETAILS,
  GET_PROGRESS_STEP,
  PROFILE_OVERVIEW_LIST,
  SET_NAICS_CDDETAILS,
  // SET_PROFILE_ID,
  SET_PROGRESS_STEP,
  SET_PROGRESS_STEP_ONLY,
  // SET_PROGRESS_STEP_ONLY,
} from "store/reducer/actionType"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import {
  getBooleanValues,
  getObjectValues,
  getOptions,
  getValues,
} from "routes/Utilities/util"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom/cjs/react-router-dom"

let generatorinfoenhancer =
  (Component) =>
  ({ ...props }) => {
    let history = useHistory()
    const { id, type, version } = useParams()
    const [showError, setShowError] = useState(false)
    // let [allDocsCheck, setAllDocsCheck] = useState(false)
    let [generatorName, setGeneratorName] = useState("")
    // let [pageNo, gotoPage] = useState(1)
    // let [submitted, setSubmitted] = useState(false)
    // let [searchTxt, setSearchTxt] = useState(null)
    let currentProfile = useSelector(
      (state) => state.profileDetails.currentProfile
    )
    //nonFilterProcessCodes
    const processNonCodes = useSelector(
      (state) => state.profileDetails.nonFilterProcessCodes
    )
    let processNonCodeOpts = getOptions(
      processNonCodes,
      "description",
      "processCodeId"
    )
    const processCodes = useSelector((state) =>
      state.profileDetails.allProcessCodes
        ? state.profileDetails.allProcessCodes.filter(
            (data) => data.processCodeId !== 0
          )
        : []
    )
    let processCodeOpts = getOptions(
      processCodes,
      "description",
      "processCodeId"
    )
    const sourceCodes = useSelector((state) =>
      state.profileDetails.allSourceCodes
        ? state.profileDetails.allSourceCodes.filter((data) => data.id !== 0)
        : []
    )
    let sourceCodeOpts = getOptions(sourceCodes, "display", "id")
    const formCodes = useSelector((state) =>
      state.profileDetails.allFormCodes
        ? state.profileDetails.allFormCodes.filter((data) => data.id !== 0)
        : []
    )
    let formCodeOpts = getOptions(formCodes, "display", "id")
    const originCodes = useSelector((state) =>
      state.profileDetails.allOriginCodes
        ? state.profileDetails.allOriginCodes.filter((data) => data.value !== 0)
        : []
    )
    let originCodeOpts = getOptions(originCodes, "description", "value")
    // const defaultGenerator = useSelector(
    //   (state) => state.profileDetails.defaultGenerator
    // )
    const profileList = useSelector(
      (state) => state.profileDetails.profilesList
    )
    const profileOverviewList = useSelector(
      (state) => state.profileDetails.profilesOverviewList
    )
    const copyProfileName = useSelector(
      (state) => state.profileDetails.profileName
    )
    const generatorOptions = useSelector(
      (state) => state.generatorDetails.generatorList
    )
    const selectedGeneForProf = useSelector((state) => state.generatorDetails)
    let generatorCodeOpts =
      generatorOptions?.length > 0
        ? getOptions(
            generatorOptions,
            "generatorName",
            "generatorId",
            "label",
            "value"
          )
        : []
    const dispatch = useDispatch()
    const initValues = {
      generatorId: "",
      vendorId: null,
      sourceCodeId: { l: "Select Source Code", v: 0 },
      formCodeId: { l: "Select Form Code", v: 0 },
      originCode: { l: "Select Origin", v: 0 },
      requestedProcessCodeId: { l: "Select Requested Process Code", v: 0 },
      rcraProcessGenerating: "",
      profileName: "",
      unusedCommercialProductFlag: false,
      spillResidueFlag: false,
      loosepackFlag: false,
      labpackFlag: false,
      disposalInstructionsComments: "",
    }
    const [initialValues, setInitialValues] = useState(initValues)
    let valSchema = {
      generatorId: Yup.string().required("Please select the Generator"),
      vendorId: Yup.number().required("Please select the Facility"),
      formCodeId: Yup.object()
        .shape({
          l: Yup.string().notOneOf(
            ["Select Form Code"],
            "Please select the Form Code"
          ),
          v: Yup.number().notOneOf([0], "Please select the Form Code"),
        })
        .required("Please select the Form Code"),
      originCode: Yup.object()
        .shape({
          l: Yup.string().notOneOf(
            ["Select Origin"],
            "Please select the Origin Code"
          ),
          v: Yup.number().notOneOf([0], "Please select the Origin Code"),
        })
        .required("Please select the Origin Code"),
      sourceCodeId: Yup.object({
        l: Yup.string().notOneOf(["(None)"], "Please select the Source Code"),
        v: Yup.number().notOneOf([0], "Please select the Source Code"),
      })
        .nullable()
        .required("Please select the Source Code"),
      requestedProcessCodeId: Yup.object()
        .shape({
          l: Yup.string().nullable(),
          v: Yup.number().nullable(),
        })
        .nullable(),
      rcraProcessGenerating: Yup.string().required(
        "Process Generating Waste is Required"
      ),
      profileName: Yup.string().required("Waste Name is Required"),
      unusedCommercialProductFlag: Yup.boolean(),
      spillResidueFlag: Yup.boolean(),
      loosepackFlag: Yup.boolean(),
      labpackFlag: Yup.boolean(),
      disposalInstructionsComments: Yup.string(),
    }

    // eslint-disable-next-line
    const generatorExists = (geneId) => {
      return generatorCodeOpts?.some((val) => val?.generatorId == geneId)
    }

    const onSubmit = async (formData) => {
      props.setShowAlert(false)
      let filterInactiveGen = generatorOptions.filter(
        (data) => data?.generatorId === formData?.generatorId
      )
      if (
        filterInactiveGen?.length > 0 &&
        filterInactiveGen &&
        filterInactiveGen[0].isActive === false
      ) {
        props.setShowInvalidGenPopup(true)
      } else {
        let payload = {
          ...formData,
          requestedProcessCodeId: formData?.requestedProcessCodeId?.v || 0,
          vendorId: formData?.vendorId,
          sourceCodeId: formData?.sourceCodeId?.v,
          formCodeId: formData?.formCodeId?.v,
          originCode: formData?.originCode?.v,
        }
        if (id) {
          payload.profileRequestId = id
        }

        try {
          let res
          if (id) {
            res = await api.p_updateWasteDescription(payload)
          } else {
            res = await api.p_saveWasteDescription(payload)
          }
          if (!res) return
          if (type) {
            setProgress(formData, 7)
            // dispatch({
            //   type: SET_PROGRESS_STEP,
            //   step: 1,
            //   progressStep: props.showAlert ? props.seekingStep + 1 : 2,
            //   percentage: setProgress(formData, 7),
            // })
          }
          getProfileData(res.data?.profileRequestId)
          if (!type) {
            history.push(
              `/profiles/createprofile/${res.data?.profileRequestId}`
            )
            setTimeout(
              () => dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: 2 }),
              3000
            )
          }
        } catch (err) {
          // dispatch({
          //   type: "SET_CHANGE_CUSTOMER",
          //   status: false,
          //   customer_name: changeCustomer?.customer,
          //   source: false,
          // })
        }
      }
    }

    const onSubmitValidate = async (formData) => {
      if (
        formData.generatorId &&
        formData.vendorId &&
        formData.rcraProcessGenerating &&
        formData.profileName &&
        formData.sourceCodeId &&
        formData.formCodeId?.v &&
        formData.originCode?.v
      ) {
        setShowError(false)
      } else {
        setShowError(true)
      }
    }

    const onSubmitProgress = async (formData, step) => {
      let payload = {
        ...formData,
        vendorId: formData?.vendorId,
        requestedProcessCodeId: formData?.requestedProcessCodeId?.v || 0,
        sourceCodeId: formData?.sourceCodeId?.v,
        formCodeId: formData?.formCodeId?.v,
        originCode: formData?.originCode?.v,
      }
      if (id) {
        payload.profileRequestId = id
      }

      try {
        let res = await api.p_updateWasteDescription(payload)

        if (!res) return
        if (type) {
          setProgress(formData, 7)
          // dispatch({
          //   type: SET_PROGRESS_STEP,
          //   step: 1,
          //   progressStep: props.showAlert ? props.seekingStep + 1 : 2,
          //   percentage: setProgress(formData, 7),
          // })
        }
        getProfileData(res.data?.profileRequestId)
        if (!type) {
          history.push(`/profiles/createprofile/${res.data?.profileRequestId}`)
          setTimeout(
            () => dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: 2 }),
            3000
          )
        }
      } catch (err) {}
    }

    const calcProgress = async (count, leng) => {
      await dispatch({
        type: GET_PROGRESS_STEP,
        step: 1,
        progressStep: 1,
        percentage: (count / leng) * 100,
        numerator: count,
        denominator: leng,
      })
    }

    const setProgress = (data, length) => {
      let count = 0
      let non_requiredcount = 0
      for (var prop in initValues) {
        if (
          prop == "sourceCodeId" ||
          prop == "formCodeId" ||
          prop == "originCode"
        ) {
          if (data[prop]?.v) {
            count = count + 1
          }
        } else if (prop == "requestedProcessCodeId") {
          if (data[prop]?.v) {
            non_requiredcount = non_requiredcount + 1
          }
        } else if (
          prop == "profileName" ||
          prop == "vendorId" ||
          prop == "rcraProcessGenerating" ||
          prop == "generatorId"
        ) {
          if (data[prop]) {
            count = count + 1
          }
        } else {
          if (data[prop]) {
            non_requiredcount = non_requiredcount + 1
          }
        }
      }
      dispatch({
        type: SET_PROGRESS_STEP,
        step: 1,
        progressStep: props.showAlert ? props.seekingStep + 1 : 2,
        percentage: (count / length) * 100,
        numerator: count,
        denominator: length,
      })
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }
    useEffect(() => {
      let data = {}
      if (
        currentProfile?.generatorId ||
        currentProfile?.templateFlag ||
        type == "viewprofile"
      ) {
        let count = 0
        let non_requiredcount = 0
        for (var prop in initValues) {
          if (
            prop == "unusedCommercialProductFlag" ||
            prop == "spillResidueFlag" ||
            prop == "loosepackFlag" ||
            prop == "labpackFlag"
          ) {
            data[prop] = getBooleanValues(
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]) {
              non_requiredcount = non_requiredcount + 1
            }
          } else if (prop == "sourceCodeId") {
            data[prop] = getObjectValues(
              sourceCodeOpts,
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]?.v) {
              count = count + 1
            }
          } else if (prop == "formCodeId") {
            data[prop] = getObjectValues(
              formCodeOpts,
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]?.v) {
              count = count + 1
            }
          } else if (prop == "originCode") {
            data[prop] = getObjectValues(
              originCodeOpts,
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]?.v) {
              count = count + 1
            }
          } else if (prop == "requestedProcessCodeId") {
            data[prop] = getObjectValues(
              processCodeOpts,
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]?.v) {
              non_requiredcount = non_requiredcount + 1
            }
          } else if (currentProfile?.templateFlag && prop == "profileName") {
            if (copyProfileName) {
              data[prop] = copyProfileName
            } else {
              data[prop] = ""
            }
          } else if (
            selectedGeneForProf.isProfileFromGenerator &&
            selectedGeneForProf.selectedProfileGenerator &&
            // currentProfile?.templateFlag &&
            prop == "generatorId"
          ) {
            data[prop] = selectedGeneForProf?.selectedProfileGenerator
            if (data[prop]) {
              count = count + 1
            }
          } else if (
            prop == "vendorId" ||
            prop == "profileName" ||
            prop == "rcraProcessGenerating" ||
            prop == "generatorId"
          ) {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (data[prop]) {
              count = count + 1
            }
          } else {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (data[prop]) {
              non_requiredcount = non_requiredcount + 1
            }
          }
        }
        setInitialValues(data)
        calcProgress(count, 7)
      }
    }, [currentProfile])

    const fetchAllVendorsData = async () => {
      try {
        let res = await api.g_getAllVendors()
        if (!res) return

        dispatch({ type: ALL_VENDORS, payload: res })
      } catch (error) {}
    }

    const fetchAllProcessCodes = async (vendorId) => {
      const req = {
        vendorId: vendorId || 0,
        plantGeneratorId: 0,
        mode: "profile",
      }

      try {
        let res = await api.p_getAllProcessCodes(req)
        if (!res) return

        dispatch({ type: ALL_PROCESS_CODES, payload: res })
        if (!vendorId) {
          dispatch({ type: ALL_NON_PROCESS_CODES, payload: res })
        }
      } catch (error) {}
    }

    const fetchAllSourceCodes = async () => {
      try {
        let res = await api.g_getAllSourceCodes()
        if (!res) return

        dispatch({ type: ALL_SOURCE_CODES, payload: res })
      } catch (error) {}
    }

    const fetchAllFormCodes = async () => {
      try {
        let res = await api.g_getAllFormCodes()
        if (!res) return

        dispatch({ type: ALL_FORM_CODES, payload: res })
      } catch (error) {}
    }
    const getAllGenerators = async () => {
      try {
        let res = await api.g_getAllGenerators()
        if (!res) return

        dispatch({ type: ALL_GENERATORS, payload: res })
      } catch (error) {}
    }

    const fetchAllOriginCodes = async () => {
      try {
        let res = await api.g_getAllOriginCodes()
        if (!res) return

        dispatch({ type: ALL_ORIGIN_CODES, payload: res })
      } catch (error) {}
    }

    const fetchAllEPAWasteCodes = async () => {
      let req = {
        ModeId: 1,
        ProfileId: 2,
        FileterId: 3,
      }
      try {
        let res = await api.g_getAllEPAWasteCodes(req)
        if (!res) return

        dispatch({ type: ALL_EPAWASTE_CODES, payload: res })
      } catch (error) {}
    }

    const fetchAllDocumentTypes = async () => {
      try {
        let res = await api.g_getAllDocumentTypes()
        if (!res) return

        dispatch({ type: ALL_DOCUMENT_TYPES, payload: res })
      } catch (error) {}
    }

    // const fetchAllFacilityProcessCodes = async () => {
    //   const vendorId = 547
    //   try {
    //     let res = await api.g_getAllFacilityProcessCodes(vendorId)
    //     if (!res) return

    //     dispatch({ type: ALL_FACILITY_PROCESS_CODES, payload: res })
    //   } catch (error) {}
    // }

    const fetchAllFrequencyTypes = async () => {
      try {
        let res = await api.g_getAllFrequencyTypes()
        if (!res) return

        dispatch({ type: ALL_FREQUENCY_TYPES, payload: res })
      } catch (error) {}
    }

    // const fetchAllProfileStatus = async () => {
    //   try {
    //     let res = await api.g_getAllProfileStatus()
    //     if (!res) return

    //     dispatch({ type: ALL_PROFILE_STATUS, payload: res })
    //   } catch (error) { }
    // }

    const getGeneratorDetails = async (id) => {
      try {
        let res = await api.g_getGeneratorDetails(id)
        if (!res) return
        dispatch({
          type: GENERATOR_DETAILS,
          payload: res,
        })
        if (res?.naics) {
          getNaicsDetails(res?.naics)
        }
      } catch (error) {}
    }

    const getNaicsDetails = async (code) => {
      let req = {
        naicsCodeKey: code,
      }
      try {
        let res = await api.p_getNAICSCodeDetals(req)
        if (!res) return
        dispatch({
          type: SET_NAICS_CDDETAILS,
          payload: res,
        })
      } catch (error) {}
    }

    const getProfileData = async (reqId, template, isNonDraft) => {
      let req
      if (isNonDraft) {
        req = {
          profileRequestId: 0,
          webProfileId: "",
          coreProfileNumber: reqId?.profile,
          coreVersionNumber: version || Number(reqId?.version),
        }
      } else {
        req = {
          profileRequestId: reqId,
        }
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        if (template) {
          dispatch({
            type: CURRENT_PROFILE,
            payload: {
              ...res,
              generatorName: generatorName,
              templateFlag: true,
              profileName: "",
            },
          })
        } else {
          dispatch({
            type: CURRENT_PROFILE,
            payload: { ...res, generatorName: generatorName },
          })
        }
      } catch (error) {}
    }

    const fetchAllProfiles = async () => {
      const payload = {
        generatorIdList: "",
        profileId: null,
        wasteName: null,
        universalWaste: "All",
        expiryType: "90 Days",
        expiryFromDate: null,
        expiryToDate: null,
        profileStatusGroupIdList: "1,2,3,4,5,6,7,8",
      }
      try {
        let res = await api.p_getAllProfilesList(payload)
        if (!res) return

        dispatch({ type: ALL_PROFILE_LIST, payload: res })
      } catch (error) {
        dispatch({ type: ALL_PROFILE_LIST, payload: { data: [] } })
      }
    }

    const profileListAPI = async () => {
      try {
        let req = {
          profilePeriod: "Last 90 Days",
          profileStatus: "",
        }
        let profileOverviewList = await api.p_getProfileOverviewList(req)
        if (!profileOverviewList) {
          dispatch({ type: PROFILE_OVERVIEW_LIST, payload: [] })
        } else {
          dispatch({
            type: PROFILE_OVERVIEW_LIST,
            payload: profileOverviewList,
          })
          // setProfileListValue(profileOverviewList.data)
        }
      } catch (error) {}
    }

    useEffect(() => {
      if (type !== "viewprofile") {
        fetchAllProfiles()
        profileListAPI()
      }
      getAllGenerators()
      fetchAllVendorsData()
      fetchAllProcessCodes()
      fetchAllFormCodes()
      fetchAllOriginCodes()
      fetchAllSourceCodes()
      fetchAllEPAWasteCodes()
      fetchAllDocumentTypes()
      // fetchAllFacilityProcessCodes()
      fetchAllFrequencyTypes()
      // fetchAllProfileStatus()
    }, [])

    useEffect(() => {
      if (type !== "viewprofile") {
        if (id && !currentProfile?.templateFlag) {
          getProfileData(id)
        }
        if (id && currentProfile?.templateFlag) {
          getProfileData(id, true)
        }
      } else {
        const getProfile = profileList.find(
          (item) => item.profileId == id || item.profile == id
        )
        const getProfileOverview = profileOverviewList?.find(
          (item) => item.profileId == id || item.profile == id
        )

        if (getProfile || getProfileOverview) {
          if (
            (getProfile?.status?.toLowerCase() == "draft" &&
              getProfile?.profile?.includes("P")) ||
            (getProfileOverview?.status?.toLowerCase() == "draft" &&
              getProfileOverview?.profile?.includes("P"))
          ) {
            getProfileData(id)
          } else {
            getProfileData(
              getProfile ? getProfile : getProfileOverview,
              false,
              true
            )
          }
        }
        //  else if (getProfileOverview) {
        //   if (getProfileOverview?.profileId) {
        //     getProfileData(id)
        //   } else {
        //     getProfileData(
        //       getProfileOverview,
        //       false,
        //       getProfileOverview?.status?.toLowerCase() != "draft"
        //     )
        //   }
        // }
      }
    }, [id, profileList?.length, profileOverviewList?.length])

    const cancelAction = () => {
      history.push(`/profiles`)
    }

    const goToCreateGenerator = (page) => {
      history.push(`/generator/createGenerator?from=createProfile`)
    }

    useEffect(() => {
      if (id && currentProfile?.generatorId) {
        getGeneratorDetails(currentProfile?.generatorId)
        fetchAllProcessCodes(currentProfile?.vendorId)
      }
    }, [id, currentProfile])

    const handleAlerts = (values, errors, isValid, step) => {
      if (Object.keys(errors)?.length > 0 && !isValid) {
        setShowError(true)
        props.setShowAlert(true)
      } else {
        setShowError(false)
        // dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: step + 1 })
        onSubmitProgress(values, step)
        props.setShowAlert(false)
      }
    }

    return (
      <Component
        {...props}
        {...{
          formik,
          processCodeOpts,
          sourceCodeOpts,
          formCodeOpts,
          originCodeOpts,
          setGeneratorName,
          getGeneratorDetails,
          cancelAction,
          goToCreateGenerator,
          initialValues,
          setInitialValues,
          showError,
          setShowError,
          handleAlerts,
          onSubmitValidate,
          fetchAllProcessCodes,
          processNonCodeOpts,
        }}
      />
    )
  }

export default generatorinfoenhancer
