import React, { Fragment, useState, useRef, useCallback } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import { Form, Formik } from "formik"
import Input from "routes/Admin/Components/Input"
import Select from "shared/Select"
import Map from "./Map"
//import api from "api"
import Modal from "shared/Modal"
import { toast } from "react-toastify"
import { debounce } from "routes/Utilities/util"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

function CreateGenerator(props) {
  let {
    formik,
    ERNameOpts,
    genIdStatusOpts,
    naicsOpts,
    subpartPOpts,
    handleShipState,
    handleManifestState,
    genCountries,
    shippingError,
    manifestError,
    shipStates,
    manifestStates,
    showSuccessModal,
    setShowSuccessModal,
    setFiles,
    erNameValue,
    getPhoneNumberByProviderName,
    cancelAction,
    createdGenId,
    loading,
    showAddressModal,
    setShowAddressModal,
    recShippingAddr,
    recManifestAddr,
    recShipError,
    recManifestError,
    enterAddress,
    setEnterAddress,
    enterManifestAddress,
    setEnterManifestAddress,
    onFinalSubmit,
    savedInfo,
    addrLoader,
    showDupGenModal,
    setShowDupGenModal,
    firstInputRef,
    onDuplicateSubmit,
    setState,
  } = props

  const fileInputRef = useRef(null)

  const [showGenIdInfo, setShowGenIdInfo] = useState(false)
  const [showEpaIdInfo, setShowEpaIdInfo] = useState(false)
  const [showStateIdInfo, setShowStateIdInfo] = useState(false)
  const [fileUploadModal, setFileUploadModal] = useState(false)
  const [address, setAddress] = useState("")
  const [fileName, setFileName] = useState("")
  const [USPSAddress, setUSPSAddress] = useState({
    streetAddress: "",
    secondaryAddress: "",
    city: "",
    state: "",
    zipCode: "",
  })
  const [manifestAddress, setManifestAddress] = useState({
    streetAddress: "",
    secondaryAddress: "",
    city: "",
    state: "",
    zipCode: "",
  })

  const debouncedFetchAddressData = useCallback(
    debounce((USPSAddress) => {
      setAddress(USPSAddress)
    }, 300),
    []
  )

  const handleUSPSAddress = async (updatedUSPSAddress) => {
    let addr =
      updatedUSPSAddress?.streetAddress +
      " " +
      updatedUSPSAddress?.city +
      " " +
      updatedUSPSAddress.state +
      " " +
      updatedUSPSAddress?.zipCode
    if (
      updatedUSPSAddress?.streetAddress &&
      updatedUSPSAddress?.city &&
      updatedUSPSAddress?.state &&
      updatedUSPSAddress?.zipCode
    )
      debouncedFetchAddressData(addr)
  }

  const handleErName = (e) => {
    getPhoneNumberByProviderName(e?.l)
  }

  const handleFileUpload = () => {
    const input = fileInputRef.current
    if (input) {
      input.click()
    }
  }

  if (loading) {
    return (
      <div className="loader-parent">
        <div className="loader"></div>
      </div>
    )
  }

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={formik?.values}
        {...formik}
      >
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <div className="ml-2 create-generator">
                <div>
                  <div className="col-sm-12 col-md-12 col-lg-12 ml-4 generatorstatus text-left my-4">
                    <div className="m-0 labelgenerator myprofile">
                      <FormattedMessage id="generator.createGenerator" />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 ml-4 d-flex">
                    <div
                      className="form-input-block col-sm-4 mb-2 px-0"
                      style={{ maxWidth: "70% !important" }}
                    >
                      <span className="label">
                        <FormattedMessage id="profile.generatorname" />
                        <span style={{ color: "red", padding: "1px" }}>
                          {" *"}
                        </span>
                      </span>
                      <Input
                        name="generatorName"
                        value={values?.generatorName}
                        reference={true}
                        firstInputRef={firstInputRef}
                        charlen={100}
                        clsName={
                          errors?.generatorName && touched?.generatorName
                            ? "input-border-red"
                            : ""
                        }
                        onFieldChange={(e) => {
                          setFieldValue("generatorName", e.target.value)
                        }}
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          top: 3,
                        }}
                      >
                        {errors.generatorName && touched.generatorName ? (
                          <div>{errors.generatorName}</div>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12  ml-4 d-flex">
                    <div
                      className="col-sm-5 text-left px-0 py-2"
                      style={{ marginRight: "-12px" }}
                    >
                      <FormattedMessage id="generator.createGenerator.ERIP" />
                      <div className="col-sm-12 d-flex py-2">
                        <div className="col-sm-6">
                          <div className="ml-2 radio-container">
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="emergencyResponseProvider"
                              value={values?.emergencyResponseProvider}
                              checked={
                                values?.emergencyResponseProvider === true
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                setFieldValue("emergencyResponseProvider", true)
                                setFieldValue(
                                  "emergencyResponsePhoneNo",
                                  "(877) 577-2669"
                                )
                              }}
                            />
                            <div className="px-4 text-description">
                              <FormattedMessage
                                id={"generator.createGenerator.cleanEarthER"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="ml-2 radio-container">
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="emergencyResponseProvider"
                              value={values?.emergencyResponseProvider}
                              checked={
                                values?.emergencyResponseProvider === false
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                setFieldValue(
                                  "emergencyResponseProvider",
                                  false
                                )
                              }}
                            />
                            <div className="px-4 text-description">
                              <FormattedMessage
                                id={"generator.createGenerator.otherER"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-0 col-sm-8 py-2 mt-2">
                      {values?.emergencyResponseProvider ? (
                        <div className="col-sm-12 d-flex">
                          <div className="form-input-block col-sm-8">
                            <span className="label">
                              <FormattedMessage id="generator.createGenerator.ERPhone" />
                            </span>
                            <Input
                              name="emergencyResponsePhoneNo"
                              value={values?.emergencyResponsePhoneNo}
                              disabled={true}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="col-sm-12 d-flex ">
                          <div
                            className={
                              values?.emergencyResponseInfo?.v !== 0
                                ? "col-sm-5"
                                : "col-sm-8"
                            }
                          >
                            <div
                              className="form-input-block mb-dropdown mni-style"
                              style={{ zIndex: 15 }}
                            >
                              <span
                                className="label"
                                style={{
                                  left: 15,
                                  marginLeft:
                                    values?.emergencyResponseInfo?.v !== 0
                                      ? "-21px"
                                      : "",
                                }}
                              >
                                <FormattedMessage id="generator.createGenerator.ERName" />
                              </span>
                              <Select
                                name="emergencyResponseInfo"
                                labelkey={"l"}
                                label={""}
                                className={"sort-dropdown"}
                                isError={
                                  !values?.emergencyResponseInfo?.v &&
                                  touched?.generatorName
                                }
                                options={ERNameOpts}
                                selected={
                                  values?.emergencyResponseInfo?.v !== 0
                                    ? values?.emergencyResponseInfo
                                    : null
                                }
                                onSelect={(e) => {
                                  setFieldValue("emergencyResponseInfo", e)
                                  handleErName(e)
                                }}
                              />
                            </div>
                            <span
                              style={{
                                color: "red",
                                fontSize: 10,
                                position: "relative",
                                top: 10,
                              }}
                            >
                              {!values?.emergencyResponseInfo?.v &&
                              touched?.emergencyResponseInfo ? (
                                <div>Emergency Response List is required</div>
                              ) : null}
                            </span>
                          </div>
                          {erNameValue && (
                            <div
                              className={
                                values?.emergencyResponseInfo?.v !== 0
                                  ? "col-sm-7"
                                  : "col-sm-5"
                              }
                            >
                              <div className="form-input-block col-sm-12">
                                <span className="label">
                                  <FormattedMessage id="generator.createGenerator.ERPhone" />
                                </span>
                                <Input
                                  name="emergencyResponsePhoneNo"
                                  value={erNameValue}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <span>
                  <hr />
                </span>
                <div>
                  <div className="ml-4 myprofile px-1 sub-header text-left">
                    <FormattedMessage id="generator.createGenerator.shippingInformation" />
                    {shippingError && (
                      <span
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        Enter a valid Shipping Address
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 ml-4 d-flex px-0 address-fields">
                    <div className="col-sm-4">
                      <div className="form-input-block pb-1 mb-dropdown country-dropdown mni-style">
                        <span
                          className="label"
                          style={{ zIndex: 12, left: 15 }}
                        >
                          <FormattedMessage id="generator.createGenerator.shippingCountry" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Select
                          name="shippingCountry"
                          isError={
                            errors?.shippingCountry &&
                            touched?.shippingCountry &&
                            !values?.shippingCountry?.v
                          }
                          labelkey={"l"}
                          label={""}
                          className={"sort-dropdown"}
                          options={genCountries || []}
                          hasSearch={true}
                          selected={values?.shippingCountry}
                          onSelect={(e) => {
                            setFieldValue("shippingCountry", e)
                            if (values?.shippingCountry?.v !== e?.v) {
                              setFieldValue("shippingState", null)
                            }
                            if (values?.sameAsShipping) {
                              setFieldValue("manifestCountry", e)
                              if (values?.manifestCountry?.v !== e?.v) {
                                setFieldValue("manifestState", null)
                              }
                              handleManifestState(e)
                            }
                            handleShipState(e)
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          top: 5,
                        }}
                      >
                        {errors.shippingCountry &&
                        touched.shippingCountry &&
                        !values?.shippingCountry?.v ? (
                          <div>{errors.shippingCountry}</div>
                        ) : null}
                      </span>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label">
                          <FormattedMessage id="generator.createGenerator.shippingStreet" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="shippingAddressLine1"
                          value={values?.shippingAddressLine1}
                          charlen={255}
                          clsName={
                            errors?.shippingAddressLine1 &&
                            touched?.shippingAddressLine1 &&
                            values?.shippingAddressLine1 === ""
                              ? "input-border-red"
                              : ""
                          }
                          onFieldChange={(e) => {
                            const { value } = e.target
                            setFieldValue("shippingAddressLine1", value)
                            let updatedUSPSAddress = {
                              ...USPSAddress,
                              streetAddress: value,
                            }
                            if (values?.sameAsShipping) {
                              setFieldValue("manifestAddressLine1", value)
                            }
                            setUSPSAddress(updatedUSPSAddress)
                            handleUSPSAddress(
                              updatedUSPSAddress,
                              values?.shippingCountry?.v
                            )
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingAddressLine1 &&
                          touched.shippingAddressLine1 &&
                          values?.shippingAddressLine1 === "" ? (
                            <div>{errors.shippingAddressLine1}</div>
                          ) : null}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label">
                          <FormattedMessage id="generator.createGenerator.shippingCity" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="shippingCity"
                          charlen={40}
                          clsName={
                            errors?.shippingCity &&
                            touched?.shippingCity &&
                            values?.shippingCity === ""
                              ? "input-border-red"
                              : ""
                          }
                          value={values?.shippingCity}
                          onFieldChange={(e) => {
                            const { value } = e.target
                            setFieldValue("shippingCity", value)
                            let updatedUSPSAddress = {
                              ...USPSAddress,
                              city: value,
                            }
                            if (values?.sameAsShipping) {
                              setFieldValue("manifestCity", value)
                            }
                            setUSPSAddress(updatedUSPSAddress)
                            handleUSPSAddress(
                              updatedUSPSAddress,
                              values?.shippingCountry?.v
                            )
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingCity &&
                          touched.shippingCity &&
                          values?.shippingCity === "" ? (
                            <div>{errors.shippingCity}</div>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 ml-4 d-flex px-0 address-fields">
                    <div className="col-sm-4">
                      <div className="form-input-block pb-1 mb-dropdown state-dropdown mni-style">
                        <span
                          className="label"
                          style={{ zIndex: 10, left: 15 }}
                        >
                          <FormattedMessage id="generator.createGenerator.shippingState" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Select
                          name="shippingState"
                          isError={
                            errors?.shippingState &&
                            touched?.shippingState &&
                            !values?.shippingState?.v
                          }
                          labelkey={"l"}
                          label={""}
                          className={"sort-dropdown"}
                          options={shipStates}
                          hasSearch={true}
                          selected={values?.shippingState}
                          onSelect={(e) => {
                            setFieldValue("shippingState", e)
                            let updatedUSPSAddress = {
                              ...USPSAddress,
                              state: e?.v,
                            }
                            if (values?.sameAsShipping) {
                              setFieldValue("manifestState", e)
                              setManifestAddress({
                                ...manifestAddress,
                                state: e?.v,
                              })
                            }
                            setState(e?.v)
                            setUSPSAddress(updatedUSPSAddress)
                            handleUSPSAddress(
                              updatedUSPSAddress,
                              values?.shippingCountry?.v
                            )
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          top: 5,
                        }}
                      >
                        {errors.shippingState &&
                        touched.shippingState &&
                        !values?.shippingState?.v ? (
                          <div>{errors.shippingState}</div>
                        ) : null}
                      </span>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label">
                          <FormattedMessage id="generator.createGenerator.shippingZip" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="shippingZip"
                          value={values?.shippingZip}
                          clsName={
                            errors?.shippingZip && touched?.shippingZip
                              ? //  && values?.shippingZip == ""
                                "input-border-red"
                              : // : errors?.shippingZip && touched?.shippingZip && values?.shippingZip?.length > 0 ? "input-border-red"
                                ""
                          }
                          onFieldChange={(e) => {
                            const { value } = e.target
                            if (values?.shippingCountry?.v === "US") {
                              const cleanedValue = value.replace(/\D/g, "")
                              let formattedValue = cleanedValue
                              if (cleanedValue.length > 5) {
                                formattedValue = `${cleanedValue.slice(
                                  0,
                                  5
                                )}-${cleanedValue.slice(5, 9)}`
                              }
                              setFieldValue("shippingZip", formattedValue)
                              if (values?.sameAsShipping) {
                                setFieldValue("manifestZip", formattedValue)
                              }
                              let updatedUSPSAddress = {
                                ...USPSAddress,
                                zipCode: formattedValue,
                              }
                              setUSPSAddress(updatedUSPSAddress)
                              handleUSPSAddress(
                                updatedUSPSAddress,
                                values?.shippingCountry?.v
                              )
                            } else {
                              setFieldValue("shippingZip", value)
                              let updatedUSPSAddress = {
                                ...USPSAddress,
                                zipCode: value,
                              }
                              setUSPSAddress(updatedUSPSAddress)
                              handleUSPSAddress(
                                updatedUSPSAddress,
                                values?.shippingCountry?.v
                              )
                              if (values?.sameAsShipping) {
                                setFieldValue("manifestZip", value)
                              }
                            }
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingZip && touched.shippingZip ? (
                            <div>{errors.shippingZip}</div>
                          ) : null}
                          {/* (errors?.shippingZip && touched.shippingZip && values?.shippingZip?.length > 0) ? (<div>{errors.shippingZip}</div>) : null} */}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label">
                          <FormattedMessage id="generator.createGenerator.county" />
                        </span>
                        <Input
                          name="shippingCounty"
                          charlen={100}
                          value={values?.shippingCounty}
                          onFieldChange={(e) => {
                            setFieldValue("shippingCounty", e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 ml-4 d-flex px-0 address-fields">
                    <div className="col-sm-4">
                      <div
                        className="form-input-block mb-dropdown"
                        style={{ marginLeft: "-15px", marginRight: "15px" }}
                      >
                        <span className="label" style={{ zIndex: 8 }}>
                          <FormattedMessage id="generator.createGenerator.shippingPhone" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="shippingPhoneNo"
                          clsName={
                            errors?.shippingPhoneNo &&
                            touched?.shippingPhoneNo &&
                            values?.shippingPhoneNo === ""
                              ? "input-border-red"
                              : ""
                          }
                          value={values?.shippingPhoneNo}
                          onFieldChange={(e) => {
                            let { value } = e.target
                            if (/^\d*$/.test(value) && value.length <= 10) {
                              setFieldValue("shippingPhoneNo", e.target.value)
                              if (values?.sameAsShipping) {
                                setFieldValue("manifestPhoneNo", e.target.value)
                              }
                            }
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingPhoneNo &&
                          touched.shippingPhoneNo &&
                          values?.shippingPhoneNo === "" ? (
                            <div>{errors.shippingPhoneNo}</div>
                          ) : null}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label">
                          <FormattedMessage id="generator.createGenerator.shippingFax" />
                        </span>
                        <Input
                          name="shippingFax"
                          type={"number"}
                          noMaxLength={false}
                          maxLen={10}
                          value={values?.shippingFax}
                          onFieldChange={(e) => {
                            const { value } = e.target
                            if (/^\d*$/.test(value)) {
                              setFieldValue("shippingFax", value)
                              if (values?.sameAsShipping) {
                                setFieldValue("manifestFax", value)
                              }
                            }
                          }}
                          onBlurChange={(e) => {
                            const { value } = e.target
                            if (/^\d*$/.test(value)) {
                              setFieldValue(
                                "shippingFax",
                                parseInt(value) || ""
                              )
                              if (values?.sameAsShipping) {
                                setFieldValue(
                                  "manifestFax",
                                  parseInt(value) || ""
                                )
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label">
                          <FormattedMessage id="generator.createGenerator.shippingEmail" />
                        </span>
                        <Input
                          name="shippingEmail"
                          charlen={100}
                          value={values?.shippingEmail}
                          onFieldChange={(e) => {
                            setFieldValue("shippingEmail", e.target.value)
                            if (values?.sameAsShipping) {
                              setFieldValue("manifestEmail", e.target.value)
                            }
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingEmail && touched.shippingEmail ? (
                            <div>{errors.shippingEmail}</div>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 ml-4 d-flex address-fields">
                    <div className="col-sm-4">
                      <div
                        className="form-input-block mb-dropdown"
                        style={{ marginLeft: "-30px", marginRight: "20px" }}
                      >
                        <span className="label" style={{ zIndex: 8 }}>
                          <FormattedMessage id="generator.createGenerator.shippingAttention" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="shippingAttention"
                          charlen={1200}
                          value={values?.shippingAttention}
                          clsName={
                            errors?.shippingAttention &&
                            touched?.shippingAttention &&
                            values?.shippingAttention === ""
                              ? "input-border-red"
                              : ""
                          }
                          onFieldChange={(e) => {
                            setFieldValue("shippingAttention", e.target.value)
                            if (values?.sameAsShipping) {
                              setFieldValue("manifestAttention", e.target.value)
                            }
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingAttention &&
                          touched.shippingAttention &&
                          values?.shippingAttention === "" ? (
                            <div>{errors.shippingAttention}</div>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5 mt-2 ml-3">
                    <Map address={address} />
                  </div>
                </div>
                <div className="generatorDiv">
                  <Modal show={showAddressModal}>
                    <div
                      className={
                        values?.shippingCountry?.v === "US" &&
                        values?.manifestCountry?.v === "US"
                          ? "modalPopup p-2"
                          : "modalPopup1 p-2"
                      }
                    >
                      <div style={{ textAlign: "right" }}>
                        <button
                          className="circle-btn"
                          onClick={() => {
                            setShowAddressModal(false)
                            setEnterAddress(true)
                            setEnterManifestAddress(true)
                          }}
                        >
                          <span
                            style={{
                              color: "#CCCCCC",
                              cursor: "pointer",
                              position: "relative",
                              bottom: 3,
                            }}
                          >
                            x
                          </span>
                        </button>
                      </div>
                      <Container
                        style={{
                          wordBreak: "break-all",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {addrLoader === true ? (
                          <div style={{ marginTop: 70 }}>
                            <div className="loader"></div>
                          </div>
                        ) : (
                          <>
                            {values?.shippingCountry?.v === "US" &&
                            values?.manifestCountry?.v === "US" ? (
                              <>
                                <Row className="justify-content-center mt-2 mb-3">
                                  <Col className="text-center">
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        //textTransform: "capitalize",
                                      }}
                                    >
                                      USPS has provided a recommended address
                                      shown below. <br />
                                      Select the address you would like to use
                                      for Shipping or Manifest address.
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <div
                                      className="mb-4"
                                      style={{
                                        border: "1px solid gray",
                                        borderRadius: "10px",
                                        paddingBottom: "20px",
                                      }}
                                    >
                                      <div
                                        className="text-white p-2"
                                        style={{
                                          fontSize: "16px",
                                          color: "#FFF",
                                          fontWeight: "700",
                                          background: "#0092BC",
                                          borderTopLeftRadius: "10px",
                                          borderTopRightRadius: "10px",
                                        }}
                                      >
                                        <span className="mb-0">
                                          SHIPPING ADDRESS
                                        </span>
                                      </div>
                                      <Row
                                        className="mt-4 text-left"
                                        style={{
                                          minHeight: 235,
                                        }}
                                      >
                                        <Col className="ml-4 mt-2">
                                          <div
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            Entered Address
                                          </div>
                                          <div className="d-flex mt-2">
                                            <div
                                              style={{
                                                marginTop: 5,
                                                marginRight: 20,
                                              }}
                                            >
                                              <input
                                                id="radioCustomColor"
                                                type="radio"
                                                name="enterAddress"
                                                value={enterAddress}
                                                checked={enterAddress === true}
                                                onChange={() => {
                                                  setEnterAddress(true)
                                                }}
                                              />
                                            </div>
                                            <div>
                                              {values?.shippingAddressLine1}
                                            </div>
                                          </div>
                                          <div className="d-flex ml-4">
                                            <div
                                              style={{
                                                marginRight: 20,
                                              }}
                                            >
                                              {`${values?.shippingCity}, ${values?.shippingState?.v} ${values?.shippingZip}`}
                                            </div>
                                          </div>
                                          <div className="d-flex ml-4 mb-2">
                                            <div
                                              style={{
                                                marginRight: 20,
                                              }}
                                            >
                                              United States
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              fontSize: "16px",
                                              color: "#0092BC",
                                              fontWeight: 600,
                                            }}
                                          >
                                            Recommended Address
                                          </div>
                                          {/* <div className="d-flex mt-2"> */}
                                          {recShipError !== "" ? (
                                            <div className="d-flex mt-2">
                                              <div className="pb-4">
                                                {recShipError}
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="d-flex mt-2">
                                                <div
                                                  style={{
                                                    marginTop: 5,
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  <input
                                                    id="radioCustomColor"
                                                    type="radio"
                                                    name="enterAddress"
                                                    value={enterAddress}
                                                    checked={
                                                      enterAddress === false
                                                    }
                                                    onChange={() => {
                                                      setEnterAddress(false)
                                                    }}
                                                  />
                                                </div>
                                                <div>
                                                  {
                                                    recShippingAddr?.streetAddress
                                                  }
                                                </div>
                                              </div>
                                              <div className="d-flex ml-4">
                                                <div
                                                  style={{
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  {recShippingAddr?.city},{" "}
                                                  {recShippingAddr?.state}{" "}
                                                  {recShippingAddr?.zipCode}
                                                </div>
                                              </div>
                                              <div className="d-flex ml-4 mb-2">
                                                <div
                                                  style={{
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  UNITED STATES
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          {/* </div> */}
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div
                                      className="mb-4"
                                      style={{
                                        border: "1px solid gray",
                                        borderRadius: "10px",
                                        paddingBottom: "20px",
                                      }}
                                    >
                                      <div
                                        className="text-white p-2"
                                        style={{
                                          fontSize: "16px",
                                          color: "#FFF",
                                          fontWeight: "700",
                                          background: "#0092BC",
                                          borderTopLeftRadius: "10px",
                                          borderTopRightRadius: "10px",
                                        }}
                                      >
                                        <span className="mb-0">
                                          MANIFEST ADDRESS
                                        </span>
                                      </div>
                                      <Row
                                        className="mt-4 text-left"
                                        style={{
                                          minHeight: 235,
                                        }}
                                      >
                                        <Col className="ml-4 mt-2">
                                          <div
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            Entered Address
                                          </div>
                                          <>
                                            <div className="d-flex mt-2">
                                              <div
                                                style={{
                                                  marginTop: 5,
                                                  marginRight: 20,
                                                }}
                                              >
                                                <input
                                                  id="radioCustomColor"
                                                  type="radio"
                                                  name="enterManifestAddress"
                                                  value={enterManifestAddress}
                                                  checked={
                                                    enterManifestAddress ===
                                                    true
                                                  }
                                                  onChange={() => {
                                                    setEnterManifestAddress(
                                                      true
                                                    )
                                                  }}
                                                />
                                              </div>
                                              <div>
                                                {values?.manifestAddressLine1}
                                              </div>
                                            </div>
                                            <div className="d-flex ml-4">
                                              <div
                                                style={{
                                                  marginRight: 20,
                                                }}
                                              >
                                                {values?.manifestCity},{" "}
                                                {values?.manifestState?.v}{" "}
                                                {values?.manifestZip}
                                              </div>
                                            </div>
                                            <div className="d-flex ml-4 mb-2">
                                              <div
                                                style={{
                                                  marginRight: 20,
                                                }}
                                              >
                                                United States
                                              </div>
                                            </div>
                                          </>
                                          {/* {values?.sameAsShipping ? (
                                        <>
                                          <div className="d-flex mt-2">
                                            <div
                                              style={{
                                                marginTop: 5,
                                                marginRight: 20,
                                              }}
                                            >
                                              <input
                                                id="radioCustomColor"
                                                type="radio"
                                                name="enterManifestAddress"
                                                value={enterManifestAddress}
                                                checked={
                                                  enterManifestAddress === true
                                                }
                                                onChange={() => {
                                                  setEnterManifestAddress(true)
                                                }}
                                              />
                                            </div>
                                            <div>
                                              {values?.shippingAddressLine1}
                                            </div>
                                          </div>
                                          <div className="d-flex ml-4">
                                            <div
                                              style={{
                                                marginRight: 20,
                                              }}
                                            >
                                              {values?.shippingCity},{" "}
                                              {values?.shippingState?.v}{" "}
                                              {values?.shippingZip}
                                            </div>
                                          </div>
                                          <div className="d-flex ml-4 mb-2">
                                            <div
                                              style={{
                                                marginRight: 20,
                                              }}
                                            >
                                              United States
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="d-flex mt-2">
                                            <div
                                              style={{
                                                marginTop: 5,
                                                marginRight: 20,
                                              }}
                                            >
                                              <input
                                                id="radioCustomColor"
                                                type="radio"
                                                name="enterManifestAddress"
                                                value={enterManifestAddress}
                                                checked={
                                                  enterManifestAddress === true
                                                }
                                                onChange={() => {
                                                  setEnterManifestAddress(true)
                                                }}
                                              />
                                            </div>
                                            <div>
                                              {values?.manifestAddressLine1}
                                            </div>
                                          </div>
                                          <div className="d-flex ml-4">
                                            <div
                                              style={{
                                                marginRight: 20,
                                              }}
                                            >
                                              {values?.manifestCity},{" "}
                                              {values?.manifestState?.v}{" "}
                                              {values?.manifestZip}
                                            </div>
                                          </div>
                                          <div className="d-flex ml-4 mb-2">
                                            <div
                                              style={{
                                                marginRight: 20,
                                              }}
                                            >
                                              United States
                                            </div>
                                          </div>
                                        </>
                                      )} */}

                                          <div
                                            style={{
                                              fontSize: "16px",
                                              color: "#0092BC",
                                              fontWeight: 600,
                                            }}
                                          >
                                            Recommended Address
                                          </div>

                                          {recManifestError !== "" ? (
                                            <div className="d-flex mt-2">
                                              <div className="pb-4">
                                                {recManifestError}
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="d-flex mt-2">
                                                <div
                                                  style={{
                                                    marginTop: 5,
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  <input
                                                    id="radioCustomColor"
                                                    type="radio"
                                                    name="enterManifestAddress"
                                                    value={enterManifestAddress}
                                                    checked={
                                                      enterManifestAddress ===
                                                      false
                                                    }
                                                    onChange={() => {
                                                      setEnterManifestAddress(
                                                        false
                                                      )
                                                    }}
                                                  />
                                                </div>
                                                <div>
                                                  {
                                                    recManifestAddr?.streetAddress
                                                  }
                                                </div>
                                              </div>
                                              <div className="d-flex ml-4">
                                                <div
                                                  style={{
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  {recManifestAddr?.city},{" "}
                                                  {recManifestAddr?.state}{" "}
                                                  {recManifestAddr?.zipCode}
                                                </div>
                                              </div>
                                              <div className="d-flex ml-4 mb-2">
                                                <div
                                                  style={{
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  UNITED STATES
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="justify-content-end mt-2 mb-4">
                                  <Col md="auto">
                                    <button
                                      className="cancel-button"
                                      onClick={() => {
                                        setShowAddressModal(false)
                                        setEnterAddress(true)
                                        setEnterManifestAddress(true)
                                      }}
                                    >
                                      EDIT
                                    </button>
                                  </Col>
                                  <Col md="auto">
                                    <button
                                      className="green-button"
                                      onClick={() => onFinalSubmit(savedInfo)}
                                    >
                                      SAVE
                                    </button>
                                  </Col>
                                </Row>
                              </>
                            ) : values?.shippingCountry?.v === "US" &&
                              values?.manifestCountry?.v !== "US" ? (
                              <>
                                <Row className="justify-content-center mt-2 mb-3">
                                  <Col className="text-center">
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        //textTransform: "capitalize",
                                      }}
                                    >
                                      USPS has provided a recommended address
                                      shown below. <br />
                                      Select the address you would like to use
                                      for Shipping or Manifest address.
                                    </span>
                                  </Col>
                                </Row>
                                <Row style={{ justifyContent: "center" }}>
                                  <Col md={8}>
                                    <div
                                      className="mb-4"
                                      style={{
                                        border: "1px solid gray",
                                        borderRadius: "10px",
                                        paddingBottom: "20px",
                                      }}
                                    >
                                      <div
                                        className="text-white p-2"
                                        style={{
                                          fontSize: "16px",
                                          color: "#FFF",
                                          fontWeight: "700",
                                          background: "#0092BC",
                                          borderTopLeftRadius: "10px",
                                          borderTopRightRadius: "10px",
                                        }}
                                      >
                                        <span className="mb-0">
                                          SHIPPING ADDRESS
                                        </span>
                                      </div>
                                      <Row
                                        className="mt-4 text-left"
                                        style={{
                                          minHeight: 235,
                                        }}
                                      >
                                        <Col className="ml-4 mt-2">
                                          <div
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            Entered Address
                                          </div>
                                          <div className="d-flex mt-2">
                                            <div
                                              style={{
                                                marginTop: 5,
                                                marginRight: 20,
                                              }}
                                            >
                                              <input
                                                id="radioCustomColor"
                                                type="radio"
                                                name="enterAddress"
                                                value={enterAddress}
                                                checked={enterAddress === true}
                                                onChange={() => {
                                                  setEnterAddress(true)
                                                }}
                                              />
                                            </div>
                                            <div>
                                              {values?.shippingAddressLine1}
                                            </div>
                                          </div>
                                          <div className="d-flex ml-4">
                                            <div
                                              style={{
                                                marginRight: 20,
                                              }}
                                            >
                                              {`${values?.shippingCity}, ${values?.shippingState?.v} ${values?.shippingZip}`}
                                            </div>
                                          </div>
                                          <div className="d-flex ml-4 mb-2">
                                            <div
                                              style={{
                                                marginRight: 20,
                                              }}
                                            >
                                              United States
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              fontSize: "16px",
                                              color: "#0092BC",
                                              fontWeight: 600,
                                            }}
                                          >
                                            Recommended Address
                                          </div>
                                          {/* <div className="d-flex mt-2"> */}
                                          {recShipError !== "" ? (
                                            <div className="d-flex mt-2">
                                              <div className="pb-4">
                                                {recShipError}
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="d-flex mt-2">
                                                <div
                                                  style={{
                                                    marginTop: 5,
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  <input
                                                    id="radioCustomColor"
                                                    type="radio"
                                                    name="enterAddress"
                                                    value={enterAddress}
                                                    checked={
                                                      enterAddress === false
                                                    }
                                                    onChange={() => {
                                                      setEnterAddress(false)
                                                    }}
                                                  />
                                                </div>
                                                <div>
                                                  {
                                                    recShippingAddr?.streetAddress
                                                  }
                                                </div>
                                              </div>
                                              <div className="d-flex ml-4">
                                                <div
                                                  style={{
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  {recShippingAddr?.city},{" "}
                                                  {recShippingAddr?.state}{" "}
                                                  {recShippingAddr?.zipCode}
                                                </div>
                                              </div>
                                              <div className="d-flex ml-4 mb-2">
                                                <div
                                                  style={{
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  UNITED STATES
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          {/* </div> */}
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="justify-content-end mt-2 mb-4">
                                  <Col md="auto">
                                    <button
                                      className="cancel-button"
                                      onClick={() => {
                                        setShowAddressModal(false)
                                        setEnterAddress(true)
                                        setEnterManifestAddress(true)
                                      }}
                                    >
                                      EDIT
                                    </button>
                                  </Col>
                                  <Col md="auto">
                                    <button
                                      className="green-button"
                                      onClick={() => onFinalSubmit(savedInfo)}
                                    >
                                      SAVE
                                    </button>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <Row className="justify-content-center mt-2 mb-3">
                                  <Col className="text-center">
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        //textTransform: "capitalize",
                                      }}
                                    >
                                      USPS has provided a recommended address
                                      shown below. <br />
                                      Select the address you would like to use
                                      for Shipping or Manifest address.
                                    </span>
                                  </Col>
                                </Row>
                                <Row style={{ justifyContent: "center" }}>
                                  <Col md={8}>
                                    <div
                                      className="mb-4"
                                      style={{
                                        border: "1px solid gray",
                                        borderRadius: "10px",
                                        paddingBottom: "20px",
                                      }}
                                    >
                                      <div
                                        className="text-white p-2"
                                        style={{
                                          fontSize: "16px",
                                          color: "#FFF",
                                          fontWeight: "700",
                                          background: "#0092BC",
                                          borderTopLeftRadius: "10px",
                                          borderTopRightRadius: "10px",
                                        }}
                                      >
                                        <span className="mb-0">
                                          MANIFEST ADDRESS
                                        </span>
                                      </div>
                                      <Row
                                        className="mt-4 text-left"
                                        style={{
                                          minHeight: 235,
                                        }}
                                      >
                                        <Col className="ml-4 mt-2">
                                          <div
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            Entered Address
                                          </div>
                                          <>
                                            <div className="d-flex mt-2">
                                              <div
                                                style={{
                                                  marginTop: 5,
                                                  marginRight: 20,
                                                }}
                                              >
                                                <input
                                                  id="radioCustomColor"
                                                  type="radio"
                                                  name="enterManifestAddress"
                                                  value={enterManifestAddress}
                                                  checked={
                                                    enterManifestAddress ===
                                                    true
                                                  }
                                                  onChange={() => {
                                                    setEnterManifestAddress(
                                                      true
                                                    )
                                                  }}
                                                />
                                              </div>
                                              <div>
                                                {values?.manifestAddressLine1}
                                              </div>
                                            </div>
                                            <div className="d-flex ml-4">
                                              <div
                                                style={{
                                                  marginRight: 20,
                                                }}
                                              >
                                                {values?.manifestCity},{" "}
                                                {values?.manifestState?.v}{" "}
                                                {values?.manifestZip}
                                              </div>
                                            </div>
                                            <div className="d-flex ml-4 mb-2">
                                              <div
                                                style={{
                                                  marginRight: 20,
                                                }}
                                              >
                                                United States
                                              </div>
                                            </div>
                                          </>

                                          <div
                                            style={{
                                              fontSize: "16px",
                                              color: "#0092BC",
                                              fontWeight: 600,
                                            }}
                                          >
                                            Recommended Address
                                          </div>

                                          {recManifestError !== "" ? (
                                            <div className="d-flex mt-2">
                                              <div className="pb-4">
                                                {recManifestError}
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="d-flex mt-2">
                                                <div
                                                  style={{
                                                    marginTop: 5,
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  <input
                                                    id="radioCustomColor"
                                                    type="radio"
                                                    name="enterManifestAddress"
                                                    value={enterManifestAddress}
                                                    checked={
                                                      enterManifestAddress ===
                                                      false
                                                    }
                                                    onChange={() => {
                                                      setEnterManifestAddress(
                                                        false
                                                      )
                                                    }}
                                                  />
                                                </div>
                                                <div>
                                                  {
                                                    recManifestAddr?.streetAddress
                                                  }
                                                </div>
                                              </div>
                                              <div className="d-flex ml-4">
                                                <div
                                                  style={{
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  {recManifestAddr?.city},{" "}
                                                  {recManifestAddr?.state}{" "}
                                                  {recManifestAddr?.zipCode}
                                                </div>
                                              </div>
                                              <div className="d-flex ml-4 mb-2">
                                                <div
                                                  style={{
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  UNITED STATES
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="justify-content-end mt-2 mb-4">
                                  <Col md="auto">
                                    <button
                                      className="cancel-button"
                                      onClick={() => {
                                        setShowAddressModal(false)
                                        setEnterAddress(true)
                                        setEnterManifestAddress(true)
                                      }}
                                    >
                                      EDIT
                                    </button>
                                  </Col>
                                  <Col md="auto">
                                    <button
                                      className="green-button"
                                      onClick={() => onFinalSubmit(savedInfo)}
                                    >
                                      SAVE
                                    </button>
                                  </Col>
                                </Row>
                              </>
                            )}

                            <div />
                          </>
                        )}
                      </Container>
                    </div>
                  </Modal>
                </div>
                <span>
                  <hr />
                </span>
                <div>
                  <div className="col-sm-12 py-1">
                    <div className="d-flex">
                      <div className="ml-4 col-sm-3 myprofile px-1 sub-header text-left">
                        <FormattedMessage id="generator.createGenerator.mailingAddress" />
                      </div>
                      <div className="col-sm-6">
                        <label className="check-container">
                          <input
                            name={"sameAsShipping"}
                            type="checkbox"
                            checked={values?.sameAsShipping}
                            onChange={(e) => {
                              setFieldValue("sameAsShipping", e.target.checked)
                              if (values?.shippingCountry) {
                                handleManifestState(values?.shippingCountry)
                              }
                              if (e.target.checked) {
                                setFieldValue(
                                  "manifestCountry",
                                  values?.shippingCountry
                                )
                                setFieldValue(
                                  "manifestState",
                                  values?.shippingState
                                )
                                setFieldValue(
                                  "manifestAddressLine1",
                                  values?.shippingAddressLine1
                                )
                                setFieldValue(
                                  "manifestCity",
                                  values?.shippingCity
                                )
                                setFieldValue(
                                  "manifestZip",
                                  values?.shippingZip
                                )
                                setFieldValue(
                                  "manifestPhoneNo",
                                  values?.shippingPhoneNo
                                )
                                setFieldValue(
                                  "manifestEmail",
                                  values?.shippingEmail
                                )
                                setFieldValue(
                                  "manifestFax",
                                  values?.shippingFax
                                )
                                setFieldValue(
                                  "manifestAttention",
                                  values?.shippingAttention
                                )
                              } else {
                                setFieldValue("manifestCountry", null)
                                setFieldValue("manifestState", null)
                                setFieldValue("manifestAddressLine1", "")
                                setFieldValue("manifestCity", "")
                                setFieldValue("manifestZip", "")
                                setFieldValue("manifestPhoneNo", "")
                                setFieldValue("manifestEmail", "")
                                setFieldValue("manifestFax", "")
                                setFieldValue("manifestAttention", "")
                              }
                            }}
                          />
                          <span className={"checkmark"} />
                        </label>
                        <div
                          className="text-description"
                          style={{ marginLeft: "-20PX" }}
                        >
                          <FormattedMessage
                            id={"generator.createGenerator.sameAddressFlag"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-left">
                      {manifestError && (
                        <span
                          style={{
                            color: "red",
                            marginLeft: "10px",
                            fontSize: "12px",
                          }}
                        >
                          Enter a valid Mailing Address
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 ml-4 d-flex px-0 address-fields">
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label">
                          <FormattedMessage id="generator.createGenerator.manifestName" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="manifestName"
                          charlen={100}
                          value={values?.manifestName}
                          clsName={
                            errors?.manifestName && touched?.manifestName
                              ? "input-border-red"
                              : ""
                          }
                          onFieldChange={(e) => {
                            setFieldValue("manifestName", e.target.value)
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.manifestName && touched.manifestName ? (
                            <div>{errors.manifestName}</div>
                          ) : null}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 ml-2">
                      <div className="form-input-block pb-1 mb-dropdown manifest-country mni-style">
                        <span className="label" style={{ left: 15 }}>
                          <FormattedMessage id="generator.createGenerator.manifestCountry" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Select
                          name="manifestCountry"
                          labelkey={"l"}
                          label={""}
                          isError={
                            errors?.manifestCountry &&
                            touched?.manifestCountry &&
                            !values?.manifestCountry?.v
                            // && !values?.sameAsShipping
                          }
                          // disabled={values?.sameAsShipping}
                          className={"sort-dropdown"}
                          options={genCountries || []}
                          hasSearch={true}
                          selected={
                            // values?.sameAsShipping
                            //   ? values?.shippingCountry :
                            values?.manifestCountry
                          }
                          onSelect={(e) => {
                            setFieldValue("manifestCountry", e)
                            if (
                              // !values?.sameAsShipping &&
                              values?.manifestCountry?.v !== e?.v
                            ) {
                              setFieldValue("manifestState", null)
                            }
                            handleManifestState(e)
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          top: 5,
                        }}
                      >
                        {errors.manifestCountry &&
                        touched.manifestCountry &&
                        !values?.manifestCountry?.v ? (
                          // && !values?.sameAsShipping
                          <div>{errors.manifestCountry}</div>
                        ) : null}
                      </span>
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          top: 3,
                        }}
                      >
                        {errors.shippingCountry &&
                          touched.shippingCountry &&
                          values?.sameAsShipping ? (
                          <div>{errors.shippingCountry}</div>
                        ) : null}
                      </span> */}
                    </div>

                    <div className="col-sm-4">
                      <div
                        className="form-input-block pb-1 mb-dropdown mni-style"
                        style={{ left: 10 }}
                      >
                        <span className="label" style={{ left: 15 }}>
                          <FormattedMessage id="generator.createGenerator.manifestState" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Select
                          name="manifestState"
                          labelkey={"l"}
                          label={""}
                          isError={
                            errors?.manifestState &&
                            touched?.manifestState &&
                            !values?.manifestState?.v
                            // && !values?.sameAsShipping
                          }
                          className={"sort-dropdown"}
                          options={manifestStates}
                          hasSearch={true}
                          // disabled={values?.sameAsShipping}
                          selected={
                            // values?.sameAsShipping
                            //   ? values?.shippingState :
                            values?.manifestState
                          }
                          onSelect={(e) => {
                            setFieldValue("manifestState", e)
                            setManifestAddress({
                              ...manifestAddress,
                              state: e?.v,
                            })
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          top: 5,
                        }}
                      >
                        {errors.manifestState &&
                        touched.manifestState &&
                        !values?.manifestState?.v ? (
                          //  && !values?.sameAsShipping
                          <div>{errors.manifestState}</div>
                        ) : null}
                      </span>
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          top: 3,
                        }}
                      >
                        {errors.shippingState &&
                          touched.shippingState &&
                          values?.sameAsShipping ? (
                          <div>{errors.shippingState}</div>
                        ) : null}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-sm-12 ml-4 d-flex px-0 address-fields">
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label">
                          <FormattedMessage id="generator.createGenerator.manifestStreet" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="manifestAddressLine1"
                          charlen={255}
                          value={
                            // values?.sameAsShipping
                            //   ? values?.shippingAddressLine1 :
                            values?.manifestAddressLine1
                          }
                          // disabled={values?.sameAsShipping}
                          clsName={
                            errors?.manifestAddressLine1 &&
                            touched?.manifestAddressLine1 &&
                            values?.manifestAddressLine1 == ""
                              ? // && !values?.sameAsShipping
                                "input-border-red"
                              : ""
                          }
                          onFieldChange={(e) => {
                            setFieldValue(
                              "manifestAddressLine1",
                              e.target.value
                            )
                            setManifestAddress({
                              ...manifestAddress,
                              streetAddress: e.target.value || "",
                            })
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.manifestAddressLine1 &&
                          touched.manifestAddressLine1 &&
                          values?.manifestAddressLine1 == "" ? (
                            // && !values?.sameAsShipping
                            <div>{errors.manifestAddressLine1}</div>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingAddressLine1 &&
                          touched.shippingAddressLine1 &&
                          values?.sameAsShipping ? (
                            <div>{errors.shippingAddressLine1}</div>
                          ) : null}
                        </span> */}
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label" style={{ zIndex: 9 }}>
                          <FormattedMessage id="generator.createGenerator.manifestCity" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="manifestCity"
                          charlen={40}
                          value={
                            // values?.sameAsShipping
                            //   ? values?.shippingCity :
                            values?.manifestCity
                          }
                          // disabled={values?.sameAsShipping}
                          clsName={
                            errors?.manifestCity &&
                            touched?.manifestCity &&
                            values?.manifestCity == ""
                              ? //  && !values?.sameAsShipping
                                "input-border-red"
                              : ""
                          }
                          onFieldChange={(e) => {
                            setFieldValue("manifestCity", e.target.value)
                            setManifestAddress({
                              ...manifestAddress,
                              city: e.target.value || "",
                            })
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.manifestCity &&
                          touched.manifestCity &&
                          values?.manifestCity == "" ? (
                            // && !values?.sameAsShipping
                            <div>{errors.manifestCity}</div>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingCity &&
                          touched.shippingCity &&
                          values?.sameAsShipping ? (
                            <div>{errors.shippingCity}</div>
                          ) : null}
                        </span> */}
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div
                        className="form-input-block mb-dropdown"
                        style={{ left: 10 }}
                      >
                        <span className="label" style={{ zIndex: 9 }}>
                          <FormattedMessage id="generator.createGenerator.manifestZip" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="manifestZip"
                          clsName={
                            errors?.manifestZip && touched?.manifestZip
                              ? // && values?.manifestZip == ""
                                // && !values?.sameAsShipping
                                "input-border-red"
                              : // : errors?.manifestZip && touched?.manifestZip && values?.manifestZip?.length > 0 ? "input-border-red"
                                ""
                          }
                          value={
                            // values?.sameAsShipping
                            //   ? values?.shippingZip :
                            values?.manifestZip
                          }
                          // disabled={values?.sameAsShipping}
                          onFieldChange={(e) => {
                            const { value } = e.target
                            if (values?.manifestCountry?.v === "US") {
                              const cleanedValue = value.replace(/\D/g, "")
                              let formattedValue = cleanedValue
                              if (cleanedValue.length > 5) {
                                formattedValue = `${cleanedValue.slice(
                                  0,
                                  5
                                )}-${cleanedValue.slice(5, 9)}`
                              }
                              setFieldValue("manifestZip", formattedValue)
                              setManifestAddress({
                                ...manifestAddress,
                                zipCode: formattedValue || "",
                              })
                            } else {
                              setFieldValue("manifestZip", value)
                            }
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors?.manifestZip && touched?.manifestZip ? (
                            //  && !values?.sameAsShipping
                            <div>{errors.manifestZip}</div>
                          ) : // : (errors?.manifestZip && touched?.manifestZip && values?.manifestZip?.length > 0) ? <div>{errors.manifestZip}</div>
                          null}
                        </span>
                        {/* <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingZip &&
                          touched.shippingZip &&
                          values?.sameAsShipping ? (
                            <div>{errors.shippingZip}</div>
                          ) : null}
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 ml-4 d-flex px-0 address-fields">
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label" style={{ zIndex: 9 }}>
                          <FormattedMessage id="generator.createGenerator.manifestPhone" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="manifestPhoneNo"
                          charlen={50}
                          value={
                            // values?.sameAsShipping
                            //   ? values?.shippingPhoneNo :
                            values?.manifestPhoneNo
                          }
                          // disabled={values?.sameAsShipping}
                          clsName={
                            errors?.manifestPhoneNo &&
                            touched?.manifestPhoneNo &&
                            values?.manifestPhoneNo == ""
                              ? //  && !values?.sameAsShipping
                                "input-border-red"
                              : ""
                          }
                          onFieldChange={(e) => {
                            let { value } = e.target
                            if (/^\d*$/.test(value) && value.length <= 10) {
                              setFieldValue("manifestPhoneNo", e.target.value)
                            }
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.manifestPhoneNo &&
                          touched.manifestPhoneNo &&
                          values?.manifestPhoneNo == "" ? (
                            //  &&
                            // !values?.sameAsShipping
                            <div>{errors.manifestPhoneNo}</div>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingPhoneNo &&
                          touched.shippingPhoneNo &&
                          values?.sameAsShipping ? (
                            <div>{errors.shippingPhoneNo}</div>
                          ) : null}
                        </span> */}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label" style={{ zIndex: 9 }}>
                          <FormattedMessage id="generator.createGenerator.manifestFax" />
                        </span>
                        <Input
                          name="manifestFax"
                          type={"number"}
                          noMaxLength={false}
                          maxLen={10}
                          value={
                            // values?.sameAsShipping
                            //   ? values?.shippingFax :
                            values?.manifestFax
                          }
                          // disabled={values?.sameAsShipping}
                          onFieldChange={(e) => {
                            const { value } = e.target
                            if (/^\d*$/.test(value)) {
                              setFieldValue("manifestFax", value)
                            }
                          }}
                          onBlurChange={(e) => {
                            const { value } = e.target
                            if (/^\d*$/.test(value)) {
                              setFieldValue(
                                "manifestFax",
                                parseInt(value) || ""
                              )
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4 ml-2">
                      <div className="form-input-block mb-dropdown">
                        <span className="label" style={{ zIndex: 9 }}>
                          <FormattedMessage id="generator.createGenerator.manifestEmail" />
                        </span>
                        <Input
                          name="manifestEmail"
                          charlen={100}
                          value={
                            // values?.sameAsShipping
                            //   ? values?.shippingEmail :
                            values?.manifestEmail
                          }
                          // disabled={values?.sameAsShipping}
                          onFieldChange={(e) => {
                            setFieldValue("manifestEmail", e.target.value)
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.manifestEmail && touched.manifestEmail ? (
                            // && !values?.sameAsShipping
                            <div>{errors.manifestEmail}</div>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingEmail &&
                          touched.shippingEmail &&
                          values?.sameAsShipping ? (
                            <div>{errors.shippingEmail}</div>
                          ) : null}
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 ml-4 d-flex px-0 address-fields">
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label" style={{ zIndex: 8 }}>
                          <FormattedMessage id="generator.createGenerator.manifestAttention" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Input
                          name="manifestAttention"
                          charlen={1200}
                          value={
                            // values?.sameAsShipping
                            //   ? values?.shippingAttention :
                            values?.manifestAttention
                          }
                          // disabled={values?.sameAsShipping}
                          clsName={
                            errors?.manifestAttention &&
                            touched?.manifestAttention &&
                            values?.manifestAttention == ""
                              ? //  &&
                                // !values?.sameAsShipping
                                "input-border-red"
                              : ""
                          }
                          onFieldChange={(e) => {
                            setFieldValue("manifestAttention", e.target.value)
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.manifestAttention &&
                          touched.manifestAttention &&
                          values?.manifestAttention == "" ? (
                            // && !values?.sameAsShipping
                            <div>{errors.manifestAttention}</div>
                          ) : null}
                        </span>
                        {/* <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.shippingAttention &&
                          touched.shippingAttention &&
                          values?.sameAsShipping ? (
                            <div>{errors.shippingAttention}</div>
                          ) : null}
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <span>
                  <hr />
                </span>
                <div>
                  <div className="col-sm-12 ml-4 d-flex">
                    <div className="col-sm-4" />
                    <div className="col-sm-4" />
                    <div
                      className="additinalinfo check-container d-flex col-sm-2 ml-3 mb-3"
                      style={{ marginRight: 25, padding: 6 }}
                    >
                      {values?.generatorIdStatus?.v === "CESQG" ? (
                        <label
                          className={`additinalinfo check-container ${
                            !values?.NoEpaId ? "text-disabled" : ""
                          }`}
                        >
                          <input
                            name={"NoEpaId"}
                            type="checkbox"
                            checked={values?.NoEpaId}
                            onChange={(e) => {
                              setFieldValue("NoEpaId", e.target.checked)
                              // if (e.target.checked) {
                              //   setFieldValue("epaId", "CESQG")
                              // }
                            }}
                            disabled={values?.generatorIdStatus?.v !== "CESQG"}
                          />
                          <span className={"checkmark"} />
                        </label>
                      ) : (
                        <label
                          className={`additinalinfo check-container ${
                            !values?.NoEpaId ? "text-disabled" : ""
                          }`}
                        >
                          <input
                            name={"NoEpaId"}
                            type="checkbox"
                            checked={false}
                            disabled={true}
                          />
                          <span className={"checkmark"} />
                        </label>
                      )}
                      <span
                        className="px-4 text-descriptiona"
                        style={{
                          marginLeft: "16PX",
                          fontSize: 12,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <FormattedMessage
                          id={`generator.createGenerator.NoEPAIDNumber`}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 ml-4 d-flex">
                    <div className="col-sm-4">
                      <div className="form-input-block pb-1 mb-dropdown mni-style">
                        <span className="label" style={{ left: 15 }}>
                          <FormattedMessage id="generator.createGenerator.NAICS" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Select
                          name="naics"
                          labelkey={"l"}
                          label={""}
                          fromaNaics={true}
                          isError={errors?.naics && touched?.naics}
                          className={"sort-dropdown"}
                          options={naicsOpts || []}
                          hasSearch={true}
                          selected={values?.naics}
                          onSelect={(e) => {
                            setFieldValue("naics", e)
                          }}
                          medium={true}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          top: 5,
                        }}
                      >
                        {errors.naics && touched.naics ? (
                          <div>{errors.naics}</div>
                        ) : null}
                      </span>
                    </div>
                    <div className="col-sm-4">
                      <div
                        className="form-input-block pb-1 mb-dropdown generator-status-dropdown mni-style"
                        style={{ left: 10 }}
                      >
                        <span
                          className="label"
                          style={{ zIndex: 12, left: 15 }}
                        >
                          <FormattedMessage id="generator.createGenerator.IdStatus" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                          <span
                            className="icon-container"
                            //onClick={() => setShowGenIdInfo(true)}
                            onMouseEnter={() => setShowGenIdInfo(true)}
                            onMouseLeave={() => setShowGenIdInfo(false)}
                          >
                            <Image
                              className={`view-icon ml-1`}
                              src="Info_Icon.svg"
                              height="14px"
                              width="14px"
                              alt=""
                            />
                            {showGenIdInfo && (
                              <div
                                className="info-popup"
                                style={{
                                  marginLeft: "-10px",
                                  maxWidth: "300px",
                                }}
                              >
                                Generator ID statuses: <br />
                                <ul style={{ marginLeft: "-20px" }}>
                                  <li>
                                    {" "}
                                    &nbsp; LQG - Large Quantity
                                    <br /> &nbsp; Generators
                                  </li>
                                  <li>
                                    {" "}
                                    &nbsp; SQG - Small Quantity
                                    <br /> &nbsp; Generators (SQGs)
                                  </li>
                                  <li>
                                    {" "}
                                    &nbsp; VSQG - Very Small Quantity
                                    <br /> &nbsp; Generators
                                  </li>
                                  <li>
                                    {" "}
                                    &nbsp; CESQG - Conditionally Exempt
                                    <br /> &nbsp; Small Quantity Generator
                                  </li>
                                  <li>
                                    {" "}
                                    &nbsp; HHW - Household Hazardous
                                    <br /> &nbsp; Waste
                                  </li>
                                  <li> &nbsp; TBA - To Be Assigned</li>
                                  <li> &nbsp; Exempt</li>
                                </ul>
                              </div>
                            )}
                          </span>
                        </span>
                        <Select
                          name="generatorIdStatus"
                          labelkey={"l"}
                          label={""}
                          isError={
                            errors?.generatorIdStatus &&
                            touched?.generatorIdStatus &&
                            !values?.generatorIdStatus?.v
                          }
                          className={"sort-dropdown "}
                          hasSearch={true}
                          options={genIdStatusOpts || []}
                          selected={values?.generatorIdStatus}
                          onSelect={(e) => {
                            const value = e.generatorIdStatus
                            setFieldValue("generatorIdStatus", e)
                            if (value !== "CESQG") {
                              setFieldValue("NoEpaId", false)
                            }
                            if (value === "HHW" || value === "Exempt") {
                              setFieldValue("epaId", value)
                            } else if (value?.includes("TBA")) {
                              setFieldValue("epaId", "TBA")
                            } else {
                              setFieldValue("epaId", "")
                            }
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          top: 5,
                        }}
                      >
                        {errors.generatorIdStatus &&
                        touched.generatorIdStatus &&
                        !values?.generatorIdStatus?.v ? (
                          <div>{errors.generatorIdStatus}</div>
                        ) : null}
                      </span>
                    </div>
                    <div className="col-sm-4">
                      <div
                        className="form-input-block mb-dropdown"
                        style={{ left: 10 }}
                      >
                        <span
                          // style={{
                          //   visibility:
                          //     showGenIdInfo === true ? "hidden" : "visible",
                          // }}
                          className="label"
                        >
                          <span style={{ color: "red", padding: "1px" }}>
                            {"*"}
                          </span>
                          <FormattedMessage id="generator.createGenerator.EPAID" />
                          <span
                            className="icon-container"
                            onMouseEnter={() => setShowEpaIdInfo(true)}
                            onMouseLeave={() => setShowEpaIdInfo(false)}
                          >
                            <Image
                              className={`view-icon ml-1`}
                              src="Info_Icon.svg"
                              height="14px"
                              width="14px"
                              alt=""
                            />
                            {showEpaIdInfo && (
                              <div
                                className="info-popup"
                                style={{
                                  marginLeft: "-25px",
                                  maxWidth: "300px",
                                }}
                              >
                                <div>
                                  If Generator is:
                                  <ul style={{ marginLeft: "-20px" }}>
                                    <li>LQG – Requires EPAID</li>
                                    <li>SQG – Requires EPAID</li>
                                    <li>
                                      VSQG or CESQG – Enter VSQG or <br />
                                      CESQG, unless in a 0 generation <br />
                                      state, then requires EPAID
                                    </li>
                                    <li>HHW – Enter HHW</li>
                                    <li>To be assigned – Enter TBA</li>
                                    <li>Exempt – Enter Exempt</li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </span>
                        </span>
                        <Input
                          name="epaId"
                          value={values?.epaId}
                          charlen={50}
                          clsName={
                            errors?.epaId &&
                            touched?.epaId &&
                            values?.generatorIdStatus?.v?.includes("TBA") &&
                            values?.epaId === "TBA"
                              ? ""
                              : errors?.epaId &&
                                touched?.epaId &&
                                values?.generatorIdStatus?.v === "Exempt" &&
                                values?.epaId === "Exempt"
                              ? ""
                              : errors?.epaId &&
                                touched?.epaId &&
                                values?.generatorIdStatus?.v === "HHW" &&
                                values?.epaId === "HHW"
                              ? ""
                              : errors?.epaId && touched?.epaId
                              ? "input-border-red"
                              : ""
                          }
                          onFieldChange={(e) => {
                            const { value } = e.target
                            if (/^[a-zA-Z0-9]*$/.test(value)) {
                              setFieldValue("epaId", value)
                            }
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.epaId &&
                            touched.epaId &&
                            (values?.generatorIdStatus &&
                            values?.generatorIdStatus?.v?.includes("TBA") &&
                            values?.epaId === "TBA" ? null : values
                                ?.generatorIdStatus?.v === "Exempt" &&
                              values?.epaId === "Exempt" ? null : values
                                ?.generatorIdStatus?.v === "HHW" &&
                              values?.epaId === "HHW" ? null : (
                              <div>
                                {values?.epaId === ""
                                  ? "EPA ID is required"
                                  : errors.epaId}
                              </div>
                            ))}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 ml-4 d-flex p-0">
                    <div className="col-sm-4">
                      <div className="form-input-block mb-dropdown">
                        <span className="label" style={{ zIndex: 9 }}>
                          {/* <span style={{ color: "red", padding: "1px" }}>
                            {"*"}
                          </span> */}
                          <FormattedMessage id="generator.createGenerator.stateID" />
                          <span
                            className="icon-container"
                            onMouseEnter={() => setShowStateIdInfo(true)}
                            onMouseLeave={() => setShowStateIdInfo(false)}
                          >
                            <Image
                              className={`view-icon ml-1`}
                              src="Info_Icon.svg"
                              height="14px"
                              width="14px"
                              alt=""
                            />
                            {showStateIdInfo && (
                              <div
                                className="info-popup"
                                style={{ marginTop: "70px" }}
                              >
                                <ul style={{ marginLeft: "-20px" }}>
                                  <li>Enter State ID where applicable</li>
                                </ul>
                              </div>
                            )}
                          </span>
                        </span>
                        <Input
                          name="stateId"
                          value={values?.stateId}
                          charlen={50}
                          onFieldChange={(e) => {
                            setFieldValue("stateId", e.target.value)
                          }}
                        />
                        {/* <span
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            top: 3,
                          }}
                        >
                          {errors.stateId && touched.stateId ? (
                            <div>{errors.stateId}</div>
                          ) : null}
                        </span> */}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div
                        className="form-input-block pb-1 mb-dropdown subpart-dropdown mni-style"
                        style={{ left: 10 }}
                      >
                        <span
                          className="label"
                          style={{ zIndex: 10, left: 15 }}
                        >
                          <FormattedMessage id="generator.createGenerator.subpartP" />
                          <span style={{ color: "red", padding: "1px" }}>
                            {" *"}
                          </span>
                        </span>
                        <Select
                          name="subpartP"
                          labelkey={"l"}
                          label={""}
                          className={"sort-dropdown "}
                          hasSearch={true}
                          isError={errors?.subpartP && touched?.subpartP}
                          options={subpartPOpts || []}
                          selected={values?.subpartP}
                          onSelect={(e) => {
                            setFieldValue("subpartP", e)
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          top: 5,
                        }}
                      >
                        {errors.subpartP && touched.subpartP ? (
                          <div>{errors.subpartP}</div>
                        ) : null}
                      </span>
                    </div>
                    <div
                      className="col-sm-4 d-flex px-0"
                      style={{ justifyContent: "flex-start", left: 10 }}
                    >
                      <div className="col-sm-7">
                        <div className="col-sm-12 d-flex px-0">
                          <div
                            className="text-left"
                            style={{ fontSize: "13px" }}
                          >
                            <FormattedMessage id="profile.optsdfapplist" />
                          </div>
                        </div>
                        <div
                          className="col-sm-7 d-flex px-0"
                          style={{
                            marginLeft: "-20px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="col-sm-4">
                            <div className="ml-2 radio-container">
                              <input
                                id="radioCustomColor"
                                type="radio"
                                name="isTsdfApprovalFile"
                                value={values?.isTsdfApprovalFile}
                                checked={
                                  values?.isTsdfApprovalFile === true
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  setFieldValue("isTsdfApprovalFile", true)
                                }}
                              />
                              <div className="px-4 text-description">
                                <FormattedMessage id={"profile.opyes"} />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="ml-2 radio-container">
                              <input
                                id="radioCustomColor"
                                type="radio"
                                name="isTsdfApprovalFile"
                                value={values?.isTsdfApprovalFile}
                                checked={
                                  values?.isTsdfApprovalFile === false
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  setFieldValue("isTsdfApprovalFile", false)
                                }}
                              />
                              <div className="px-4 text-description">
                                <FormattedMessage id={"profile.opno"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {values?.isTsdfApprovalFile ? (
                        <div className="col-sm-4 px-0">
                          <button
                            type="button"
                            className="green-button"
                            onClick={() => setFileUploadModal(true)}
                          >
                            <FormattedMessage id="profile.documents.upload" />
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="modal-file-content">
                      <Modal show={fileUploadModal}>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              position: "relative",
                              top: "7px",
                              right: "12px",
                            }}
                          >
                            <Image
                              className="closeicon"
                              src="Close_Icon.svg"
                              height="20px"
                              width="20px"
                              onClick={() => setFileUploadModal(false)}
                            />
                          </div>
                          <div
                            className="label"
                            style={{ marginLeft: 20, textAlign: "left" }}
                          >
                            UPLOAD
                          </div>
                          <div
                            className="col-sm-12 d-flex"
                            style={{ alignItems: "baseline" }}
                          >
                            <div className="col-sm-7 mt-4">
                              <div
                                className="form-input-block mb-dropdown"
                                style={{ cursor: "unset" }}
                              >
                                <Input
                                  name="uploadHint"
                                  value={"Upload from your computer"}
                                  disabled={true}
                                />

                                <span
                                  style={{
                                    fontSize: 9,
                                  }}
                                >
                                  Valid File Extensions are .pdf, .gif, .jpg,
                                  .jpeg, .png
                                  <br />
                                </span>
                                <span
                                  style={{
                                    fontSize: 9,
                                  }}
                                >
                                  {
                                    "The following special characters are not allowed in the filename: * + / : ; , < = > ?  [ ] |"
                                  }
                                  <br />
                                </span>
                                <span
                                  style={{
                                    fontSize: 9,
                                  }}
                                >
                                  Choose your file to upload (max size 4 MB)
                                </span>
                              </div>
                            </div>
                            <div className="col-sm-1">
                              <div className="col-sm-12 col-md-12 col-lg-12 text-left py-2 m-b-2 position-relative ">
                                <input
                                  ref={fileInputRef}
                                  className="cancel-button"
                                  type="file"
                                  style={{ display: "none" }}
                                  id="inputFile"
                                  onChange={(e) => {
                                    const file = e.target?.files[0]
                                    const maxFileSize = 4 * 1024 * 1024 // 4MB limit
                                    const allowedExtensions =
                                      /(\.pdf|\.gif|\.jpg|\.jpeg|\.png)$/i // Valid extensions
                                    const invalidCharacters =
                                      /[*+\/:;,<=>?\\[\]|]/ // Invalid characters

                                    if (file) {
                                      const fileName = file.name

                                      // Check file size
                                      if (file.size > maxFileSize) {
                                        const errmsg =
                                          "File size exceeds 4MB. Please select a smaller file."
                                        setFileUploadModal(false)
                                        toast.error(errmsg, { autoClose: 3000 })
                                        setFieldValue("files", "")
                                        setFileName("")
                                        setFiles(null)
                                        return
                                      }

                                      // Check file extension
                                      if (!allowedExtensions.test(fileName)) {
                                        const errmsg =
                                          "Invalid file type. Allowed types: .pdf, .gif, .jpg, .jpeg, .png"
                                        setFileUploadModal(false)
                                        toast.error(errmsg, { autoClose: 3000 })
                                        setFieldValue("files", "")
                                        setFileName("")
                                        setFiles(null)
                                        return
                                      }

                                      // Check for invalid characters in file name
                                      if (invalidCharacters.test(fileName)) {
                                        const errmsg =
                                          "Invalid characters in file name. Please avoid: * + / : ; , < = > ? \\ [ ] |"
                                        setFileUploadModal(false)
                                        toast.error(errmsg, { autoClose: 3000 })
                                        setFieldValue("files", "")
                                        setFileName("")
                                        setFiles(null)
                                        return
                                      }

                                      // If everything is valid, proceed
                                      setFieldValue("files", fileName)
                                      setFileName(fileName)
                                      setFiles(file)
                                      setFileUploadModal(false)
                                      toast.success(
                                        "File has been uploaded successfully",
                                        { autoClose: 1000 }
                                      )
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 pb-2">
                              <button
                                type="button"
                                className="green-button"
                                htmlFor={"inputFile"}
                                onClick={(e) => handleFileUpload(e)}
                              >
                                <FormattedMessage id="generator.createGenerator.browse" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                    <div className="modal-sucess-content">
                      <Modal show={showSuccessModal}>
                        <div className="gen-modal-content">
                          <div className="label">
                            <span>{createdGenId} </span>
                            <FormattedMessage
                              id={"generator.createGenerator.successMessage"}
                            />
                          </div>
                          <div>
                            <button
                              type="button"
                              className="mt-3 green-button"
                              onClick={() => {
                                setShowSuccessModal(false)
                                cancelAction()
                              }}
                            >
                              {"OK"}
                            </button>
                          </div>
                        </div>
                      </Modal>
                    </div>
                    <div
                      className="modal-sucess-content"
                      style={{ position: "relative", overflow: "visible" }}
                    >
                      <Modal show={showDupGenModal}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                          }}
                        >
                          <Image
                            className="closeicon"
                            src="close_icon.png"
                            height="20px"
                            width="20px"
                            onClick={() => setShowDupGenModal(false)}
                          />
                        </div>
                        <div
                          className="modal-text mt-4 mb-4 ml-4"
                          style={{ textAlign: "left" }}
                        >
                          THIS GENERATOR CURRENTLY EXISTS
                        </div>
                        <div
                          className="mt-4 mb-4 ml-4"
                          style={{ textAlign: "left", fontSize: 12 }}
                        >
                          Do You Wish To Proceed With Creating A Duplicate
                          Generator? If So, Please Consider &nbsp;&nbsp;&nbsp;{" "}
                          <br />
                          Adjusting The Name To Make It Distinct
                        </div>
                        <div className="row m-0 new-row-block">
                          <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2">
                            <div className="row m-0 float-right m-r-2r">
                              <button
                                type="submit"
                                className={`green-button`}
                                onClick={() => {
                                  setShowDupGenModal(false)
                                  onDuplicateSubmit(savedInfo)
                                }}
                              >
                                OK
                              </button>
                            </div>
                            <div className="row m-0 float-right m-r-2r">
                              <button
                                type="button"
                                className="cancel-button"
                                onClick={() => setShowDupGenModal(false)}
                              >
                                CANCEL
                              </button>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                  {errors?.files && (
                    <div
                      className="col-sm-12 d-flex mb-3 ml-4 p-0"
                      style={{ marginTop: "-25px" }}
                    >
                      <div className="col-sm-4" />
                      <div className="col-sm-4" />
                      <div
                        className="col-sm-4 d-flex px-0"
                        style={{
                          justifyContent: "flex-start",
                          marginLeft: 26,
                          marginTop: -11,
                          color: "red",
                          fontSize: 10,
                        }}
                      >
                        {errors.files ? <div>{errors.files}</div> : null}
                      </div>
                    </div>
                  )}
                  {fileName && values.isTsdfApprovalFile === true && (
                    <div
                      className="col-sm-12 d-flex mb-3 ml-4 p-0"
                      style={{ marginTop: "-25px" }}
                    >
                      <div className="col-sm-4" />
                      <div className="col-sm-4" />
                      <div
                        className="col-sm-4 d-flex px-0"
                        style={{
                          justifyContent: "flex-start",
                          fontSize: 14,
                          marginLeft: 26,
                          marginTop: -11,
                        }}
                      >
                        {fileName}
                      </div>
                    </div>
                  )}

                  <div className="col-sm-12 ml-4">
                    <div className="form-input-block mb-dropdown">
                      <span className="label" style={{ zIndex: 8 }}>
                        <FormattedMessage id="generator.createGenerator.disposalIns" />
                      </span>
                      <Input
                        name="disposalInstructions"
                        charlen={255}
                        value={values?.disposalInstructions}
                        onFieldChange={(e) => {
                          setFieldValue("disposalInstructions", e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0 new-row-block">
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2 ml-4">
                    <div className="row m-0 float-right m-r-2r">
                      {loading == false ? (
                        <button type="submit" className={`green-button`}>
                          Submit
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className={"disabledBgBtn"}
                          disabled
                        >
                          Submit
                        </button>
                      )}
                    </div>
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => cancelAction()}
                      >
                        Cancel
                      </button>
                    </div>
                    <span className="float-right text-red m-r-2 mr-2">
                      {`*Required Fields`}
                    </span>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Fragment>
  )
}
export default enhancer(CreateGenerator)
