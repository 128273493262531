import { REACT_APP_CLARITY_PROJECT_ID as CLARITY_PROJECT_ID } from "config"

export const initializeClarity = () => {
  //const clarityProjectId = process.env.REACT_APP_CLARITY_PROJECT_ID;
  // console.log(CLARITY_PROJECT_ID, "CLARITY_PROJECT_ID")
  if (!CLARITY_PROJECT_ID) {
    return
  }

  ;(function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        ;(c[a].q = c[a].q || []).push(arguments)
      }
    t = l.createElement(r)
    t.async = 1
    t.src = "https://www.clarity.ms/tag/" + i
    y = l.getElementsByTagName(r)[0]
    y.parentNode.insertBefore(t, y)
  })(window, document, "clarity", "script", CLARITY_PROJECT_ID)
}
