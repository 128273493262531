import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Loading from "shared/Loading"
import classnames from "classnames"
import { Formik, Form } from "formik"
import { Field } from "shared/Formik"
import HeaderBar from "shared/HeaderBar"
import { useHarsco } from "shared"
import Select from "../Admin/Components/Select"

function Profile(props) {
  let {
    formik,
    initialValues,
    onSubmitRequest,
    location,
    tonsConversionUpdated,
    updateTonsConverter,
    onSelectValue,
    open,
    onSelectField,
    customers,
    user,
    selectedCustomer,
    handleSearchChange,
    onChange,
    isEdit,
    roles,
  } = props
  const showHeading = location ? true : false
  const { isFullCircle } = useHarsco()

  const handleOnChange = (e, values, errors) => {
    onChange(e, values)
  }

  return (
    <Fragment>
      <div className="">
        <Loading id="updateuser">
          <div className="col-sm-12 col-md-12 col-lg-12 profile">
            {showHeading && (
              <div className="m-0 headinglabel mt-3 text-left">
                <FormattedMessage id="edit.profile.heading" />
              </div>
            )}
            <div className="profileform">
              {!showHeading && (
                <>
                  <HeaderBar />
                  <div className="formrow">
                    <div className="offset-2 pl-3 pt-4">
                      <FormattedMessage id="profile.fillToContinue" />
                    </div>
                  </div>
                </>
              )}
              <Formik {...formik} initialValues={initialValues}>
                {({ values }) => (
                  <Form onChange={(e) => handleOnChange(e, values)}>
                    <div className="row formrow ">
                      <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                      <div className="col-sm-4 col-md-4 col-lg-4 ">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="profile.firstname" />
                          </span>
                          <Field
                            type="text"
                            className="input-box "
                            name="firstName"
                          />
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 ">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="profile.lastname" />
                          </span>
                          <Field
                            type="text"
                            className="input-box "
                            name="lastName"
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                    </div>
                    <div className="row formrow ">
                      <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                      <div className="col-sm-4 col-md-4 col-lg-4 ">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="profile.contactphone" />
                          </span>
                          <Field
                            mask="+1 999-999-9999"
                            className="input-box"
                            name="phone"
                          />
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 ">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="profile.email" />
                          </span>
                          <Field
                            type="email"
                            className="input-box email"
                            name="communicationEmail"
                            cols="3"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                    </div>
                    <div className="row formrow ">
                      <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                      <div className="col-sm-8 col-md-8 col-lg-8 ">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="profile.jobTitle" />
                          </span>
                          <Field
                            type="text"
                            className="input-box"
                            name="title"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                    </div>
                    {(user?.roleId === roles.CE_ADMIN ||
                      user?.roleId === roles.CE_GENERIC_USER ||
                      user?.roleId === roles.CUSTOMER_ADMIN ||
                      user?.roleId === roles.PORTAL_ADMIN ||
                      user?.roleId === roles.CE_COMMERCIAL_USER ||
                      user?.roleId === roles.ENTERPRISE_USER) && (
                      <div className="row formrow ">
                        <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                        <div
                          className={`col-sm-8 col-md-8 col-lg-8 ${
                            user?.roleId === roles.CUSTOMER_ADMIN &&
                            "prevent-click"
                          }`}
                        >
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage
                                id={
                                  user?.roleId === roles.CUSTOMER_ADMIN
                                    ? "createuser.titleaccount"
                                    : "createuser.titledefaultaccount"
                                }
                              />
                            </span>
                            <Select
                              name="userType"
                              onSelectField={onSelectField}
                              open={open}
                              search={true}
                              placeholder={"Select Customer"}
                              // options={customers}
                              options={
                                user?.roleId === roles.CE_COMMERCIAL_USER
                                  ? customers?.filter(
                                      (val) => val.businessSegmentId == 3
                                    )
                                  : customers
                              }
                              onSelectValue={onSelectValue}
                              value={selectedCustomer}
                              handleSearchChange={handleSearchChange}
                              loadingId={"customer_search"}
                              searchField={"customerSearch"}
                              cls={"left-align"}
                              disabled={user?.roleId == roles.CUSTOMER_ADMIN}
                            />
                          </div>
                          {user?.businessSegmentId === 3 &&
                          user?.roleId !== roles.CE_COMMERCIAL_USER ? (
                            <div className="default-text">
                              <FormattedMessage id="createuser.defaultaccounttextMI" />
                            </div>
                          ) : (
                            (user?.roleId === roles.CE_GENERIC_USER ||
                              user?.roleId === roles.CE_ADMIN ||
                              user?.roleId === roles.PORTAL_ADMIN) && (
                              <div className="default-text">
                                <FormattedMessage id="createuser.defaultaccounttext" />
                              </div>
                            )
                          )}
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                      </div>
                    )}
                    {isFullCircle && (
                      <div className="row formrow ">
                        <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                        <div className="col-sm-3 col-md-3 col-lg-3 pl-0 ">
                          <span className="label">
                            Preferred Units For Dashboard Tile
                          </span>
                        </div>
                        <div className="col-sm-5 col-md-5 col-lg-5 displayFlex ">
                          <span className="toggleText">Tons</span>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={tonsConversionUpdated}
                              onChange={updateTonsConverter}
                            />
                            <span className="slider round"></span>
                          </label>
                          <span className="toggleText">MT</span>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                      </div>
                    )}
                    <div className={`row formrow`}>
                      <div className="text-center m-auto">
                        <button
                          className={classnames("savebutton", {
                            "none-clickable": !isEdit && showHeading,
                          })}
                          onClick={onSubmitRequest}
                        >
                          {showHeading ? (
                            <FormattedMessage id="profile.savechanges" />
                          ) : (
                            <FormattedMessage id="profile.continue" />
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Loading>
      </div>
    </Fragment>
  )
}
export default enhancer(Profile)
