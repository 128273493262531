import React, { useState, useEffect, useRef } from "react"
import classnames from "classnames"
import searchs from "assets/icon/ic_search.svg"
import EllipsisText from "react-ellipsis-text"

let Select = (props) => {
  let {
    options,
    labelKey = "l",
    onSelect,
    className,
    label,
    open,
    setOpen,
    hasSearch,
    selected,
    prependLabel,
    short,
    medium,
    long,
    size,
    required,
    disabled,
    isError,
    allowSelect,
    selectLabelType,
    fromaNaics,
  } = props
  let [search, setSearch] = useState("")
  const elementRef = useRef()

  const isHalfSize = size == "half" ? true : false

  let updateSelect = (d) => () => {
    onSelect && onSelect(d)
    setOpen(false)
  }

  let isFiltered = (d) => {
    if (search != "") return !d.toLowerCase().includes(search.toLowerCase())
    return false
  }
  let isLengthcheck = (d) => {
    if (d?.length > 25) return true
    return false
  }

  let len = selected && selected.l

  useEffect(() => {
    setSearch("")
    if (elementRef.current.scrollTop > 0) {
      elementRef.current.scrollTop = 0
    }
  }, [open])

  if (prependLabel) label = `${label}:`

  return (
    <div
      className={classnames(
        "custom-dropdown",
        {
          active: open,
          [className]: true,
          "single-half": isHalfSize,
        },
        len && len.length > 21 ? "lengthy" : "none",
        label == "Report Type" ? "lengthy" : "none"
      )}
      role="button"
      style={{ pointerEvents: disabled ? "none" : "auto" }}
    >
      <div
        onClick={() => setOpen(!open)}
        className={classnames(
          disabled ? "disabledSbg" : "none",
          "drop-select",
          { "has-error": open === false ? isError : "" },
          len && len.length > 21 ? "lengthy" : "none",
          label == "Report Type" ? "lengthy" : "none"
        )}
      >
        <span className="inner-label">
          {required && <span className="required-star-red mar-bottom">*</span>}
          <EllipsisText
            text={
              prependLabel
                ? `${label} ${selected?.[labelKey] || ""}`
                : selected
                ? selected?.[labelKey] || ""
                : label
            }
            length={short ? 18 : medium ? 45 : long ? 100 : 30}
          />
        </span>
        <span
          className={classnames(
            "position-absolute",
            selected && selected[labelKey]?.length > 45
              ? "roundbracket"
              : "d-none"
          )}
        ></span>
        <span
          className={classnames("drop-arrows", { down: !open, up: open })}
          // className={classnames("arrow", { down: !open, up: open })}
        ></span>
        <span></span>
      </div>

      <ul className="drop-option select-single" ref={elementRef}>
        {hasSearch && (
          <li
            className={"search-box inputfield mt-2 px-0 mb-2"}
            style={{
              backgroundColor: "transparent",
            }}
          >
            <input
              type="text"
              placeholder="Search"
              className="searchField"
              onChange={(e) => setSearch(e.currentTarget.value)}
              value={search}
            />
            <img
              className="serchicon"
              src={searchs}
              height="9px"
              width="8px"
              alt=""
            />
          </li>
        )}
        <React.Fragment>
          {allowSelect && (
            <li
              className={classnames(
                "pl-3",
                "ellipsisText",
                { "d-none": isFiltered("") },
                { high: isLengthcheck("") }
              )}
              onClick={
                selectLabelType ? () => {} : updateSelect({ labelKey: "" })
              }
              key={-1}
              title={""}
            >
              {`-- Select ${selectLabelType} --`}
            </li>
          )}
          {options?.map((d) => (
            <li
              className={classnames(
                "pl-3",
                "ellipsisText",
                { "d-none": isFiltered(d[labelKey]) },
                { high: isLengthcheck(d[labelKey]) }
              )}
              onClick={updateSelect(d)}
              key={
                fromaNaics === true
                  ? `${d[labelKey]}${d["v"]}`
                  : d.stateCodeId
                  ? d.stateCodeId
                  : d[labelKey]
              }
              title={isLengthcheck(d[labelKey]) ? d[labelKey] : ""}
            >
              {d[labelKey]}
            </li>
          ))}
        </React.Fragment>
      </ul>
    </div>
  )
}

export default Select
