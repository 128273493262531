import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import api from "api"
import { syncUserProfile, setCustomerData } from "shared"
import { roles } from "config"
import { useEffect } from "react"
import useDebounce from "shared/debounce"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    const user = useSelector((state) => state.profile)
    const {
      firstName,
      lastName,
      phone,
      email,
      title,
      userPreferences,
      communicationEmail,
    } = user
    const { is_quickTour_completed, is_tonsConversion_updated } =
      JSON.parse(userPreferences)

    const initialValues = {
      firstName,
      lastName,
      phone,
      email,
      title,
      communicationEmail,
    }

    const [tonsConversionUpdated, setTonsConverter] = useState(
      is_tonsConversion_updated
    )
    let formik = {
      enableReinitialize: true,
    }

    const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

    let valSchema = {
      phone: Yup.string()
        .nullable()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Please enter your phone number"),
      email: Yup.string()
        .email("Invalid email")
        .required("Please enter your email"),
      communicationEmail: Yup.string()
        .email("Invalid email")
        .required("Please enter your email"),
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter last name"),
      title: Yup.string().nullable().required("Please enter job title"),
    }
    formik.validationSchema = Yup.object().shape(valSchema)
    const [formData] = useState({})

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [customers, setCustomers] = useState([])
    const [isEdit, setEdit] = useState(false)

    const [selectedCustomer, setSelectedCustomer] = useState({
      l: `${user?.customer_name} - ${user?.customer_id}`,
      v: user?.customer_id,
    })
    const [customerSearch, setSearch] = useState()

    const { customerList, changeCustomer } = useSelector((state) => state)

    useEffect(() => {
      ;(async () => {
        getInitialCustomer()
      })()
    }, [])

    useEffect(() => {
      ;(async () => {
        if (customerSearch?.length > 2) {
          let res = await api.g_onboardedcustomers({
            searchText: customerSearch,
            loading_key: "customer_search",
          })
          let customer = setCustomerData(res)
          setCustomers(customer)
        } else if (customerSearch?.length == 0) {
          getInitialCustomer()
        }
      })()
    }, [useDebounce(customerSearch, 1000)])

    const getInitialCustomer = async () => {
      if (
        customerList.length === 0 &&
        (user?.roleId === roles.PORTAL_ADMIN ||
          user?.roleId === roles.CE_ADMIN ||
          user?.roleId === roles.CE_GENERIC_USER ||
          user?.roleId === roles.CUSTOMER_ADMIN ||
          user?.roleId === roles.ENTERPRISE_USER)
      ) {
        let resCustomer = await api.g_onboardedcustomers()
        if (resCustomer) {
          let customer = []
          customer = setCustomerData(resCustomer)
          setCustomers(customer)
          dispatch({ type: "SET_CUSTOMERS", customers: customer })
        }
      } else {
        setCustomers(customerList)
      }
    }

    const handleSearchChange = (e) => {
      if (e.target.name === "customerSearch") setSearch(e.target.value)
    }

    const onSelectField = (name, value) => {
      setOpen(value)
      if (!value) {
        getInitialCustomer()
      }
    }

    const onSelectValue = (title, value) => {
      if (user?.customer_id !== value?.v) {
        setEdit(true)
      } else {
        setEdit(false)
      }
      setSelectedCustomer({ ...value })
    }

    formik.onSubmit = async (formData) => {
      let values = {}
      values["is_quickTour_completed"] = is_quickTour_completed
      values["is_tonsConversion_updated"] = tonsConversionUpdated
      if (
        (user?.roleId === roles.CE_ADMIN ||
          user?.roleId === roles.CE_GENERIC_USER ||
          user?.roleId === roles.PORTAL_ADMIN ||
          user?.roleId === roles.CE_COMMERCIAL_USER ||
          user?.roleId === roles.ENTERPRISE_USER) &&
        selectedCustomer.v !== user.customer_id
      ) {
        let resCustomer
        try {
          resCustomer = await api.pa_updateDefaultCustomer({
            customer_id: selectedCustomer?.v,
            shouldUpdateDefaultCustomer: true,
            loading_key: "updateuser",
          })
        } catch (err) {
          dispatch({
            type: "SET_CHANGE_CUSTOMER",
            status: false,
            customer_name: changeCustomer?.customer,
            source: false,
          })
        }
        if (resCustomer) {
          dispatch({
            type: "SET_CHANGE_CUSTOMER",
            status: true,
            customer_name: selectedCustomer,
            source: true,
          })
        }
        await updateUserDetails(formData, values)
      } else {
        await updateUserDetails(formData, values)
      }
    }

    const updateUserDetails = async (formData, values) => {
      let res = await api.pa_user({
        loading_key: "updateuser",
        ...formData,
        userPreferences: JSON.stringify(values),
      })
      if (!res) return res

      if (!props.location) await syncUserProfile()
      else {
        history.push({
          pathname: "/editprofilecheckout",
          state: {
            ...formData,
            accountName: selectedCustomer?.l,
          },
        })
        await syncUserProfile()
      }
    }

    const updateTonsConverter = (e) => {
      setTonsConverter(e.currentTarget.checked)
    }

    const compareObjects = (firstObject, secondObject) => {
      //initial values
      let firstObjectKeys = Object.values(firstObject).sort()

      //changed values
      let secondObjectKeys = Object.values(secondObject).sort()

      //comparing both values
      return firstObjectKeys.join() === secondObjectKeys.join()
    }

    const onChange = (e, values) => {
      values = { ...values, [e.target.name]: e.target.value.trim() }
      if (!compareObjects(initialValues, values)) {
        setEdit(true)
      } else {
        setEdit(false)
      }
    }

    return (
      <Component
        {...props}
        {...{
          formData,
          user,
          formik,
          initialValues,
          tonsConversionUpdated,
          updateTonsConverter,
          open,
          customers,
          selectedCustomer,
          customerSearch,
          handleSearchChange,
          onSelectField,
          onSelectValue,
          onChange,
          isEdit,
          roles,
        }}
      />
    )
  }

export default enhancer
