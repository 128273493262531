import React, { useEffect, useState } from "react"
import api from "api"
import { useHarsco } from "shared"
import {
  CURRENT_PROFILE,
  GET_PROGRESS_STEP,
  RETRIEVE_DOCUMENTS,
  SET_ACTIVE_STEP,
  SET_PROGRESS_STEP,
  SET_PROGRESS_STEP_ONLY,
  ERROR_HIGHLIGHT,
} from "store/reducer/actionType"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import {
  getBooleanValues,
  getDateValues,
  getValues,
} from "routes/Utilities/util"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

let generatorcertificationenhancer =
  (Component) =>
  ({ ...props }) => {
    const [showError, setShowError] = useState(false)
    const [showAppError, setShowAppError] = useState(false)
    const history = useHistory()
    const { profile } = useHarsco()
    const dispatch = useDispatch()
    // const { customer_name } = useHarsco()
    const { id, type } = useParams()
    const [fileUpload, setFileUpload] = useState(null)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [success, setSuccess] = useState(null)
    const [failure, setFailure] = useState(null)
    const [submitUpload, setSubmitUpload] = useState(null)
    // eslint-disable-next-line
    const [loader, setLoader] = useState(false)
    const applicationProgress = useSelector(
      (state) => state.profileDetails.applicationProgress
    )

    const initValues = {
      customerSignatureName: "",
      customerSignatureTitle: "",
      customerSignatureDatetime: new Date(),
      electronicSybmissionFlag: false,
      letterOfConcentFlag: false,
      letterOfConcent: "",
      presignedstatus: false,
    }
    const [initialValues, setInitialValues] = useState(initValues)
    const availDocuments = useSelector(
      (state) => state.generatorDetails.availabledocDocuments
    )

    const profileList = useSelector(
      (state) => state.profileDetails.profilesList
    )
    const esignedDocs = availDocuments?.filter(
      (val) => val?.documentTypeId == 27
    )
    const presignDocs = availDocuments?.filter(
      (val) => val?.documentTypeId == 9
    )

    const currentProfile = useSelector(
      (state) => state.profileDetails?.currentProfile
    )

    let valSchema = {
      customerSignatureName: Yup.string().required("Name is required"),
      customerSignatureTitle: Yup.string().required("Title is required"),
      customerSignatureDatetime: Yup.string()
        .nullable()
        .required("Date is required"),
      electronicSybmissionFlag: Yup.boolean().when("presignedstatus", {
        is: (presignedstatus) => !presignedstatus,
        then: Yup.boolean().required("Select any of the options"),
        otherwise: Yup.boolean(),
      }),
      presignedstatus: Yup.boolean().when("electronicSybmissionFlag", {
        is: (electronicSybmissionFlag) => !electronicSybmissionFlag,
        then: Yup.boolean().required("Select any of the options"),
        otherwise: Yup.boolean(),
      }),
      letterOfConcentFlag: Yup.boolean(),
      // .when("electronicSybmissionFlag", {
      //   is: (electronicSybmissionFlag) => electronicSybmissionFlag === true,
      //   then: Yup.boolean().required("Letter of Consent is required"),
      //   otherwise: Yup.boolean(),
      // }),
      //   .test(
      //   "validate",
      //   "Letter of Consent is required",
      //   (data, letterOfConcentFlag) => {
      //     return letterOfConcentFlag?.parent?.electronicSybmissionFlag
      //   }
      // ),
      letterOfConcent: Yup.string(),
    }

    const isApplicationValid =
      currentProfile?.prfchange == "N"
        ? applicationProgress?.["8"]?.percentage == 100 &&
          applicationProgress?.["9"]?.percentage == 100
        : applicationProgress?.["1"]?.percentage == 100 &&
          applicationProgress?.["2"]?.percentage == 100 &&
          applicationProgress?.["3"]?.percentage == 100 &&
          applicationProgress?.["4"]?.percentage == 100 &&
          applicationProgress?.["5"]?.percentage == 100 &&
          applicationProgress?.["6"]?.percentage == 100 &&
          applicationProgress?.["7"]?.percentage == 100 &&
          applicationProgress?.["8"]?.percentage == 100
    // applicationProgress?.["1"] > 0 &&
    // applicationProgress?.["2"] > 4 &&
    // applicationProgress?.["3"] == 100 &&
    // applicationProgress?.["4"] > 0 &&
    // applicationProgress?.["5"] > 3.8 &&
    // applicationProgress?.["6"] > 0 &&
    // applicationProgress?.["7"] > 0
    // &&
    // applicationProgress?.["8"] == 100
    const getProfileData = async (reqId) => {
      const req = {
        profileRequestId: id || reqId,
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }

    const handleErrorHighlight = (flag) => {
      for (var val in applicationProgress) {
        dispatch({
          type: ERROR_HIGHLIGHT,
          step: val,
          errorFlag:
            applicationProgress?.[val]?.percentage !== 100 ? true : false,
        })
      }
    }

    const onSubmit = async (data, isReadyForSubmit) => {
      if (
        (!Boolean(isApplicationValid) && isReadyForSubmit) ||
        !data?.customerSignatureDatetime ||
        !data?.customerSignatureName ||
        !data?.customerSignatureTitle ||
        (data?.electronicSybmissionFlag && esignedDocs?.length === 0) ||
        (data?.presignedstatus && presignDocs?.length === 0)
      ) {
        return
      }
      props.setShowAlert(false)
      let required_length = 3
      if (data?.presignedstatus) required_length = required_length + 1
      if (data?.electronicSybmissionFlag && !data?.presignedstatus)
        required_length = required_length + 1
      let req = {
        isReadyForSubmit: isReadyForSubmit,
        profileRequestId: currentProfile?.profileRequestId || id,
        customerSignatureName: data?.customerSignatureName,
        customerSignatureTitle: data?.customerSignatureTitle,
        customerSignatureDatetime: data?.customerSignatureDatetime,
        electronicSybmissionFlag: data?.electronicSybmissionFlag,
        letterOfConcentFlag: data?.electronicSybmissionFlag ? true : false,
        letterOfConcent: data?.electronicSybmissionFlag
          ? data?.letterOfConcent
          : "",
        presignedstatus: data?.presignedstatus ? 1 : 0,
      }
      // var formData = new FormData()
      // formData.append("customerId", changeCustomer?.customer?.v)
      // formData.append("roleId", "GenericRole")
      // formData.append("userName", profile?.email)
      // formData.append(
      //   "profileRequestId",
      //   currentProfile?.profileRequestId || id
      // )
      // formData.append("customerSignatureName", data?.customerSignatureName)
      // formData.append("customerSignatureTitle", data?.customerSignatureTitle)
      // formData.append(
      //   "customerSignatureDatetime",
      //   data?.customerSignatureDatetime?.toISOString() || ""
      // )
      // formData.append(
      //   "electronicSybmissionFlag",
      //   data?.electronicSybmissionFlag
      // )
      // formData.append(
      //   "letterOfConcentFlag",
      //   data?.electronicSybmissionFlag ? data?.letterOfConcentFlag : false
      // )
      // formData.append(
      //   "letterOfConcent",
      //   data?.electronicSybmissionFlag ? data?.letterOfConcent : ""
      // )
      // formData.append("presignedstatus", data?.presignedstatus ? 1 : 0)
      // formData.append("documentTypeId", 63)
      // formData.append("documentTypeDescription", "Signature Authorization")
      // formData.append("tabName", "Generator Certification")
      // formData.append("file", fileUpload)

      try {
        let res = await api.p_saveGeneratorCertification(req)
        if (!res) return
        if (!isReadyForSubmit) {
          toast.success(res?.message)
          setProgress(data, required_length)
          getProfileData(currentProfile?.profileRequestId)
          // setTimeout(() => {
          //   cancelAction()
          // }, 2000)
          return
        }
        if (res.statusCode == 200) {
          setSuccess(true)
          setFailure(false)
        } else {
          setSuccess(false)
          setFailure(true)
        }
        setProgress(data, required_length)
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {
        setSuccess(false)
        setFailure(true)
        // dispatch({
        //   type: "SET_CHANGE_CUSTOMER",
        //   status: false,
        //   customer_name: changeCustomer?.customer,
        //   source: false,
        // })
      }
    }

    const onSubmitProgress = async (data, step) => {
      if (
        // (!Boolean(isApplicationValid) || !isReadyForSubmit) ||
        !data?.customerSignatureDatetime ||
        !data?.customerSignatureName ||
        !data?.customerSignatureTitle ||
        (data?.electronicSybmissionFlag && esignedDocs?.length === 0) ||
        (data?.presignedstatus && presignDocs?.length === 0)
      ) {
        return
      }
      props.setShowAlert(false)
      let required_length = 3
      if (data?.presignedstatus) required_length = required_length + 1
      if (data?.electronicSybmissionFlag && !data?.presignedstatus)
        required_length = required_length + 1
      let req = {
        isReadyForSubmit: false,
        profileRequestId: currentProfile?.profileRequestId || id,
        customerSignatureName: data?.customerSignatureName,
        customerSignatureTitle: data?.customerSignatureTitle,
        customerSignatureDatetime: data?.customerSignatureDatetime,
        electronicSybmissionFlag: data?.electronicSybmissionFlag,
        letterOfConcentFlag: data?.electronicSybmissionFlag ? true : false,
        letterOfConcent: data?.electronicSybmissionFlag
          ? data?.letterOfConcent
          : "",
        presignedstatus: data?.presignedstatus ? 1 : 0,
      }

      try {
        let res = await api.p_saveGeneratorCertification(req)
        if (!res) return
        // if (res.message == 200) {
        //   setSuccess(true)
        //   setFailure(false)
        // } else {
        //   setSuccess(false)
        //   setFailure(true)
        // }
        setProgress(data, required_length)
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {
        setSuccess(false)
        setFailure(true)
      }
    }

    const retrieveDocuments = async (reqId) => {
      const req = {
        profileRequestId: reqId,
        documentTypeEntity: "Profile",
        documentDesc: "Generator Certification",
      }
      try {
        let res = await api.p_getDocuments(req)
        if (!res) return
        dispatch({ type: RETRIEVE_DOCUMENTS, payload: res })
      } catch (error) {}
    }

    const onSubmitDocs = async (type) => {
      if (!fileUpload) {
        return
      }
      setSubmitUpload(true)
      setLoader(true)
      var formData = new FormData()
      formData.append("roleId", "GenericRole")
      formData.append("userName", profile?.email)
      formData.append("profileRequestId", currentProfile?.profileRequestId)
      // formData.append("documentTypeId", null)
      formData.append("documentTypeEntity", "Profile")
      formData.append(
        "documentTypeDescription",
        type ? type : "Signature Authorization"
      )
      formData.append("tabName", "Generator Certification")
      formData.append("files", fileUpload)

      try {
        let res = await api.f_postDocuments(formData)
        if (!res) return
        retrieveDocuments(currentProfile?.profileRequestId)
        toast.success("File has been Uploaded Successfully")
      } catch (err) {
        toast.error("File upload has failed")
      } finally {
        setLoader(false)
      }
    }
    const deleteDocument = async (data, profileId) => {
      try {
        let res = await api.p_deleteDocument({
          profileRequestDocumentSeqid: data?.profileRequestDocumentSeqids,
          profileRequestId: profileId,
        })
        if (!res) return
        retrieveDocuments(currentProfile?.profileRequestId)
        toast.success("Document has been deleted successfully")
      } catch (error) {}
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: Yup.object().shape(valSchema, [
        ["electronicSybmissionFlag", "presignedstatus"],
      ]),
      onSubmit: onSubmit,
    }

    const goToProfilePages = (page) => {
      history.push(`/profiles/${page}`)
    }

    const backAction = () => {
      dispatch({ type: SET_ACTIVE_STEP, payload: 8 })
    }

    const setProgress = (data, length) => {
      let count = 0
      let non_requiredcount = 0
      for (var prop in initValues) {
        if (
          prop == "customerSignatureTitle" ||
          prop == "customerSignatureName" ||
          prop == "customerSignatureDatetime"
        ) {
          if (data[prop]) {
            count = count + 1
          }
        } else if (prop == "presignedstatus") {
          if (data[prop] && data["electronicSybmissionFlag"] == false) {
            count = count + 1
          }
        } else if (prop == "electronicSybmissionFlag") {
          if (data[prop] && data["presignedstatus"] == false) {
            count = count + 1
          }
        } else {
          if (data[prop]) {
            non_requiredcount = non_requiredcount + 1
          }
        }
      }
      dispatch({
        type: SET_PROGRESS_STEP,
        step: 9,
        progressStep: props.showAlert ? props.seekingStep + 1 : 9,
        percentage: (count / length) * 100,
        numerator: count,
        denominator: length,
      })
    }

    const calcProgress = async (count, leng) => {
      await dispatch({
        type: GET_PROGRESS_STEP,
        step: 9,
        progressStep: 9,
        percentage: (count / leng) * 100,
        numerator: count,
        denominator: leng,
      })
    }

    useEffect(() => {
      let data = {}
      if (currentProfile?.generatorId || type == "viewprofile") {
        let count = 0
        let required_length = 3
        let non_requiredcount = 0
        let eSubmissionFlag = getBooleanValues(
          currentProfile,
          "electronicSybmissionFlag",
          initValues?.["electronicSybmissionFlag"]
        )
        let presignedFlag = getBooleanValues(
          currentProfile,
          "presignedstatus",
          initValues?.["presignedstatus"]
        )
        if (eSubmissionFlag && !presignedFlag)
          required_length = required_length + 1
        if (presignedFlag && !eSubmissionFlag)
          required_length = required_length + 1
        for (var prop in initValues) {
          if (prop == "electronicSybmissionFlag" || prop == "presignedstatus") {
            data[prop] = getBooleanValues(
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]) {
              count = count + 1
            }
          } else if (prop == "customerSignatureDatetime") {
            data[prop] = getDateValues(currentProfile, prop, initValues?.[prop])
            if (data[prop]) {
              count = count + 1
            }
          } else if (
            prop == "customerSignatureName" ||
            prop == "customerSignatureTitle"
          ) {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (data[prop]) {
              count = count + 1
            }
          } else {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (data[prop]) {
              non_requiredcount = non_requiredcount + 1
            }
          }
        }
        calcProgress(count, required_length)
        setInitialValues(data)
      }
    }, [currentProfile?.generatorId])

    const cancelAction = () => {
      history.push(`/profiles`)
    }
    const downloadDocument = async (data, profileId) => {
      const getProfile = profileList.find(
        (item) => item.profileId == profileId || item.profile == profileId
      )
      const payload = {
        documentName: getProfile
          ? getProfile?.status?.toLowerCase() == "draft"
            ? data?.documentName
            : data?.filename?.replaceAll("/", "\\")
          : data?.documentName
          ? data?.documentName
          : data?.filename?.replaceAll("/", "\\"),
        isdraft: getProfile
          ? getProfile?.status?.toLowerCase() == "draft"
            ? true
            : false
          : data?.documentName
          ? true
          : false,
        // profileRequestDocumentSeqid: data?.profileRequestDocumentSeqids,
        // profileRequestId: profileId,
        // documentTypeId: data?.documentTypeId,
      }
      try {
        let res = await api.pb_downloadDocuments(payload)
        if (!res) return
        let url = window.URL.createObjectURL(res)
        let a = document.createElement("a")
        a.href = url
        a.download = getProfile
          ? getProfile?.status?.toLowerCase() == "draft"
            ? data?.documentName
            : data?.display_name
          : data?.documentName
          ? data?.documentName
          : data?.display_name
        a.click()
        a.remove()
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
      } catch (error) {}
    }

    const handleAlerts = (values, errors, isValid, step) => {
      if (
        !values?.customerSignatureDatetime ||
        !values?.customerSignatureName ||
        !values?.customerSignatureTitle ||
        (Object.keys(errors)?.length > 0 && !isValid) ||
        (values?.electronicSybmissionFlag && esignedDocs?.length === 0) ||
        (values?.presignedstatus && presignDocs?.length === 0)
      ) {
        setShowError(true)
        setShowAppError(false)
        props.setShowAlert(true)
      }
      // else if (!Boolean(isApplicationValid)) {
      //   setShowAppError(false)
      //   setShowError(true)
      //   props.setShowAlert(true)
      // }
      else {
        setShowError(false)
        setShowAppError(false)
        dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: step + 1 })
        onSubmitProgress(values, step + 1)
        props.setShowAlert(false)
      }
    }

    return (
      <Component
        {...props}
        {...{
          goToProfilePages,
          formik,
          backAction,
          fileUpload,
          setFileUpload,
          selectedFiles,
          setSelectedFiles,
          cancelAction,
          showError,
          setShowError,
          handleAlerts,
          onSubmitDocs,
          success,
          failure,
          setSuccess,
          setFailure,
          submitUpload,
          setSubmitUpload,
          downloadDocument,
          retrieveDocuments,
          isApplicationValid,
          showAppError,
          setShowAppError,
          deleteDocument,
          loader,
          handleErrorHighlight,
        }}
      />
    )
  }

export default generatorcertificationenhancer
