import { Fragment, useState } from "react"
import enhancer from "./enhancer"
import { roles } from "config"
// import classnames from "classnames"
import { FormattedMessage } from "react-intl"
import Loading from "shared/Loading"
import { useSelector } from "react-redux"

function RecertifyProfile(props) {
  let {
    goToProfile,
    // newProfileId,
    // handleNewProfile,
    // goToEditProfile,
    getRecertifiedProfile,
    viewProfile,
    // recertRes,
    // submit,
    setSubmit,
  } = props
  const [withFlag, setWithFlag] = useState(null)
  const [hasError, setHasError] = useState(false)
  // const [responseMessage, setResponseMessage] = useState("")

  const recertifyProfile = useSelector(
    (state) => state.profileDetails?.recertifyProfile
  )

  const handleSubmitRequest = () => {
    if (withFlag === null || withFlag === undefined) {
      setHasError(true)
    } else {
      getRecertifiedProfile(withFlag)
      setHasError(false)
    }
  }
  const handleExit = () => {
    setSubmit(false)
    setWithFlag(null)
    setHasError(false)
    goToProfile()
  }

  let profileId =
    recertifyProfile?.status !== "draft"
      ? recertifyProfile?.profile
      : recertifyProfile?.profileId

  // useEffect(() => {
  //   let msgArr = (recertRes?.message).split(" ")
  //   let msg = msgArr?.length > 1 ? msgArr.slice(1).join(" ") : ""
  //   setResponseMessage(msg)
  // }, [recertRes])

  return (
    <Fragment>
      <div className="col-sm-12">
        {/* <div
          className="m-0 pt-4 ml-3 myprofile text-left headerFonts"
          style={{ fontSize: "20px" }}
        >
          <FormattedMessage id="profile.recertify.title" />
        </div>
        {submit ? (
          <div className="col-sm-12">
            <div
              className="text-description text-left"
              style={{
                color: "#007bff",
                fontWeight: "bold",
                fontSize: "22px",
                padding: "1.5rem 0.5rem",
              }}
            >
              <FormattedMessage id="profile.recertify.thankyounote" />
            </div> */}
        {/* {withFlag === false && (
              <div>
                <div
                  className="text-description text-left headerFonts"
                  style={{ fontSize: "20px", padding: "1rem 0.5rem" }}
                >
                  <FormattedMessage id="profile.recertify.withoutChangeHeader" />
                </div>
                <div
                  className="d-flex"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#6C7894",
                    padding: "1rem 0.5rem",
                  }}
                >
                  <div
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      paddingRight: "5px",
                    }}
                    onClick={() =>
                      viewProfile(
                        recertRes?.newProfileNumber?.includes("P")
                          ? recertRes?.newProfileNumber?.substr(
                              1,
                              recertRes?.newProfileNumber?.length - 1
                            )
                          : recertRes?.newProfileNumber?.includes("-")
                          ? recertRes?.newProfileNumber?.split("-")[0]
                          : recertRes?.newProfileNumber
                      )
                    }
                  >
                    {recertRes?.newProfileNumber}
                  </div>
                  <div>{responseMessage}</div>
                </div>
              </div>
            )} */}
        {/* {withFlag ? (
              <div
                className="text-description text-left"
                style={{ color: "rgb(129 131 133)", fontSize: "16px" }}
              >
                {`${recertRes.newProfileNumber} Has Been Submitted To Core For Approval`}
              </div>
            ) : (
              <div
                className="text-description text-left"
                style={{ color: "rgb(129 131 133)", fontSize: "16px" }}
              >
                {`${recertRes.newProfileNumber} Has Been Submitted To Core For Approval`}
              </div>
            )} */}
        {/* {withFlag && (
              <div>
                <div
                  className="text-description text-left py-2 headerFonts"
                  style={{ fontSize: "20px", padding: "1rem 0.5rem" }}
                >
                  <FormattedMessage id="profile.recertify.withChangeHeader" />
                </div>
                <div
                  className="d-flex "
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#6C7894",
                    padding: "1rem 0.5rem",
                  }}
                >
                  <div
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      paddingRight: "5px",
                    }}
                    onClick={() =>
                      goToEditProfile(
                        "editprofile",
                        recertRes?.newProfileNumber?.includes("P")
                          ? recertRes?.newProfileNumber?.substr(
                              1,
                              recertRes?.newProfileNumber?.length - 1
                            )
                          : recertRes?.newProfileNumber
                      )
                    }
                  >
                    {recertRes?.newProfileNumber}
                  </div>
                  <div>
                    {recertRes?.newProfileNumber && responseMessage} */}
        {/* : `${
                          profileId.split("").includes("P")
                            ? profileId.substr(1, profileId.length - 1)
                            : profileId
                        } Already Submitted To Core For Approval`} */}
        {/* </div>
                </div>
              </div>
            )} */}

        {/* {withFlag ? (
              <div
                className="text-description text-left"
                style={{ color: "rgb(129 131 133)", fontSize: "16px" }}
              >
                {`${recertRes.newProfileNumber} Is Draft Copy of ${profileId}`}
              </div>
            ) : (
              <div
                className="text-description text-left"
                style={{ color: "rgb(129 131 133)", fontSize: "16px" }}
              >
                {`${recertRes.newProfileNumber} Is Working Copy of ${profileId}`}
              </div>
            )} */}
        {/* <div
              style={{
                // position: "fixed",
                // bottom: "55px",
                // right: 0,
                padding: "1rem",
                textAlign: "right",
              }}
            >
              <button
                className="downloadbtn"
                onClick={handleExit}
                style={{ padding: "6px 10px", minWidth: 140 }}
              >
                <FormattedMessage id="profile.recertify.exitButton" />
              </button>
            </div>
          </div>
        ) : ( */}
        <div style={{ marginTop: "1rem" }}>
          <Loading id="documents">
            <div
              className="col-sm-12 col-md-12 col-lg-12 mx-3 p-0 table sticky isshipreport"
              style={{
                maxHeight: 400,
                overflowY: "unset",
                overflowX: "unset",
              }}
            >
              <div className="row m-0 headers">
                <div className={`tr active`}>
                  <div
                    className={`th customsort inactive title-container`}
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 150,
                    }}
                  >
                    <span className="title-text">
                      <FormattedMessage id="profile.recertify.withoutChange" />
                    </span>
                  </div>
                  <div
                    className={`th customsort inactive title-container`}
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 150,
                    }}
                  >
                    <span className="title-text">
                      <FormattedMessage id="profile.recertify.withChange" />
                    </span>
                  </div>
                  <div
                    className={`th customsort inactive title-container`}
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 120,
                    }}
                  >
                    <span className="title-text">
                      <FormattedMessage id="profile.recertify.profileId" />
                    </span>
                  </div>
                  <div
                    className={`th customsort inactive title-container`}
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 140,
                    }}
                  >
                    <span className="title-text">
                      <FormattedMessage id="profile.recertify.startDate" />
                    </span>
                  </div>
                  <div
                    className={`th customsort inactive title-container`}
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 140,
                    }}
                  >
                    <span className="title-text">
                      <FormattedMessage id="profile.recertify.expiryDate" />
                    </span>
                  </div>
                  <div
                    className={`th customsort inactive title-container`}
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 230,
                    }}
                  >
                    <span className="title-text">
                      <FormattedMessage id="profile.recertify.wasteName" />
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="row m-0 headers"
                // style={{ display: "contents" }}
              >
                <div
                  className="tr active"
                  key={1}
                  style={{
                    display: "flex",
                    minHeight: 45,
                  }}
                >
                  <div
                    className="td custom"
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 150,
                      paddingTop: 15,
                    }}
                  >
                    <div className="radio-container">
                      <input
                        id="radioCustomColor"
                        style={{ marginLeft: -9 }}
                        type="radio"
                        name="recertChange"
                        value={"without"}
                        onChange={() => {
                          setWithFlag(false)
                          setHasError(false)
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="td custom"
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 150,
                      paddingTop: 15,
                    }}
                  >
                    <div className="radio-container">
                      <input
                        id="radioCustomColor"
                        style={{ marginLeft: -6 }}
                        type="radio"
                        name="recertChange"
                        value={"with"}
                        onChange={() => {
                          setWithFlag(true)
                          setHasError(false)
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="td custom"
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 120,
                      paddingTop: 10,
                    }}
                  >
                    <span
                      style={{
                        color: "#337AB7",
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        viewProfile(
                          profileId.split("").includes("P")
                            ? profileId.substr(1, profileId.length - 1)
                            : profileId.includes("-")
                            ? profileId.split("-")?.[0]
                            : profileId
                        )
                      }}
                    >
                      {profileId}
                    </span>
                  </div>
                  <div
                    className="td custom"
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 140,
                      paddingTop: 15,
                      fontSize: 12,
                    }}
                  >
                    {recertifyProfile?.approvedDate ||
                      recertifyProfile?.createdDate}
                  </div>
                  <div
                    className="td custom"
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 140,
                      paddingTop: 15,
                      fontSize: 12,
                    }}
                  >
                    <div
                      className={`
                          ${
                            recertifyProfile?.expiryDate != "N/A" &&
                            new Date().getTime() >
                              new Date(recertifyProfile?.expiryDate).getTime()
                              ? "color-red"
                              : ""
                          }`}
                    >
                      {recertifyProfile?.expiryDate}
                    </div>
                  </div>
                  <div
                    className="td custom"
                    colSpan={2}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 230,
                      paddingTop: 15,
                      fontSize: 12,
                    }}
                  >
                    {recertifyProfile?.wasteName}
                  </div>
                </div>
              </div>
            </div>
            {hasError && (
              <div
                className="text-left ml-4"
                style={{ fontSize: "12px", color: "red" }}
              >
                Please select with or without change
              </div>
            )}
          </Loading>
          <div
            className="mt-4 mb-2 text-description text-left"
            style={{
              color: "#337AB7",
              fontWeight: "bold",
              fontSize: "13px",
            }}
          >
            <FormattedMessage id="profile.recertify.notes1" />
          </div>
          <div
            className="text-description text-left"
            style={{
              color: "rgb(129 131 133)",
              fontWeight: "bold",
              fontSize: "13px",
            }}
          >
            <FormattedMessage id="profile.recertify.notes2" />
          </div>
          <div
            style={{
              // position: "fixed",
              // bottom: "55px",
              // right: 0,
              padding: "1rem",
              textAlign: "right",
            }}
          >
            <button
              className="cancel-button"
              onClick={handleExit}
              style={{
                marginRight: "20px",
                padding: "6px 10px",
                minWidth: 140,
              }}
            >
              <FormattedMessage id="profile.recertify.cancel" />
            </button>
            <button
              className={`downloadbtn`}
              onClick={handleSubmitRequest}
              style={{ padding: "6px 10px", minWidth: 140, marginRight: -16 }}
            >
              <FormattedMessage id="profile.recertify.submitRequest" />
            </button>
          </div>
        </div>
        {/* )} */}
      </div>
    </Fragment>
  )
}

export default enhancer(RecertifyProfile)
