import enUS from "date-fns/locale/en-US"
import appConfig from "./config.json"

let {
  REACT_APP_API_PATH,
  REACT_APP_SSO_API_PATH,
  REACT_APP_LOCAL_MOCK,
  REACT_APP_GOOGLE_MAP_API_KEY,
  REACT_APP_GOOGLE_ANALYTICS_KEY,
  REACT_APP_CORPORATE_CLIENT_ID,
  REACT_APP_INDIVIDUAL_CLIENT_ID,
  REACT_APP_INDIVIDUAL_TENANT_ID,
  REACT_APP_INDIVIDUAL_AUTHORITY_DOMAIN,
  REACT_APP_GA_LOG_TEST_USER_DATA,
  REACT_APP_GEN_LOCAL_MOCK,
  REACT_APP_CLARITY_PROJECT_ID,
} = process.env

export {
  REACT_APP_API_PATH,
  REACT_APP_SSO_API_PATH,
  REACT_APP_LOCAL_MOCK,
  REACT_APP_GEN_LOCAL_MOCK,
  REACT_APP_GOOGLE_MAP_API_KEY,
  REACT_APP_GOOGLE_ANALYTICS_KEY,
  REACT_APP_CORPORATE_CLIENT_ID,
  REACT_APP_INDIVIDUAL_CLIENT_ID,
  REACT_APP_INDIVIDUAL_TENANT_ID,
  REACT_APP_INDIVIDUAL_AUTHORITY_DOMAIN,
  REACT_APP_GA_LOG_TEST_USER_DATA,
  REACT_APP_CLARITY_PROJECT_ID,
}

export const site = {
  email: "CEIUS_PortalSupport@cleanearthinc.com",
  subject: "Portal Support",
  number: "888.988.3478",
}

export const SortByService = [
  { v: "Desc", l: "Newest first" },
  { v: "Asc", l: "Oldest first" },
  { v: "bystore", l: "By store" },
]

export const MyStoreSortByService = [
  { v: "Desc", l: "Newest first" },
  { v: "Asc", l: "Oldest first" },
]

export const alertsOpts = {
  sortByOpts: [
    { l: "Recent first", v: "desc" },
    { l: "Oldest first", v: "asc" },
  ],
  filterByOpts: [
    { v: "all", l: "All" },
    { v: true, l: "Read Only" },
    { v: false, l: "Unread Only" },
  ],
}
export const SortByalert = ["Recent first", "Oldest first"]
export const FilterByalert = ["All", "Read Only", "Unread Only"]

export const myAlertsOpts = {
  sortByOpts: [
    { l: "Recent first", v: "desc" },
    { l: "Generator Type", v: "generator_creation" },
    { l: "Profile Type", v: "profile_" },
    { l: "All", v: "all" },
  ],

  filterByOpts: [
    { v: "all", l: "All" },
    { v: "profile_expiring", l: "Profile Expiring" },
    { v: "profile_creation", l: "Profile creation" },
    { v: "profile_approved", l: "Profile Approved" },
    { v: "generator_creation", l: "Generator creation" },
  ],
}
export const myAlertsOptsCommUser = {
  sortByOpts: [{ l: "Generator Type", v: "generator_creation" }],

  filterByOpts: [{ v: "generator_creation", l: "Generator creation" }],
}

export const carouselSetting = {
  autoPlay: 15000,
  infiniteLoop: true,
}

export const ServiceIssueOpts = [
  "Missed service",
  "Incomplete service",
  "Other issue",
]

export const RequestTypeOpts = [
  "New Off-cycle Pickup Request",
  "New Urgent Pickup Request",
  // "New Emergency Pickup Request (24 hours)",
]

export const ScheduleTypes = [
  "Regular",
  "On Request",
  "Urgent 3 Day",
  "Rework",
  "ER",
  "ER-Urgent",
]

export const requestServices = {
  report_issue: {
    api_type: "Report a Service Issue",
  },
  change_request: {
    api_type: "Change Request (Service Details)",
  },
  new_schedule_request: {
    api_type: "New Schedule Request",
  },
  request_supplies: {
    api_type: "Request Supplies",
  },
}

let stdFormat = "dd LLL yyyy"
const formatRelativeLocale = {
  lastWeek: stdFormat,
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: stdFormat,
  other: stdFormat,
}

export const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
}

export const BrowserList = [
  "Microsoft IE",
  "MS Edge",
  "Mozilla Firefox",
  "Google Chrome",
  "Safari",
  "Opera",
  "Safari on iOS",
  "Opera Mini",
  "Android Browser",
  "Opera Mobile",
  "Chrome for Android",
  "Firefox for Android",
  "Not Sure",
]
export const OSList = ["Windows 7", "Windows 8", "Windows 10", "MacOs", "Linux"]
export const DeviceList = ["Mobile", "Tablet", "Laptop", "Desktop"]

export const TotalSpendHideIDS = []

export const login_slider_config = {
  dots: false,
  fade: true,
  infinite: true,
  arrows: false,
  autoplay: true,
  pauseOnHover: false,
  useTransform: false,
  speed: 500,
}

export const ADMIN = "Administrator"
export const STORE_MANAGER = "Store Manager"

export const roles = {
  PORTAL_ADMIN: 1,
  CE_ADMIN: 2,
  ENTERPRISE_USER: 3,
  CUSTOMER_ADMIN: 4,
  CORPORATE_USER: 5,
  SITE_USER: 6,
  CE_GENERIC_USER: 7,
  Brand_User: 8,
  CE_COMMERCIAL_USER: 9,
}

export const BusinessSegmentId = {
  RETAIL: 1,
  FULL_CIRCLE: 2,
  "M&I": 3,
}

export const AnnualScheduleReportServiceTypesByHomeDepotCustomer =
  appConfig.AnnualScheduleReportServiceTypes
