import React, { useState } from "react"
import api from "api"
// import { useHarsco } from "shared"
import {
  CURRENT_PROFILE,
  SET_ACTIVE_STEP,
  SET_CHEMICALS,
  SET_PROGRESS_STEP,
  SET_PROGRESS_STEP_ONLY,
} from "store/reducer/actionType"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { useHistory } from "react-router-dom"
import { useHarsco } from "shared"
import { toast } from "react-toastify"

let chemicalcompositionenhancer =
  (Component) =>
  ({ ...props }) => {
    const history = useHistory()
    const { id } = useParams()
    const [showError, setShowError] = useState(false)
    const initValues = {
      Constituent: "",
      Key: "",
      Min: null,
      Max: null,
      UOM: "",
      TRIStatus: true,
      CAS: "",
    }
    const { profile } = useHarsco()
    let [chemComp, setChemComp] = useState([])
    const dispatch = useDispatch()
    const currentProfile = useSelector(
      (state) => state.profileDetails?.currentProfile
    )

    const [fields, setFields] = useState({
      Constituent: "",
      Key: "",
      Min: null,
      Max: null,
      UOM: "",
      TRIStatus: "",
      CAS: "",
    })
    const [loadChemicals, setLoadChemicals] = useState(false)
    let valSchema = {
      Constituent: Yup.string(),
      Key: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number(),
        })
        .nullable(),
      Min: Yup.string(),
      Max: Yup.string(),
      UOM: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable(),
      TRIStatus: Yup.string(),
      CAS: Yup.string(),
    }
    const getProfileData = async (reqId) => {
      const req = {
        profileRequestId: id || reqId,
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }

    const isErrorPresent = () => {
      return chemComp.some(
        (val) =>
          val?.Constituent?.trim()?.length == 0 ||
          !val?.Key ||
          val?.Key?.length > 1 ||
          ((val?.Min === null ||
            val?.Min === undefined ||
            !(String(val?.Min)?.length > 0)) &&
            val?.Key !== "<" &&
            val?.Key !== "B" &&
            val?.Key !== "T" &&
            val?.Key !== "S") ||
          (!val?.Max &&
            val?.Key !== "=" &&
            val?.Key !== ">" &&
            val?.Key !== "B" &&
            val?.Key !== "T" &&
            val?.Key !== "S") ||
          (!val?.UOM &&
            val?.Key !== "B" &&
            val?.Key !== "T" &&
            val?.Key !== "S") ||
          (val?.Key == "R" &&
            (val?.Min === null ||
              val?.Min === undefined ||
              !(String(val?.Min)?.length > 0) ||
              !val?.Max ||
              ((val?.Min === null ||
                val?.Min === undefined ||
                !(String(val?.Min)?.length > 0)) &&
                val?.Max &&
                parseFloat(val?.Min) > parseFloat(val?.Max)))) ||
          (val?.Key === "S" &&
            val?.Min &&
            val?.Max &&
            String(val?.Min)?.length > 0 &&
            val?.Max &&
            parseFloat(val?.Min) > parseFloat(val?.Max))
      )
    }

    const onSubmitAlone = async () => {
      const isError = isErrorPresent()
      if (chemComp?.length === 0 || isError) {
        return
      }
      props.setShowAlert(false)
      const payload = {
        profileRequestId: currentProfile?.profileRequestId || id,
        chemicalConstituents: chemComp?.map((val, i) => {
          return {
            ...val,
            SeqId: i,
            Key: val?.Key || "",
            UOM: val?.UOM || "",
            Min: String(val?.Min)?.length > 0 ? val?.Min : null,
            Max: val?.Max || null,
            TRIStatus:
              val?.TRIStatus === true || val?.TRIStatus === "Y" ? true : false,
            CAS: val?.CAS,
          }
        }),
      }

      try {
        let res = await api.p_saveChemicalComposition(payload)
        if (!res) return
        getProfileData(currentProfile?.profileRequestId)
        toast.success("Chemical Composition Saved Successfully")
      } catch (err) {
        toast.error("Chemical Composition Save Failed")
      }
    }

    const getPotentialTriChemicals = async (name) => {
      setLoadChemicals(true)
      try {
        let res = await api.g_getPotentialTriChemiclas(name)
        if (!res) return
        dispatch({
          type: SET_CHEMICALS,
          payload: res,
        })
      } catch (err) {
      } finally {
        setLoadChemicals(false)
      }
    }

    const resetChemicals = async () => {
      dispatch({
        type: SET_CHEMICALS,
        payload: [],
      })
    }

    const onSubmit = async () => {
      const isError = isErrorPresent()
      if (chemComp?.length === 0 || isError) {
        return
      }
      props.setShowAlert(false)
      const payload = {
        roleId: "GenericRole",
        userName: profile?.email,
        profileRequestId: currentProfile?.profileRequestId || id,
        chemicalConstituents: chemComp?.map((val, i) => {
          return {
            ...val,
            SeqId: i,
            Key: val?.Key || "",
            UOM: val?.UOM || "",
            Min: String(val?.Min)?.length > 0 ? parseFloat(val.Min) : null,
            Max: val?.Max ? parseFloat(val.Max) : null,
            TRIStatus:
              val?.TRIStatus === true || val?.TRIStatus === "Y" ? true : false,
            CAS: val?.CAS,
          }
        }),
      }

      try {
        let res = await api.p_saveChemicalComposition(payload)
        if (!res) return
        dispatch({
          type: SET_PROGRESS_STEP,
          step: 3,
          progressStep: props.showAlert ? props.seekingStep + 1 : 4,
          percentage: 100,
          numerator: 1,
          denominator: 1,
        })
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {
        // dispatch({
        //   type: "SET_CHANGE_CUSTOMER",
        //   status: false,
        //   customer_name: changeCustomer?.customer,
        //   source: false,
        // })
      }
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }

    const goToProfilePages = (page) => {
      history.push(`/profiles/${page}`)
    }

    const backAction = () => {
      dispatch({ type: SET_ACTIVE_STEP, payload: 2 })
    }

    const cancelAction = () => {
      history.push(`/profiles`)
    }

    const onSubmitProgress = async (step) => {
      const isError = isErrorPresent()
      if (chemComp?.length === 0 || isError) {
        return
      }
      const payload = {
        roleId: "GenericRole",
        userName: profile?.email,
        profileRequestId: currentProfile?.profileRequestId || id,
        chemicalConstituents: chemComp?.map((val, i) => {
          return {
            ...val,
            SeqId: i,
            Key: val?.Key || "",
            UOM: val?.UOM || "",
            Min: String(val?.Min)?.length > 0 ? val?.Min : null,
            Max: val?.Max || null,
            TRIStatus:
              val?.TRIStatus === true || val?.TRIStatus === "Y" ? true : false,
            CAS: val?.CAS,
          }
        }),
      }

      try {
        let res = await api.p_saveChemicalComposition(payload)
        if (!res) return
        dispatch({
          type: SET_PROGRESS_STEP,
          step: 3,
          progressStep: step,
          percentage: 100,
          numerator: 1,
          denominator: 1,
        })
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {}
    }

    const handleAlerts = (values, errors, isValid, step) => {
      const isError = isErrorPresent()
      if (chemComp.length === 0 || isError) {
        setShowError(true)
        props.setShowAlert(true)
      } else {
        setShowError(false)
        dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: step + 1 })
        onSubmitProgress(step)
        props.setShowAlert(false)
      }
    }

    return (
      <Component
        {...props}
        {...{
          formik,
          fields,
          backAction,
          setChemComp,
          setFields,
          goToProfilePages,
          cancelAction,
          showError,
          setShowError,
          handleAlerts,
          onSubmitAlone,
          getPotentialTriChemicals,
          resetChemicals,
          loadChemicals,
        }}
      />
    )
  }

export default chemicalcompositionenhancer
