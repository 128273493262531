import React, { Fragment, useEffect, useState, useRef } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import classnames from "classnames"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import Loading from "shared/Loading"
import { getOptions } from "routes/Utilities/util"
import Input from "routes/Admin/Components/Input"
import { useSelector } from "react-redux"
import Select from "shared/Select"
import MultiSelectComponent from "shared/Select/CustomMultiSelect"
import { useHarsco } from "shared"
import GeneratorDetails from "./GeneratorDetails"
import { roles } from "config"
import { debounce } from "lodash"

function Generator(props) {
  let {
    goToCreateProfile,
    // setDefaultGenerator,
    fetchStateInfo,
    fetchCitiesInfo,
    getGeneratorDetails,
    goToCreateGenerator,
    createProfileFromTemplate,
    mapDefaultGenerator,
    loading,
    showResults,
    setShowResults,
    generatorIdFromNotify,
    loader,
  } = props
  const { profile } = useHarsco()
  const [showOptions, setShowOptions] = useState(false)
  const [record, setRecord] = useState("")
  const [recordIndex, setRecordIndex] = useState(0)
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [address, setAddress] = useState("")
  const [storeID, setStoreID] = useState("")
  const [epaid, setEpadId] = useState("")
  const [popup, setPopup] = useState(false)
  //const [showResults, setShowResults] = useState(false)
  const [showView, setShowView] = useState(false)
  const [template, setTemplate] = useState({ l: "", v: "" })
  const [revisionNumber, setRevisionNumber] = useState()
  const [profileNumber, setProfileNumber] = useState()
  //const [firstSearch, setFirstSearch] = useState(true)

  const firstSearch = useRef(true)
  const states = useSelector((state) => state.generatorDetails.states)
  const cities = useSelector((state) => state.generatorDetails.cities)
  const stateOptions = getOptions(states, "state", "stateCode")
  const cityOptions = getOptions(cities, "shippingCity", "shippingCity")
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )
  // const defaultGenerator = useSelector(
  //   (state) => state.profileDetails.defaultGenerator
  // )
  const generatorDetails = useSelector(
    (state) => state.generatorDetails.generatorDetails
  )
  const searchGeneratorsList = useSelector(
    (state) => state.generatorDetails.searchGeneratorsList
  )
  const allProfileTemplates = useSelector(
    (state) => state.profileDetails.allProfileTemplates
  )

  const allProfileTemplatesOptions = getOptions(
    allProfileTemplates,
    "profileName",
    "profileNumber"
  )

  useEffect(() => {
    const reviNumber = allProfileTemplatesOptions.find(
      (p) => p.profileNumber === template.v
    )
    setRevisionNumber(reviNumber?.revisionNumber)
    setProfileNumber(reviNumber?.profileNumber)
  }, [allProfileTemplatesOptions, template])

  let generatorCodeOpts =
    generatorOptions?.length > 0
      ? getOptions(
          generatorOptions,
          "generatorName",
          "generatorId",
          "label",
          "value"
        )
      : [{ value: "", label: "" }]
  const [selectedGenerator, setSelectedGenerator] = useState([
    { label: "", value: "" },
  ])
  // const [loadDefault, setLoadDefault] = useState(false)

  // const generatorExists = (geneId) => {
  //   return generatorCodeOpts?.some((val) => val?.generatorId == geneId)
  // }

  useEffect(() => {
    if (
      generatorCodeOpts?.length > 0 &&
      // !loadDefault &&
      firstSearch.current === true &&
      generatorIdFromNotify
    ) {
      // setFirstSearch(false)
      firstSearch.current = false
      setSelectedGenerator([
        generatorCodeOpts?.filter(
          (val) => val.generatorId === generatorIdFromNotify
        )?.[0],
      ])
      setShowView(true)
      getGeneratorDetails(generatorIdFromNotify)
      scrollToSection()
      fetchStateInfo(generatorIdFromNotify)
      fetchCitiesInfo({ generatorId: generatorIdFromNotify, stateId: "" })
    } else if (
      generatorCodeOpts?.length > 0 &&
      // !loadDefault &&
      firstSearch.current === true
      // && defaultGenerator
    ) {
      // if (generatorExists(defaultGenerator)) {
      //   setSelectedGenerator([
      //     generatorCodeOpts?.filter(
      //       (val) => val.generatorId === defaultGenerator
      //     )?.[0],
      //   ])
      // } else {
      //   setSelectedGenerator([{ generatorId: "", generatorName: "" }])
      // }
      // fetchStateInfo(defaultGenerator)
      // fetchCitiesInfo({ generatorId: defaultGenerator, stateId: "" })
      firstSearch.current = false
    }
    if (!generatorIdFromNotify) {
      setSelectedGenerator(generatorCodeOpts)
    }
    // setLoadDefault(true)
  }, [generatorOptions?.length, generatorCodeOpts?.length])

  const sendRequest = debounce((val) => {
    if (val?.length > 0) {
      fetchStateInfo(val?.map((x) => x?.generatorId)?.join(","))
      fetchCitiesInfo({
        generatorId: val?.map((x) => x?.generatorId)?.join(","),
        stateId: "",
      })
    } else {
      fetchStateInfo("")
      fetchCitiesInfo({ generatorId: "", stateId: "" })
    }
  }, 2000)

  // const debouncedSendRequest = (val) => setTimeout(() => sendRequest(val), 500);

  const loadInitialData = () => {
    const payload = {
      // generatorIds: defaultGenerator || "",
      generatorIds: "",
      storeID: "",
      epaid: "",
      address: "",
      state: "",
      city: "",
    }
    props?.searchSubmit(payload)
  }

  // useEffect(() => {
  //   if (generatorOptions?.length > 0 && allProfileStatus?.length > 0) {
  //     loadInitialData()
  //   }
  // }, [allProfileStatus?.length, generatorOptions?.length])

  const resetFilter = () => {
    if (generatorCodeOpts?.length > 0 && generatorIdFromNotify) {
      // setFirstSearch(false)
      firstSearch.current = false
      setSelectedGenerator([
        generatorCodeOpts?.filter(
          (val) => val?.generatorId === generatorIdFromNotify
        )?.[0],
      ])
      setShowView(false)
      setShowResults(false)
      fetchStateInfo(generatorIdFromNotify)
      fetchCitiesInfo({ generatorId: generatorIdFromNotify, stateId: "" })
      setStoreID("")
      setEpadId("")
      setAddress("")
      setState(null)
      setCity(null)
    } else {
      setShowView(false)
      setShowResults(false)
      setSelectedGenerator(generatorCodeOpts)
      fetchStateInfo("")
      fetchCitiesInfo({ generatorId: "", stateId: "" })
      setStoreID("")
      setEpadId("")
      setAddress("")
      setState(null)
      setCity(null)
      loadInitialData()
    }
  }

  // const setDefaultGen = (id) => {
  //   const payload = {
  //     generatorIds: selectedGenerator
  //       ?.map((val) => val?.generatorId)
  //       ?.join(","),
  //     storeID: storeID,
  //     epaid: epaid,
  //     address: address,
  //     state: state?.stateCode || "",
  //     city: city?.shippingCity || "",
  //   }
  //   setDefaultGenerator(id, payload)
  // }

  const searchFilter = () => {
    setShowView(false)
    setShowResults(true)
    let match = selectedGenerator?.length === generatorCodeOpts?.length
    let genIdList = ""
    if (match) {
      genIdList = "All"
    } else {
      genIdList = selectedGenerator
        ?.filter((val) => val?.generatorId)
        ?.map((val) => val?.generatorId)
        ?.join(",")
    }
    const payload = {
      generatorIds: genIdList,
      storeID: storeID,
      epaid: epaid,
      address: address,
      state: state?.stateCode || "",
      city: city?.shippingCity || "",
    }
    props?.searchSubmit(payload)
  }

  const sectionRef = useRef(null)

  const scrollToSection = () => {
    sectionRef?.current?.scrollIntoView({ behavior: "smooth" })
  }

  if (loader) {
    return (
      <div className="loader-parent">
        <div className="loader"></div>
      </div>
    )
  }

  return (
    <Fragment>
      {showOptions && (
        <div className="overlay" onClick={() => setShowOptions(false)}></div>
      )}
      {popup && (
        <div
          className="overlaypopup"
          onClick={() => setShowOptions(false)}
        ></div>
      )}
      {popup && (
        <div className="popup p-2 template-popup">
          <div className="dsp-flx col-sm-12 mt-4">
            <h3 className="label p-2">
              <FormattedMessage id="profile.selecttemplate" />
            </h3>
            <button className="circle-btn" onClick={() => setPopup(false)}>
              <span className="cross-btn">x</span>
            </button>
          </div>
          <div className="label pl-4 predefine-temp">
            <FormattedMessage id="profile.predefinedtemplates" />
          </div>
          <div className="mt-4">
            <div className="form-input-block ml-2 col-sm-6 mt-2 mni-style">
              <span className="label pl-0 select">
                <FormattedMessage id="profile.select" />
              </span>
              <Select
                name="choose template"
                label=""
                className={"sort-dropdown w-90 button-clickable"}
                labelkey={"l"}
                options={allProfileTemplatesOptions}
                selected={template}
                hasSearch={true}
                onSelect={(e) => {
                  setTemplate(e)
                }}
              />
            </div>
          </div>
          <div className="pl-4 mt-4 confirm-btn">
            <button
              className={`${classnames("downloadbtn")} ${
                !template?.v ? "disabledBgBtn" : ""
              }`}
              onClick={() => {
                mapDefaultGenerator(record)
                createProfileFromTemplate(
                  "editprofile",
                  profileNumber,
                  revisionNumber,
                  record
                )
              }}
              disabled={!template?.v}
            >
              <FormattedMessage id="profile.confirm" />
            </button>
          </div>
        </div>
      )}
      <div>
        <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
          <div className="m-0 labelgenerator myprofile">
            <FormattedMessage id="generator.search" />
          </div>
          <div className="downloadsec gen-btn">
            {profile?.roleId !== roles.CE_GENERIC_USER && (
              <button
                className={classnames("downloadbtn")}
                onClick={() => goToCreateGenerator("createGenerator")}
                // onBlur={() => setShowOptions(false)}
              >
                <Image
                  className="mr-1"
                  src={"Add_Icon_MI.svg"}
                  height="16px"
                  width="16px"
                  alt=""
                />
                <FormattedMessage id="generator.create" />
              </button>
            )}
          </div>
        </div>
        {generatorCodeOpts?.length > 0 && (
          <div className="col-sm-12 col-md-12 col-lg-12 d-flex">
            <div className="form-input-block col-sm-4 gen-input">
              <span className="label">
                <FormattedMessage id="generator.generator" />
              </span>
              <MultiSelectComponent
                className="custom-generator"
                name="generatorIds"
                options={generatorCodeOpts}
                selected={selectedGenerator?.filter((val) => val?.generatorId)}
                // debounceDuration={3000}
                handleOnChange={(val) => {
                  setSelectedGenerator(val)
                  setState(null)
                  setCity(null)
                  // debouncedSendRequest(val)
                  sendRequest(val)
                }}
                disableSearch={false}
                isAllReqd={true}
              />
            </div>
            {/* <div className="form-input-block col-sm-4">
              <span className="label">
                <FormattedMessage id="generator.searchByStore" />
              </span>
              <Input
                name="storeID"
                value={storeID}
                onFieldChange={(e) => {
                  setStoreID(e.target.value)
                }}
              />
            </div> */}
            <div className="form-input-block col-sm-4">
              <span className="label">
                <FormattedMessage id="generator.enterEPAID" />
              </span>
              <Input
                name="epaid"
                charlen={50}
                value={epaid}
                onFieldChange={(e) => {
                  setEpadId(e.target.value)
                }}
              />
            </div>
            <div className="form-input-block col-sm-4">
              <span className="label">
                <FormattedMessage id="generator.address" />
              </span>
              <Input
                name="address"
                charlen={255}
                value={address}
                onFieldChange={(e) => {
                  setAddress(e.target.value)
                }}
              />
            </div>
          </div>
        )}
        <div className="col-sm-12 col-md-12 col-lg-12 d-flex my-4">
          {/* <div className="form-input-block col-sm-4">
            <span className="label">
              <FormattedMessage id="generator.address" />
            </span>
            <Input
              name="address"
              charlen={50}
              value={address}
              onFieldChange={(e) => {
                setAddress(e.target.value)
              }}
            />
          </div> */}
          <div
            className={`form-input-block col-sm-4 mb-dropdown mni-style
            ${
              stateOptions?.length > 0 ? "stateHeight" : "noOptionsStateHeight"
            } 
            search-generator`}
          >
            <span className="label">
              <FormattedMessage id="generator.state" />
            </span>
            <Select
              name="state"
              label="Select State"
              labelkey={"l"}
              className={"sort-dropdown w-90"}
              hasSearch={true}
              options={stateOptions}
              selected={state}
              // allowSelect={true}
              selectLabelType={"State"}
              onSelect={(e) => {
                setState(e)
                setCity(null)
                fetchCitiesInfo({
                  stateId: e?.v,
                  generatorId:
                    selectedGenerator && selectedGenerator?.length > 0
                      ? selectedGenerator?.map((x) => x?.generatorId)?.join(",")
                      : "",
                })
              }}
            />
          </div>
          <div
            className={`form-input-block col-sm-4 mni-style 
          ${cityOptions?.length > 0 ? "cityHeight" : "noOptionsCityHeight"}
          search-generator`}
          >
            <span className="label">
              <FormattedMessage id="generator.city" />
            </span>
            <Select
              name="city"
              label="Select City"
              hasSearch={true}
              labelkey={"l"}
              // allowSelect={true}
              selectLabelType={"City"}
              className={"sort-dropdown w-90"}
              options={cityOptions}
              selected={city}
              onSelect={(e) => {
                setCity(e)
              }}
            />
          </div>
          <div className="form-input-block col-sm-4"></div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4 pt-4">
          <div className="downloadsec">
            <button
              type="button"
              className="cancel-button"
              style={{ marginRight: 20, padding: "6px 10px", minWidth: 140 }}
              onClick={() => resetFilter()}
            >
              <FormattedMessage id={"profilelistreport.clearFilter"} />
            </button>
            <button
              className={classnames("downloadbtn gen-profile-search")}
              onClick={searchFilter}
            >
              <FormattedMessage id="profile.searchbtn" />
            </button>
          </div>
        </div>
      </div>
      {showResults && (
        <div style={{ marginTop: "4rem" }}>
          <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
            <div className="m-0 labelgenerator myprofile mt-4">
              <FormattedMessage id="generator.searchresults" />
            </div>
          </div>
          <Loading id="documents">
            <div className="col-sm-12 col-md-12 col-lg-12 mx-3 p-0 table sticky gen-search-result">
              <div
                className="row m-0 headers"
                // style={{ display: "contents" }}
              >
                <div
                  className={`tr active`}
                  style={{
                    background: showOptions ? "#ccc" : "transparent",
                    // opacity: showOptions ? 0.4 : 1,
                  }}
                >
                  <div
                    className={`th customsort inactive title-container epaid`}
                    colSpan={1}
                  >
                    <span className="title-text">
                      <FormattedMessage id="generator.epaid" />
                    </span>
                  </div>
                  <div
                    className={`th customsort inactive title-container generator-name`}
                    colSpan={1}
                  >
                    <span className="title-text">
                      <FormattedMessage id="generator.name" />
                    </span>
                  </div>
                  <div
                    className={`th customsort inactive title-container gen-address`}
                    colSpan={1}
                  >
                    <span className="title-text">
                      <FormattedMessage id="generator.genaddress" />
                    </span>
                  </div>
                  {/* <div
                    className={`th customsort inactive title-container gen-default`}
                    colSpan={2}
                  >
                    <span className="title-text">
                      <FormattedMessage id="generator.makedefault" />
                    </span>
                  </div> */}
                  <div
                    className={`th customsort inactive title-container gen-action`}
                    colSpan={1}
                  >
                    <span className="title-text">
                      <FormattedMessage id="generator.actions" />
                    </span>
                  </div>
                </div>
              </div>
              {!loading && (
                <div
                  className="row m-0 body"
                  // style={{ display: "contents" }}
                  // style={{
                  //   maxHeight: 400,
                  //   overflowY: "scroll",
                  //   minHeight: profileList?.length == 1 ? 70 : 0,
                  // }}
                >
                  {searchGeneratorsList?.map((data, i) => (
                    <div
                      className="tr active gen-result-data"
                      key={i}
                      style={{
                        display: "flex",
                        height:
                          showOptions &&
                          i != 0 &&
                          i === searchGeneratorsList?.length - 1
                            ? 75
                            : "",
                        minHeight:
                          showOptions && recordIndex - 1 === i ? "100px" : "0",
                        background: showOptions ? "#ccc" : "transparent",
                        // opacity: showOptions ? 0.4 : 1,
                      }}
                    >
                      <div
                        className="td custom"
                        colSpan={1}
                        style={{
                          display: "inline-block",
                          boxSizing: "border-box",
                          width: 160,
                          background: showOptions ? "#eeeeee" : "#ffffff",
                          pointerEvents: showOptions ? "none" : "auto",
                        }}
                      >
                        {data?.epaid}
                      </div>
                      <div
                        className="td custom"
                        colSpan={1}
                        style={{
                          display: "inline-block",
                          boxSizing: "border-box",
                          wordBreak: "break-all",
                          width: 300,
                          background: showOptions ? "#eeeeee" : "#ffffff",
                          pointerEvents: showOptions ? "none" : "auto",
                        }}
                      >
                        <span
                          className="gen-name-style"
                          onClick={() => {
                            setShowView(true)
                            getGeneratorDetails(data?.generatorId)
                            scrollToSection()
                          }}
                        >
                          {data?.generatorName}
                        </span>
                      </div>
                      <div
                        className="td custom info-tooltip"
                        colSpan={2}
                        style={{
                          display: "inline-block",
                          boxSizing: "border-box",
                          width: 300,
                          overflow: "visible",
                          wordBreak: "break-word",
                          background: showOptions ? "#eeeeee" : "#ffffff",
                          pointerEvents: showOptions ? "none" : "auto",
                        }}
                      >
                        {data?.generatorAddress}
                      </div>

                      {/* <div
                        className="td custom customCell"
                        colSpan={1}
                        style={{
                          display: "inline-block",
                          boxSizing: "border-box",
                          width: 150,
                          background: showOptions ? "#eeeeee" : "#ffffff",
                          pointerEvents: showOptions ? "none" : "auto",
                        }}
                      >
                        <input
                          className="py-4"
                          type="radio"
                          name="isDefault"
                          checked={data?.defaultGenerator}
                          onClick={(e) => {
                            e.preventDefault()
                            setShowView(false)
                            setDefaultGen(data?.generatorId)
                          }}
                          disabled={
                            profile?.roleId === roles.CE_GENERIC_USER ||
                            data?.isActive === false
                          }
                          style={{ opacity: showOptions ? 0.4 : 1 }}
                        />
                      </div> */}
                      <div
                        className="td custom customCell px-1 py-2"
                        colSpan={1}
                        style={{
                          display: "inline-block",
                          boxSizing: "border-box",
                          width: 200,
                          position: "relative",
                          minHeight:
                            showOptions && recordIndex - 1 === i
                              ? "100px"
                              : "0",
                          background: showOptions ? "#eeeeee" : "#ffffff",
                          // opacity: showOptions ? 0.4 : 1,
                          // pointerEvents: showOptions ? 'none' : 'auto',
                        }}
                      >
                        {/* {profile?.roleId !== roles.CE_GENERIC_USER && ( */}
                        <button
                          className={`${classnames("downloadbtn")} ${
                            profile?.roleId === roles.CE_GENERIC_USER
                              ? "disabledBg"
                              : data?.isActive === false
                              ? "disable disabledBg"
                              : ""
                          }`}
                          onClick={() => {
                            setRecord(data?.generatorId)
                            setRecordIndex(i + 1)
                            setShowOptions((x) => !x)
                          }}
                          style={{
                            padding: "6px 10px",
                            cursor: "pointer",
                            position:
                              showOptions && recordIndex - 1 == i
                                ? "absolute"
                                : "static",
                            top: 10,
                          }}
                          disabled={profile?.roleId === roles.CE_GENERIC_USER}
                          // onBlur={() => setShowOptions(false)}
                        >
                          <Image
                            className="mr-1"
                            src={"Add_Icon_MI.svg"}
                            height="16px"
                            width="16px"
                            alt=""
                          />
                          <FormattedMessage id="profile.create" />
                          <Image
                            className="ml-1"
                            src={"ArrowDown.svg"}
                            height="10px"
                            width="10px"
                            alt=""
                          />
                        </button>
                        {/* )} */}
                        {showOptions && data?.generatorId == record && (
                          <div className="dsp-flx position-absolute create-profile-opt">
                            <span
                              className="py-1 px-2 from-blank"
                              onClick={() => {
                                mapDefaultGenerator(data?.generatorId)
                                goToCreateProfile("createprofile")
                              }}
                            >
                              From Blank
                            </span>
                            <span
                              className="py-1 px-2 from-template"
                              onClick={() => setPopup(true)}
                            >
                              From Template
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {loading && (
              <div>
                <div className="loader create-gen-loader"></div>
              </div>
            )}
            {!loading && searchGeneratorsList?.length == 0 && (
              <div className="td inactive no-search">
                No Search Results for the filter.
              </div>
            )}
          </Loading>
        </div>
      )}
      {showView && generatorDetails?.generatorId && (
        <div style={{ marginTop: "4rem" }}>
          <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
            <div className="m-0 labelgenerator myprofile mt-4">
              <FormattedMessage id="generator.view" />
            </div>
          </div>

          <div style={{ marginLeft: 20 }}>
            <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left pb-4 pl-0">
              <div className="m-0 labelgenerator myprofile">
                <p>
                  {generatorDetails?.generatorName}{" "}
                  {searchGeneratorsList.find((val) => val.defaultGenerator)
                    ?.generatorId === generatorDetails?.generatorId
                    ? "(Default)"
                    : ""}
                </p>
              </div>
            </div>
            <GeneratorDetails
              generatorDetails={generatorDetails}
              ref={sectionRef}
            />
          </div>
        </div>
      )}
    </Fragment>
  )
}
export default enhancer(Generator)
