import React, { Fragment, useEffect } from "react"
import enhancer from "./enhancer"
import Title from "./title"
import "./style.scss"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import Headermodal from "shared/headerModal"
import OutsideClickHandler from "react-outside-click-handler"
import classnames from "classnames"
import { useLocation } from "react-router"
import { toast } from "react-toastify"
import Alerts from "routes/Alerts"
import Select from "../../routes/Admin/Components/Select"

function Header(props) {
  let {
    onLogoClick,
    onServiceReqClick,
    onChangeSearch,
    onClickhelp,
    showModal,
    handleModalToggle,
    handleoutsideModal,
    toggleSidebar,
    isTablet,
    unreadAlrtCount,
    logout,
    onCleanEarthClick,
    setShowModal,
    isStoreManager,
    updateCount,
    isFullCircle,
    onSelectValue,
    open,
    onSelectField,
    customers,
    user,
    selectedCustomer,
    handleSearchChange,
    roles,
    changeCustomer,
  } = props
  let location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    toast.dismiss()
  }, [location])

  return (
    <Fragment>
      <Title />
      <header className={`headerwrapper ${isTablet ? "isTablet " : ""}`}>
        <div className={`clean-earth-container `}>
          <div className={`row px-15  ${isTablet ? "pr-3 " : ""}`}>
            <div
              className={`${
                isTablet
                  ? "col-2 col-sm-3 col-md-3 col-lg-3 left-section pr-0"
                  : location.pathname != "/help"
                  ? "col-2 col-sm-3 col-md-3 col-lg-3 left-section"
                  : isStoreManager
                  ? "col-2 col-sm-3 col-md-3 col-lg-3 left-section"
                  : "col-2 col-sm-3 col-md-4 col-lg-4 left-section"
              }`}
            >
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 text-left logo">
                  {isTablet && location.pathname != "/help" && (
                    <span onClick={toggleSidebar}>
                      <Image className="menu" src="ic_menu.svg" alt="icon" />
                    </span>
                  )}
                  <Image
                    className="logoimg c-pointer"
                    src="Connect_Plus_Logo.svg"
                    alt=""
                    onClick={() => {
                      onLogoClick()
                    }}
                  />
                </div>
              </div>
            </div>
            {!isTablet && isStoreManager && (
              <div className="col-sm-6 col-md-6 col-lg-6 title-font">
                {location.pathname === "/help" ? (
                  <div>USER GUIDE</div>
                ) : (
                  <div></div>
                )}
              </div>
            )}
            {!isTablet && !isStoreManager && (
              <div className="col-sm-4 col-md-3 col-lg-3 title-font p-0">
                {location.pathname === "/help" ? (
                  <div>USER GUIDE</div>
                ) : !location.pathname.includes("/myschedule/serviceorder/") &&
                  !location.pathname.includes("/requestHistoryDetail/") ? (
                  <div
                    className={`drp-dwn ${
                      user?.roleId == roles.CUSTOMER_ADMIN && "prevent-click"
                    }`}
                  >
                    {(user?.roleId == roles.CE_GENERIC_USER ||
                      user?.roleId == roles.CE_ADMIN ||
                      ((user?.roleId == roles.CORPORATE_USER ||
                        user?.roleId == roles.CE_COMMERCIAL_USER) &&
                        changeCustomer?.customer?.businessSegmentId == 3) ||
                      user?.roleId == roles.CUSTOMER_ADMIN ||
                      user?.roleId == roles.PORTAL_ADMIN ||
                      user?.roleId == roles.ENTERPRISE_USER) && (
                      <div className="form-input-block">
                        <span className="label">
                          <FormattedMessage id="createuser.titleaccount" />
                        </span>
                        <Select
                          name="userType"
                          onSelectField={onSelectField}
                          open={open}
                          search={true}
                          placeholder={"Select Customer"}
                          options={
                            user?.roleId == roles.CE_COMMERCIAL_USER
                              ? customers?.filter(
                                  (val) => val.businessSegmentId == 3
                                )
                              : customers
                          }
                          disabled={
                            (user?.roleId == roles.CORPORATE_USER &&
                              user?.businessSegmentId == 3) ||
                            user?.roleId == roles.CUSTOMER_ADMIN
                          }
                          onSelectValue={onSelectValue}
                          value={selectedCustomer}
                          handleSearchChange={handleSearchChange}
                          loadingId={"customer_search"}
                          searchField={"customerSearch"}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            )}
            <div
              className={`${
                isTablet
                  ? "col-10 col-sm-9  col-md-9 col-lg-9 right-section"
                  : isStoreManager
                  ? "col-sm-4 col-md-3 col-lg-3 right-section"
                  : location.pathname !== "/help"
                  ? "col-sm-5 col-md-6 col-lg-6 right-section"
                  : "col-sm-5 col-md-5 col-lg-5 right-section"
              }`}
            >
              <div className=" serachfiel  d-none">
                <input
                  type="text"
                  placeholder="Search by keywords, topics"
                  className="searchField"
                  onChange={onChangeSearch}
                  onClick={onChangeSearch}
                />
                <Image
                  className="serchicon"
                  src="ic_search.svg"
                  height=""
                  width=""
                  alt=""
                />
              </div>
              <span className="headerText d-none">Locations</span>

              <div id="headerSection">
                {changeCustomer?.customer?.businessSegmentId != 3 && (
                  <span
                    className={
                      isStoreManager
                        ? "headerText min-padding-right"
                        : "headerText"
                    }
                    onClick={onCleanEarthClick}
                  >
                    Clean Earth News
                  </span>
                )}
                {!isStoreManager &&
                  changeCustomer?.customer?.businessSegmentId != 3 &&
                  !isFullCircle &&
                  !(user?.roleId == roles.CE_GENERIC_USER) && (
                    <button
                      className={
                        !isStoreManager
                          ? "requestService min-margin-left"
                          : "requestService"
                      }
                      onClick={onServiceReqClick}
                    >
                      <FormattedMessage id="header.service" />
                    </button>
                  )}

                {/* {changeCustomer?.customer?.businessSegmentId != 3 && (
                  <span
                    id="alerts"
                    className={`alerts ${isStoreManager ? "mr-4" : ""}`}
                  >
                    <div
                      className={`d-inline logout`}
                      aria-label="User Guide"
                      data-balloon-pos="down"
                    >
                      <Image
                        className={classnames("helpicon c-p")}
                        onClick={() => {
                          onClickhelp()
                        }}
                        src="user_guide.svg"
                        alt=""
                        // title={"User Guide"}
                      />
                    </div>
                  </span>
                )} */}
                <span
                  id="alerts"
                  className={`alerts ${isStoreManager ? "" : ""}`}
                >
                  <div
                    className={`d-inline logout`}
                    aria-label="User Guide"
                    data-balloon-pos="down"
                  >
                    <Image
                      className={classnames("helpicon c-p")}
                      onClick={() => {
                        onClickhelp()
                      }}
                      src="user_guide.svg"
                      alt=""
                      // title={"User Guide"}
                    />
                  </div>
                </span>
                {!isStoreManager &&
                user?.roleId === roles.CE_GENERIC_USER &&
                changeCustomer?.customer?.businessSegmentId === 3 ? (
                  <span className="ml-4" />
                ) : (
                  <div
                    className={`d-inline logout`}
                    aria-label="Notification"
                    data-balloon-pos="down"
                  >
                    <div
                      className="alertsec"
                      onClick={(e) => {
                        e.preventDefault()
                        handleModalToggle()
                      }}
                    >
                      <Image
                        className={classnames("alerticon", {
                          shake: unreadAlrtCount > 0,
                        })}
                        src="ic_alerts.svg"
                        alt=""
                      />
                      {unreadAlrtCount > 0 && (
                        <span
                          className={
                            user &&
                            !(
                              user?.roleId == roles.CE_GENERIC_USER ||
                              user?.roleId == roles.CE_ADMIN ||
                              ((user?.roleId == roles.CORPORATE_USER ||
                                user?.roleId == roles.CE_COMMERCIAL_USER) &&
                                changeCustomer?.customer?.businessSegmentId ==
                                  3) ||
                              user?.roleId == roles.CUSTOMER_ADMIN ||
                              user?.roleId == roles.PORTAL_ADMIN ||
                              user?.roleId == roles.ENTERPRISE_USER
                            )
                              ? "numeralert"
                              : "redalert"
                          }
                        >
                          {unreadAlrtCount}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={`d-inline logout ${
                    !isStoreManager && user?.roleId !== roles.CE_GENERIC_USER
                      ? ""
                      : ""
                  }`}
                  aria-label="Logout"
                  data-balloon-pos="down"
                >
                  <Image
                    className="logout c-p"
                    src="CE_Portal_Log_Out_Nav_Bar.svg"
                    alt="logout"
                    onClick={logout}
                    height="22px"
                    width="22px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <OutsideClickHandler onOutsideClick={() => handleoutsideModal()}>
          <Headermodal
            show={showModal}
            handleClose={handleModalToggle}
            wrapperclass={"help"}
            noClose={false}
          >
            <Alerts
              setShowModal={setShowModal}
              isHeaderAlerts={showModal}
              updateCount={(type) => updateCount(type)}
            />
          </Headermodal>
        </OutsideClickHandler>
      </header>
    </Fragment>
  )
}
export default enhancer(Header)
