import React, { Fragment, useState, useRef, useEffect } from "react"
import generatorcertificationenhancer from "./generatorcertificationenhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
// import Select from "routes/Admin/Components/Select"
import Input from "routes/Admin/Components/Input"
import Modal from "shared/Modal"
import Image from "shared/Image"
import DatePicker from "react-datepicker"
import { useSelector } from "react-redux"
import { Form, Formik } from "formik"
import { toast } from "react-toastify"
import {
  // getCompanyInitial,
  useHarsco,
} from "shared"
import { roles } from "config"
import AlertModal from "./AlertModal"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import DocumentsList from "./DocumentsList"
// import { toast } from "react-toastify"

function GeneratorCertification(props) {
  const user = useSelector((state) => state.user.account)
  const { isStoreManager } = useHarsco()
  const datePickerRef = useRef(null)
  // const { firstName, lastName, customer_name } = user
  // let nameInitail;
  let name
  if (isStoreManager) {
    // nameInitail = getCompanyInitial(customer_name)
    // name = nameInitail + " " + storeId
  } else {
    // nameInitail = lastName?.charAt(0) + firstName?.charAt(0)
    name = user?.name
  }
  let {
    formik,
    backAction,
    fileUpload,
    setFileUpload,
    cancelAction,
    showAlert,
    setShowAlert,
    handleAlerts,
    seekingStep,
    showError,
    setShowError,
    onSubmitDocs,
    success,
    failure,
    setSuccess,
    setFailure,
    isApplicationValid,
    showAppError,
    setShowAppError,
    setSubmitUpload,
    downloadDocument,
    deleteDocument,
    loader,
    genNameforView,
    handleErrorHighlight,
  } = props
  const openCalender = () => {}
  const [showModal, setShowModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [names, setNames] = useState("")
  const [date, setDate] = useState("")
  const { profile } = useHarsco()
  const [title, setTitle] = useState("")
  const [showErrors, setShowErrors] = useState(false)
  // const maxFileSize = 10 * 1024 * 1024;
  const currentProfile = useSelector(
    (state) => state.profileDetails?.currentProfile
  )
  // const getTotalFileSize = (selectedFiles) => {
  //   return selectedFiles.reduce((acc, file) => acc + file.size, 0);
  // };
  const handleUpload = (e) => {
    const file = e.target.files?.length > 0 && e.target.files[0]
    const maxFileSize = 10 * 1024 * 1024 // 10MB limit
    const allowedExtensions = /(\.pdf|\.gif|\.jpg|\.jpeg|\.png)$/i // Allowed file types
    const invalidCharacters = /[*+\/:;,<=>?\\[\]|]/ // Invalid characters in file name

    if (file) {
      const fileName = file.name

      // Check file size
      if (file.size > maxFileSize) {
        setFileUpload(null)
        const errmsg = "File size exceeds 10MB. Please select a smaller file."
        toast.error(errmsg, { autoClose: 3000 })
        return
      }

      // Check file extension
      if (!allowedExtensions.test(fileName)) {
        setFileUpload(null)
        const errmsg =
          "Invalid file type. Allowed types: .pdf, .gif, .jpg, .jpeg, .png."
        toast.error(errmsg, { autoClose: 3000 })
        return
      }

      // Check for invalid characters in file name
      if (invalidCharacters.test(fileName)) {
        setFileUpload(null)
        const errmsg =
          "Invalid characters in file name. Avoid: * + / : ; , < = > ? \\ [ ] |"
        toast.error(errmsg, { autoClose: 3000 })
        return
      }

      // If all checks pass, set the file
      setFileUpload(file)
      // toast.success("File has been uploaded successfully", { autoClose: 1000 });
    }
  }
  const { type } = useParams()
  const ref = useRef()
  const refupload = useRef()
  const refupload2 = useRef()
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )

  const getDefaultGeneratorName = (id) => {
    return (
      generatorOptions?.filter((val) => val.generatorId === id)?.[0]
        ?.generatorName || ""
    )
  }
  const availDocuments = useSelector(
    (state) => state.generatorDetails.availabledocDocuments
  )
  const esignedDocs = availDocuments?.filter((val) => val?.documentTypeId == 27)
  const presignDocs = availDocuments?.filter((val) => val?.documentTypeId == 9)

  useEffect(() => {
    if (showAlert) {
      // ref.current.click()
    }
  }, [showAlert])

  useEffect(() => {
    if (currentProfile?.profileRequestId)
      props.retrieveDocuments(currentProfile?.profileRequestId)
  }, [currentProfile?.profileRequestId])

  const handleClickOutside = (e) => {
    if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={formik?.values}
        {...formik}
      >
        {({ values, errors, touched, setFieldValue, isValid }) => (
          <Form>
            <div className="ml-4">
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 addi-info generatorstatus text-left py-4">
                <div className="ml-2 m-0 labelgenerator myprofile">
                  <FormattedMessage id="profile.generatorcertification.title" />
                </div>
                <div className="form-input-block col-sm-4">
                  <span className="label">
                    <FormattedMessage id="profile.generator" />
                  </span>
                  <Input
                    value={
                      getDefaultGeneratorName(currentProfile?.generatorId) ||
                      genNameforView
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="ml-4" style={{ textAlign: "left" }}>
                {
                  "I certify that I am an authorized representative and have confirmed the accuracy of the attached document to the best of my knowledge. I attest that any samples submitted are representative of the actual waste.  I authorize Clean Earth to modify the profile if any discrepancies are identified during the approval process."
                }
              </div>
              <div className="row m-0 mt-4">
                <div
                  className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div
                    className="col-sm-4"
                    style={{ zIndex: open ? -100 : "" }}
                  >
                    <div className="form-input-block">
                      <span className="label">
                        <FormattedMessage id="profile.name" />
                        <span style={{ color: "red" }}>{" *"}</span>
                      </span>
                      <Input
                        placeholder="Enter Name"
                        name="customerSignatureName"
                        value={values?.customerSignatureName}
                        noMaxLength={false}
                        charlen={50}
                        disabled={type === "viewprofile" ? true : false}
                        onFieldChange={(e) => {
                          setNames(e.target.value)
                          setFieldValue("customerSignatureName", e.target.value)
                        }}
                      />
                      <span
                        style={{
                          color: "red",
                          marginLeft: 15,
                          fontSize: 10,
                          paddingTop: 10,
                        }}
                      >
                        {errors?.customerSignatureName && showErrors ? (
                          <span>{errors?.customerSignatureName}</span>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-input-block">
                      <span className="label">
                        <FormattedMessage id="profile.generatorCertificationTitle" />
                        <span style={{ color: "red" }}>{" *"}</span>
                      </span>
                      <Input
                        placeholder="Enter Title"
                        name="customerSignatureTitle"
                        value={values?.customerSignatureTitle}
                        noMaxLength={false}
                        charlen={50}
                        disabled={type === "viewprofile" ? true : false}
                        onFieldChange={(e) => {
                          setTitle(e.target.value)
                          setFieldValue(
                            "customerSignatureTitle",
                            e.target.value
                          )
                        }}
                      />
                      <span
                        style={{
                          color: "red",
                          marginLeft: 15,
                          fontSize: 10,
                          paddingTop: 10,
                        }}
                      >
                        {errors?.customerSignatureTitle && showErrors ? (
                          <span>{errors?.customerSignatureTitle}</span>
                        ) : null}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 mt-4 px-4">
                <div
                  className="col-sm-12 col-md-12 col-lg-12 p-0  date-picker-style date-picker "
                  onClick={() => openCalender("begining", true)}
                >
                  <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                    <div className="form-input-block">
                      <span className="label">
                        <FormattedMessage id="profile.generatorCertification.date" />
                        <span style={{ color: "red" }}>{" *"}</span>
                      </span>
                      <div ref={datePickerRef} className="form-input-block">
                        <DatePicker
                          className="date-input-box"
                          open={open}
                          onInputClick={() => setOpen(true)}
                          onChange={(val, event) => {
                            event.stopPropagation()
                            setDate(val)
                            setFieldValue("customerSignatureDatetime", val)
                            setOpen(false)
                          }}
                          showIcon={true}
                          disabled={type === "viewprofile" ? true : false}
                          icon={
                            <Image
                              className="customDate"
                              src={"ic_calendar.svg"}
                              height="14px"
                              width="14px"
                              alt=""
                              onClick={(e) => {
                                e.stopPropagation()
                                setOpen((x) => !x)
                              }}
                            />
                          }
                          minDate={new Date()}
                          toggleCalendarOnIconClick={true}
                          dateFormat="MM/dd/yyyy"
                          name="customerSignatureDatetime"
                          value={
                            values?.customerSignatureDatetime
                              ? values?.customerSignatureDatetime
                              : ""
                          }
                          selected={values?.customerSignatureDatetime || null}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-10 col-md-2 col-lg-2 text-center p-0 ">
                    <Image
                      className=""
                      src={"ic_calendar.svg"}
                      height="14px"
                      width="14px"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
              <div
                style={{
                  color: "red",
                  fontSize: 10,
                  paddingTop: 10,
                  paddingLeft: 30,
                  textAlign: "left",
                }}
              >
                {errors?.customerSignatureDatetime &&
                touched?.customerSignatureDatetime ? (
                  <div>{errors?.customerSignatureDatetime}</div>
                ) : null}
              </div>
              {/* {submission} */}
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 addi-info generatorstatus text-left py-4">
                <div className="ml-2 m-0 labelgenerator myprofile">
                  <FormattedMessage id="profile.generatorCertification.submit" />
                </div>
              </div>
              {profile?.roleId === roles.CORPORATE_USER && (
                <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 px-3 ">
                  <div className="form-input-block">
                    <input
                      className="py-4"
                      style={{ marginTop: 5 }}
                      id="radioCustomColor"
                      disabled={type === "viewprofile" ? true : false}
                      type="radio"
                      name={"electronicSybmissionFlag"}
                      checked={values?.electronicSybmissionFlag}
                      onClick={(e) => {
                        setFieldValue(
                          "electronicSybmissionFlag",
                          e.target.checked
                        )
                        setFieldValue("presignedstatus", false)
                        setSubmitUpload(null)
                        // let arr = []
                        // setSelectedFiles([...arr])
                        setFileUpload(null)
                      }}
                    />
                    <div
                      className="px-4 "
                      style={{ marginLeft: "24PX", width: "max-content" }}
                    >
                      <FormattedMessage
                        id={"profile.generatorCertification.esubmission"}
                      />
                    </div>
                  </div>
                </div>
              )}
              {values?.electronicSybmissionFlag && (
                <>
                  <div>
                    <div className="px-2">
                      <div className="px-2 tex-description text-justify">
                        {
                          "This option will track your login credentials as the electronic signature and eliminates the need for faxing or e-mailing a signed profile"
                        }
                      </div>
                      <div className="px-2 tex-description text-justify">
                        <b>{"Note : "}</b>
                        <span>
                          {
                            " This options are available only to the Clean Earth online services users."
                          }
                        </span>
                      </div>
                      <div className="px-2 tex-description text-justify">
                        <b>
                          {
                            "Profile must be submitted to initiate the approval process. "
                          }
                        </b>
                      </div>
                      <div className="px-2 tex-description text-justify">
                        {
                          "You are about to submit a profile for approval. A signed copy of each profile is required to be on file prior to approval. The requirement can be met by agreeing to Clean Earth electronic submittal policy or by submitting Printing and e - mailing or faxing a signed profile."
                        }
                      </div>
                    </div>
                    <div className="px-2 mt-3 mb-2 tex-description text-justify disclaimer">
                      <p>
                        <strong>Disclaimer:</strong>&nbsp;If you are signing on
                        behalf of the generator, please upload a letter of
                        consent.
                      </p>
                    </div>
                    <div className="row m-0" style={{ alignItems: "center" }}>
                      <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 px-3">
                        <div className="d-flex m-2 ">
                          <label className="check-container">
                            <input
                              type="checkbox"
                              name={"letterOfConcentFlag"}
                              checked={values?.letterOfConcentFlag}
                              onClick={(e) => {
                                setFieldValue(
                                  "letterOfConcentFlag",
                                  e.target.checked
                                )
                              }}
                            />
                            <span className={"checkmark"} />
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-5  py-2">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="profile.generatorCertification.loc" />
                            <span style={{ color: "red" }}>{" *"}</span>
                          </span>
                          <Input
                            placeholder="Enter Name"
                            name="letterOfConcent"
                            disabled={type === "viewprofile" ? true : false}
                            value={values?.letterOfConcent}
                            onFieldChange={(e) => {
                              setFieldValue("letterOfConcent", e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      {/* {values?.letterOfConcentFlag &&
                      values?.letterOfConcent?.trim()?.length > 0 && ( */}
                      <>
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="profile.generatorCertification.chooseFile" />
                            <span style={{ color: "red" }}>{" *"}</span>
                          </span>
                          <input
                            type="file"
                            ref={refupload}
                            // multiple
                            accept=".xls, .xlsx, image/png, image/jpeg, .pdf,.doc,.docx,application/msword,image/gif,.tif"
                            className="cancel-button"
                            disabled={
                              type == "viewprofile" ||
                              !values?.letterOfConcentFlag ||
                              values?.letterOfConcent?.trim()?.length === 0
                            }
                            placeholder="profile.generatorCertification.chooseFile"
                            onChange={handleUpload}
                            // value={fileUpload}
                          />
                        </div>
                        {type !== "viewprofile" && (
                          <div className="row m-0 float-right ml-4 ">
                            <button
                              className={`green-button ${
                                !fileUpload ||
                                values?.letterOfConcent?.trim()?.length === 0
                                  ? "disabledBg"
                                  : ""
                              }`}
                              type="button"
                              onClick={() => {
                                onSubmitDocs("Signature Authorization")
                                refupload.current.value = ""
                                setFileUpload(null)
                              }}
                              // ref={ref}
                              disabled={
                                !fileUpload ||
                                type == "viewprofile" ||
                                !values?.letterOfConcentFlag ||
                                values?.letterOfConcent?.trim()?.length === 0
                              }
                            >
                              <FormattedMessage id="profile.generatorCertification.upload" />
                            </button>
                          </div>
                        )}
                      </>
                      {/* )} */}
                    </div>
                    {/* {values?.electronicSybmissionFlag === true &&
                    values?.letterOfConcent?.trim()?.length === 0 ? (
                      <div
                        style={{
                          color: "red",
                          fontSize: 10,
                          paddingTop: 10,
                          paddingLeft: 30,
                          textAlign: "left",
                        }}
                      >
                        {"Letter of consent is required"}
                      </div>
                    ) : null} */}
                    {/* {selectedFiles?.length > 0 && (
                    <div style={{position: "relative", top: 2, left: 50}}>
                          <button className="cancel-button">
                            {selectedFiles.map((file) => (
                              <div 
                              key={file.name}
                              >
                                {file.name}
                                <button className="cancel-button" style={{border: "1px"}} onClick={() => handleDeleteFile(file)}>✕</button>
                              </div>
                            ))}
                          </button>
                    </div> 
                  )} */}
                    <div className="row m-0" style={{ alignItems: "center" }}>
                      <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 px-3"></div>
                      <div className="col-sm-4  py-2"></div>
                      <>
                        <div
                          className="row m-0 float-right m-r-2r d-flex"
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className="px-2 text-justify"
                            style={{ fontSize: "small" }}
                          >
                            {
                              "Valid File Extensions are .pdf, .gif, .jpg, .jpeg, .png"
                            }
                          </div>
                          <div
                            className="px-2 text-justify fw-b"
                            style={{ fontSize: "small" }}
                          >
                            {
                              "The following special characters are not allowed in the filename: * + / : ; , < = > ?  [ ] |"
                            }
                          </div>
                          <div
                            className="px-2 text-justify fw-b"
                            style={{ fontSize: "small" }}
                          >
                            {"Choose your file to upload (max size 10 MB)"}
                          </div>
                        </div>
                      </>
                      {/* )} */}
                    </div>
                  </div>
                  <DocumentsList
                    filterById={27}
                    downloadDocument={downloadDocument}
                    deleteDocument={deleteDocument}
                    loader={loader}
                  />
                </>
              )}
              <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 px-3 ">
                <div className="form-input-block">
                  <input
                    id="radioCustomColor"
                    type="radio"
                    style={{ marginTop: 5 }}
                    name={"presignedstatus"}
                    disabled={type === "viewprofile" ? true : false}
                    checked={values?.presignedstatus}
                    onClick={(e) => {
                      setFieldValue("presignedstatus", e.target.checked)
                      setFieldValue("electronicSybmissionFlag", false)
                      setSubmitUpload(null)
                      // let arr = []
                      // setSelectedFiles([...arr])
                      setFileUpload(null)
                    }}
                  />
                </div>
                <div
                  className="px-4"
                  style={{ marginLeft: "24PX", width: "max-content" }}
                >
                  <FormattedMessage
                    id={"profile.generatorCertification.signedDocument"}
                  />
                </div>
              </div>
              {values?.presignedstatus && (
                <>
                  <div className="px-2 py-2">
                    <div className="px-2 tex-description text-justify">
                      {
                        "To attach documents to this profile ( Material Safety Data Sheets, Analytical testing, Certifications, etc.,)"
                      }
                    </div>
                    <div
                      className="dsp-flx m-2"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <div className="form-input-block">
                        <span className="label">
                          <FormattedMessage id="profile.generatorCertification.chooseFile" />
                          <span style={{ color: "red" }}>{" *"}</span>
                        </span>
                        <input
                          type="file"
                          ref={refupload2}
                          disabled={type === "viewprofile" ? true : false}
                          accept=".xls, .xlsx, image/png, image/jpeg, .pdf,.doc,.docx,application/msword,image/gif,.tif"
                          className="cancel-button"
                          placeholder="profile.generatorCertification.chooseFile"
                          onChange={handleUpload}
                        />
                      </div>
                      {type !== "viewprofile" && (
                        <div className="row ml-4 ">
                          <button
                            className={`green-button ${
                              !fileUpload ? "disabledBg" : ""
                            }`}
                            type="button"
                            onClick={() => {
                              onSubmitDocs("Signed Profile")
                              refupload2.current.value = ""
                              setFileUpload(null)
                            }}
                            disabled={!fileUpload || type == "viewprofile"}
                          >
                            <FormattedMessage id="profile.generatorCertification.upload" />
                          </button>
                        </div>
                      )}
                    </div>
                    {/* {selectedFiles?.length > 0 && (
                    <div style={{position: "relative", top: 2, right: 340, bottom: 1}}>
                          <button className="cancel-button">
                            {selectedFiles.map((file) => (
                              <div 
                              key={file.name}
                              >
                                {file.name}
                                <button className="cancel-button" style={{border: "1px"}} onClick={() => handleDeleteFile(file)}>✕</button>
                              </div>
                            ))}
                          </button>
                    </div> 
                  )} */}
                    <div
                      className="px-2 text-justify"
                      style={{ fontSize: "small" }}
                    >
                      {
                        "Valid File Extensions are .pdf, .gif, .jpg, .jpeg, .png"
                      }
                    </div>
                    <div
                      className="px-2 text-justify fw-b"
                      style={{ fontSize: "small" }}
                    >
                      {
                        "The following special characters are not allowed in the filename: * + / : ; , < = > ?  [ ] |"
                      }
                    </div>
                    <div
                      className="px-2 text-justify fw-b"
                      style={{ fontSize: "small" }}
                    >
                      {"Choose your file to upload (max size 10 MB)"}
                    </div>
                  </div>
                  <DocumentsList
                    filterById={9}
                    downloadDocument={downloadDocument}
                    deleteDocument={deleteDocument}
                    loader={loader}
                  />
                </>
              )}
              {/* Generator Certification Submission Successfull popup*/}
              <div className="mniAlertDiv">
                <Modal show={showModal} style={{ padding: 20 }}>
                  <div style={{ padding: 20, fontSize: 12 }}>
                    <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-4">
                      <div className="m-0 labelgenerator myprofile">
                        <FormattedMessage id="profile.generatorcertification.popuptitle" />
                      </div>
                    </div>
                    <div className="ml-3" style={{ textAlign: "left" }}>
                      {
                        "I certify that I am an authorized representative and have confirmed the accuracy of the attached document to the best of my knowledge. I attest that any samples submitted are representative of the actual waste.  I authorize Clean Earth to modify the profile if any discrepancies are identified during the approval process."
                      }
                    </div>
                    <div
                      className="dsp-flx col-sm-12 mt-4"
                      style={{ fontWeight: 700, color: "#0092BC" }}
                    >
                      <div className="col-sm-4">
                        <FormattedMessage id="profile.generatorcertification.auth" />
                      </div>
                      <div className="col-sm-3">
                        <FormattedMessage id="profile.generatorcertification.authname" />
                      </div>
                      <div className="col-sm-3">
                        <FormattedMessage id="profile.generatorCertificationTitle" />
                      </div>
                      <div className="col-sm-2">
                        <FormattedMessage id="profile.generatorCertification.date" />
                      </div>
                    </div>
                    <div className="dsp-flx col-sm-12 ">
                      <div className="col-sm-4">{name}</div>
                      <div className="col-sm-3">
                        {formik.initialValues?.customerSignatureName || names}
                      </div>
                      <div className="col-sm-3">
                        {formik.initialValues?.customerSignatureTitle || title}
                      </div>
                      <div className="col-sm-2">
                        {new Date(
                          formik.initialValues?.customerSignatureDatetime ||
                            date
                        ).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="ml-3 mt-4" style={{ textAlign: "left" }}>
                      The waste profile has been submitted using Clean Earth{" "}
                      {formik.initialValues?.electronicSybmissionFlag
                        ? "electronic signature"
                        : "presigned"}{" "}
                      system.
                    </div>
                    <div
                      className="dsp-flx col-sm-12 mt-4"
                      style={{ flexDirection: "row-reverse" }}
                    >
                      <button
                        className="green-button"
                        type="button"
                        onClick={() => cancelAction()}
                      >
                        <FormattedMessage id="profile.generatorcertification.ok" />
                      </button>
                    </div>
                  </div>
                </Modal>
                {/* Generator Certification Submission popup*/}
                <Modal show={success || failure} style={{ padding: 20 }}>
                  <div style={{ padding: 20, fontSize: 12 }}>
                    <div
                      className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left"
                      style={{ flexDirection: "column" }}
                    >
                      <div
                        className="m-0 labelgenerator myprofile mb-2"
                        style={{ textAlign: "left" }}
                      >
                        {success ? "SUCCESS" : "FAILURE"}
                      </div>
                      <div className="mt-2 myprofile">
                        {success
                          ? `The profile P${currentProfile?.profileRequestId} has been generated successfully`
                          : "The profile generation failed"}
                      </div>
                      <div className="col-sm-10 col-md-12 col-lg-12 text-left mt-4">
                        <div
                          className="d-flex"
                          style={{ justifyContent: "center" }}
                        >
                          <button
                            type="button"
                            className="cancel-button"
                            style={{ background: "#0092BC", color: "#ffffff" }}
                            onClick={() => {
                              setSuccess(false)
                              setFailure(false)
                              setShowModal(false)
                              cancelAction()
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
              {/* {Back, Cancel, Save and Continue} */}
              {type !== "viewprofile" && (
                <div
                  className="row new-row-block"
                  style={{ marginTop: "2rem", marginBottom: "0" }}
                >
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2 ml-4">
                    <div className="row m-0 float-left">
                      <button
                        type="button"
                        className={`cancel-button`}
                        onClick={() => backAction()}
                      >
                        Back
                      </button>
                    </div>
                    {(profile?.roleId === roles.CORPORATE_USER ||
                      profile?.roleId === roles.PORTAL_ADMIN) && (
                      <div className="row m-0 float-right m-r-2r">
                        <button
                          type="button"
                          // ref={ref}
                          className={`green-button
                        ${
                          // !isApplicationValid ||
                          (!values?.electronicSybmissionFlag &&
                            !values?.presignedstatus) ||
                          (values?.electronicSybmissionFlag &&
                            (esignedDocs?.length === 0 ||
                              values?.letterOfConcent?.trim()?.length === 0)) ||
                          (values?.presignedstatus && presignDocs?.length === 0)
                            ? "disabledBg"
                            : ""
                        }
                      `}
                          disabled={
                            // !isApplicationValid ||
                            (!values?.electronicSybmissionFlag &&
                              !values?.presignedstatus) ||
                            (values?.electronicSybmissionFlag &&
                              (esignedDocs?.length === 0 ||
                                values?.letterOfConcent?.trim()?.length ===
                                  0)) ||
                            (values?.presignedstatus &&
                              presignDocs?.length === 0)
                          }
                          onClick={() => {
                            if (
                              Boolean(isApplicationValid) &&
                              values?.customerSignatureName &&
                              values.customerSignatureTitle
                            ) {
                              handleErrorHighlight(false)
                              formik.onSubmit(values, true)
                            } else {
                              handleErrorHighlight(true)
                              setShowAlert(true)
                              setShowAppError(true)
                              setShowErrors(true)
                            }
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                    {(profile?.roleId === roles.CORPORATE_USER ||
                      profile?.roleId === roles.PORTAL_ADMIN ||
                      profile?.roleId === roles.CE_COMMERCIAL_USER) && (
                      <div className="row m-0 float-right m-r-2r">
                        <button
                          type="button"
                          ref={ref}
                          className={`green-button
                        ${
                          !values?.customerSignatureDatetime ||
                          !values?.customerSignatureName?.trim() ||
                          !values?.customerSignatureTitle?.trim() ||
                          (!values?.electronicSybmissionFlag &&
                            !values?.presignedstatus) ||
                          (values?.electronicSybmissionFlag &&
                            (esignedDocs?.length === 0 ||
                              values?.letterOfConcent?.trim()?.length === 0)) ||
                          (values?.presignedstatus && presignDocs?.length === 0)
                            ? "disabledBg"
                            : ""
                        }
                      `}
                          disabled={
                            !values?.customerSignatureDatetime ||
                            !values?.customerSignatureName?.trim() ||
                            !values?.customerSignatureTitle?.trim() ||
                            (!values?.electronicSybmissionFlag &&
                              !values?.presignedstatus) ||
                            (values?.electronicSybmissionFlag &&
                              (esignedDocs?.length === 0 ||
                                values?.letterOfConcent?.trim()?.length ===
                                  0)) ||
                            (values?.presignedstatus &&
                              presignDocs?.length === 0)
                          }
                          onClick={() => {
                            formik.onSubmit(values, false)
                          }}
                        >
                          Save
                        </button>
                      </div>
                    )}
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => cancelAction()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <AlertModal
                    showError={showError}
                    setShowError={setShowError}
                    showAppError={showAppError}
                    setShowAppError={setShowAppError}
                    seekingStep={seekingStep}
                    submitAction={() =>
                      handleAlerts(values, errors, isValid, seekingStep)
                    }
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                  {/* Please fill all required fields in all section to proceed submission */}
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default generatorcertificationenhancer(GeneratorCertification)
