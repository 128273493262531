import {
  REACT_APP_API_PATH as b2c,
  REACT_APP_SSO_API_PATH as sso,
  // REACT_APP_LOCAL_MOCK as localmock,
  // REACT_APP_GEN_LOCAL_MOCK as genlocalmock,
} from "config"
import { dateForApi as dfA } from "shared"

let host = (val) => {
  // if (val === "gen") {
  //   return genlocalmock
  // }
  // if (val) {
  //   return localmock
  // }
  if (localStorage.userType == "b2b") return sso
  return b2c
}

let urls = {
  g_documentTabOverView: ({ dates: [from, to] }) =>
    `${host()}/api/dashboard/document/overview?from=${dfA(from)}&to=${dfA(to)}`,
  g_wasteProcess: ({ dates: [from, to], type }) =>
    `${host()}/api/dashboard/wasteprocess/overview?&hazWaste=true&wasteStreamRank=true&viewType=${type}&from=${dfA(
      from
    )}&to=${dfA(to)}`,
  g_serviceDates: ({ dates: [from, to] }) =>
    `${host()}/api/dashboard/order/has-service?from=${dfA(from)}&to=${dfA(to)}`,
  p_schedulesSummary: ({ dates: [from, to], workOrderType = "" }) =>
    `${host()}/api/order/schedules/summary?from=${dfA(from)}&to=${dfA(
      to
    )}&workOrderType=${workOrderType}`,
  p_schedulesServices: ({
    dates: [from, to],
    index = 1,
    size,
    sort = "Asc",
    workOrderType = "",
  }) =>
    `${host()}/api/orders?to=${dfA(
      to
    )}&PageIndex=${index}&PageSize=${size}&sort=${sort}&from=${dfA(
      from
    )}&workOrderType=${workOrderType}`,
  p_genStatus: ({ dates: [from, to], index = 1, size = 20 }) =>
    `${host()}/api/reports/genStatus?from=${dfA(from)}&to=${dfA(
      to
    )}&PageIndex=${index}&PageSize=${size}`,
  p_genStatusTotals: ({ dates: [from, to] }) =>
    `${host()}/api/reports/genStatus/totals?from=${dfA(from)}&to=${dfA(to)}`,
  g_alertsUnread: ({ dates: [from, to] }) =>
    `${host()}/api/alert?from=${dfA(from)}&to=${dfA(to)}&isRead=false`,
  g_alertsAll: ({ dates: [from, to], read = false }) =>
    `${host()}/api/alert?from=${dfA(from)}&to=${dfA(to)}`,
  g_downloadAlertList: ({ userId }) =>
    `${host()}/api/report/waste-shipment-reportList?UserId=${userId}`,
  g_getallstore: () => `${host()}/api/location`,
  g_scheduleTypes: () => `${host()}/api/filters/schedule-types`,
  g_serviceTypes: ({ division = "" }) =>
    `${host()}/api/filters/service-types?brand=${division}`,
  g_getLatLong: ({ address }) =>
    `${host()}/api/gmap/geocode?address=${address}`,
  p_requestService: () => `${host()}/api/servicerequest`,
  p_markAlertRead: () => `${host()}/api/alert/update`,
  p_documents: ({ dates: [from, to], index = 1, size = 20 }) =>
    `${host()}/api/Documents?from=${dfA(from)}&to=${dfA(
      to
    )}&PageIndex=${index}&PageSize=${size}`,
  f_postDocuments: (req) => `${host()}/api/MniProfile/SaveFileInDocumentTab`,
  pb_downloadDocuments: (req) =>
    `${host()}/api/MniProfile/DownloadFileInDocumentTab`,
  pb_downloadProfileDocuments: (req) =>
    `${host()}/api/MniProfile/DownloadWasteProfileReport`,
  g_serviceDetails: ({ workOrderAzId }) =>
    `${host()}/api/serviceDetails/${workOrderAzId}`,
  g_pendingService: ({ generatorAZId }) =>
    `${host()}/api/servicedetails/pending-services/${generatorAZId}`,
  g_serviceHistory: ({ generatorAZId, index = 1, date, size = 5 }) =>
    `${host()}/api/order/servicehistory/${generatorAZId}?to=${dfA(
      date
    )}&PageIndex=${index}&PageSize=${size}`,
  g_supportingDoc: ({ workOrderAzId }) =>
    `${host()}/api/documents/ServiceDetails/supporting-documents/${workOrderAzId}?`,
  g_shipmentDetails: ({ workOrderAzId }) =>
    `${host()}/api/servicedetails/manifest-lines/${workOrderAzId}`,
  p_wasteShipment: ({ dates: [from, to], index = 1, size = 20 }) =>
    `${host()}/api/report/waste-shipment?from=${dfA(from)}&to=${dfA(
      to
    )}&PageIndex=${index}&PageSize=${size}`,
  p_exceptionReport: ({ dates: [from, to], index = 1, size = 20 }) =>
    `${host()}/api/reports/exception-detail?from=${dfA(from)}&to=${dfA(
      to
    )}&PageIndex=${index}&PageSize=${size}`,
  p_exceptionReportSummary: ({ dates: [from, to], index = 1, size = 20 }) =>
    `${host()}/api/reports/exception-summary?from=${dfA(from)}&to=${dfA(
      to
    )}&PageIndex=${index}&PageSize=${size}`,
  g_exceptionDetails: ({ workOrderAzId }) =>
    `${host()}/api/exception/exception-records/${workOrderAzId}`,
  g_exceptionGrossary: () => `${host()}/api/exception/exception-glossary`,
  p_getProfileData: ({ index = 1, size = 20 }) =>
    `${host()}/api/profile?PageIndex=${index}&PageSize=${size}`,
  g_getAllVendors: () => `${host()}/api/MniProfile/GetAllVendors?mode=1`,
  p_getAllProcessCodes: (req) => `${host()}/api/MniProfile/GetAllProcessCodes`,
  g_getAllFormCodes: () => `${host()}/api/MniProfile/GetFormCode`,
  g_getAllOdors: () => `${host()}/api/MniProfile/GetAllOdors`,
  g_getAllOriginCodes: () =>
    `${host()}/api/MniProfile/GetAllOriginCodes?mode=OriginCode`,
  g_getAllGenerators: () =>
    `${host()}/api/MniGenerator/GetGeneratorListByCustomerId`,
  p_searchGeneratorList: (req) => `${host()}/api/MniGenerator/SearchGenerator`,
  p_getCityState: (req) => `${host()}/api/MniGenerator/USPSCityState`,
  p_getAllCitiesInfo: (req) => `${host()}/api/MniGenerator/GetShippingCity`,
  g_getGeneratorDetails: (id) =>
    `${host()}/api/MniGenerator/GetGeneratorById?generatorId=${id}`,
  g_getAllErServiceNames: () =>
    `${host()}/api/MniGenerator/GetAllErServiceNames`,
  g_getPhoneNumberByProviderName: (name) =>
    `${host()}/api/MniGenerator/GetPhoneNumberByProviderName?providerName=${name}`,
  g_getCountryList: () => `${host()}/api/MniGenerator/GetCountryList`,
  g_getStateList: () => `${host()}/api/MniGenerator/GetStateList`,
  g_getGeoMapLocation: (address) =>
    `${host()}/api/MniGenerator/GetGeoMapLocation?address=${address}`,
  p_USPSAddressValidation: () =>
    `${host()}/api/MniGenerator/USPSAddressValidation`,
  f_saveNewGenerator: (req) => `${host()}/api/MniGenerator/SaveNewGenerator`,
  f_checkDuplicateGenerator: (req) =>
    `${host()}/api/MniGenerator/CheckForDuplicateGenerator`,
  g_getGeneratorIdStatus: () =>
    `${host()}/api/MniGenerator/GetGeneratorIdStatus`,
  g_getNaicsCode: () => `${host()}/api/MniGenerator/GetNAICSCode`,
  g_getKnowyourCSR: () => `${host()}/api/MniProfile/KnowYourCSRByCustomer`,
  p_getAllStateInfo: (req) =>
    `${host()}/api/MniGenerator/GetGeneratorStateByCustomerId`,
  p_setDefaultGenerator: (req) =>
    `${host()}/api/MniGenerator/SetDefaultGenerator`,
  p_getProfileOverviewList: (req) =>
    `${host()}/api/MniProfile/GetProfileOverviewList`,
  p_getProfileOverviewStatusCount: (req) =>
    `${host()}/api/MniProfile/GetProfileOverviewStatusCount`,
  p_getModuleOverviewList: (req) =>
    `${host()}/api/MniProfile/GetOriginatedRequestByModules`,
  // g_getDefaultGenerator: () => `${host()}/api/MniGenerator/GetdefaultGenerator`,
  g_getAllSourceCodes: () => `${host()}/api/MniProfile/GetAllSourceCode`,
  p_getAllEPAWasteCodes: () =>
    `${host()}/api/MniProfile/GetAllEpaWasteCodes?ModeId=0&ProfileId=1&FilterId=0`,
  g_getAllDocumentTypes: () =>
    `${host()}/api/MniProfile/GetAllDocumentTypes?DocumentTypeEntity=Profile`,
  p_getDocuments: (req) =>
    `${host()}/api/MniProfile/GetDocumentDetailsInDocumentTab`,
  p_deleteDocument: ({ profileRequestDocumentSeqid, profileRequestId }) =>
    `${host()}/api/MniProfile/DeleteAttachment?profileRequestDocumentSeqid=${profileRequestDocumentSeqid}&profileRequestId=${profileRequestId}`,
  g_getAllFacilityProcessCodes: (id) =>
    `${host()}/api/MniProfile/GetAllFacilityProcessCodeLimited?VendorId=${id}`,
  g_getAllFrequencyTypes: () => `${host()}/api/MniProfile/GetAllFrequencyTypes`,
  g_getAllProfileStatus: () => `${host()}/api/MniProfile/GetAllProfileStatus`,
  g_getAllLDRClass: () => `${host()}/api/MniProfile/GetAllLDRClass`,
  g_getAvailableEpaWasteCodes: (id) =>
    `${host()}/api/MniProfile/GetAvailableEpaWasteCodes?profileRequestId=${id}`,
  g_getSelectedEpaWasteCodes: (id) =>
    `${host()}/api/MniProfile/GetSelectedEpaWasteCodes?profileRequestId=${id}`,
  g_getAvailableEpaUhcs: (id) =>
    `${host()}/api/MniProfile/GetAvailableEpaUhcs?profileRequestId=${id}`,
  g_getSelectedEpaUhcs: (id) =>
    `${host()}/api/MniProfile/GetSelectedEpaUhcs?profileRequestId=${id}`,
  g_getAvailableStateCode: (id) =>
    `${host()}/api/MniProfile/GetAvailableStateCode?profileRequestId=${id}`,
  p_getNAICSCodeDetals: (req) =>
    `${host()}/api/MniGenerator/GetNaicsCodeDetails`,
  g_getSelectedStateCodes: (id) =>
    `${host()}/api/MniProfile/GetSelectedStateCode?profileRequestId=${id}`,
  g_getAvailableLdrRcraCode: (id) =>
    `${host()}/api/MniProfile/GetAvailableLdrRcraCode?profileRequestId=${id}`,
  g_getSelectedLdrRcraCode: (id) =>
    `${host()}/api/MniProfile/GetSelectedLdrRcraCode?profileRequestId=${id}`,
  g_getAvailableLdrUhcs: (id) =>
    `${host()}/api/MniProfile/GetAvailableLdrUhcs?profileRequestId=${id}`,
  g_getSelectedLdrUhcs: (id) =>
    `${host()}/api/MniProfile/GetSelectedLdrUhcs?profileRequestId=${id}`,
  g_ServiceRequestSupplies: () => `${host()}/api/ServiceRequest/supplies`,
  pa_user: () => `${host()}/sapi/user`,
  g_user: () => `${host()}/sapi/user`,
  p_supportForm: () => `${host()}/api/support`,
  pa_acceptTerms: () => `${host()}/api/user/acceptedterms`,
  g_docTypes: () => `${host()}/api/filters/document-types`,
  g_lastServiceDetails: () => `${host()}/api/last-service-details`,
  p_serviceRequestHistory: ({ dates: [from, to] }) =>
    `${host()}/api/serviceRequest/request-history?from=${dfA(from)}&to=${dfA(
      to
    )}`,
  g_historicalLDRs: ({ storeId }) =>
    `${host()}/api/Documents/annual-ldr?storeNumber=${encodeURIComponent(
      storeId
    )}`,
  p_wasteShipmentDownload: ({
    dates: [from, to],
    index = 1,
    size = 20,
    userId,
    userName,
    connectionId,
  }) =>
    `${host()}/api/report/waste-shipment-download?from=${dfA(from)}&to=${dfA(
      to
    )}
      &PageIndex=${index}&PageSize=${size}&UserId=${userId}&UserName=${userName}&ConnectionId=${connectionId}`,
  p_updateShipmentDownload: () => `${host()}/api/report/waste-shipment-update`,
  p_saveWasteDescription: () => `${host()}/api/MniProfile/SaveWasteDescription`,
  p_updateWasteDescription: () =>
    `${host()}/api/MniProfile/UpdateWasteDescription`,
  p_saveAdditionalInfo: () =>
    `${host()}/api/MniProfile/SaveAdditionalInformation`,
  p_saveWasteCharacteristics: () =>
    `${host()}/api/MniProfile/SaveWasteCharacteristics`,
  p_saveChemicalComposition: () =>
    `${host()}/api/MniProfile/SaveChemicalComposition`,
  g_getPotentialTriChemiclas: (name) =>
    `${host()}/api/MniProfile/GetPotentialTriChemicals?chemicalName=${name}`,
  g_getAllRegConstNameByCategory: () =>
    `${host()}/api/MniProfile/GetAllRegConstNameByCategory`,
  p_saveRegulatoryConstituents: () =>
    `${host()}/api/MniProfile/SaveRegulatedConstituents`,
  p_saveStateRegulatoryInfo: () =>
    `${host()}/api/MniProfile/SaveStateRegulatoryInformation`,
  p_saveShipmentInformation: () =>
    `${host()}/api/MniProfile/SaveShipmentInformation`,
  p_saveGeneratorCertification: () =>
    `${host()}/api/MniProfile/SaveGeneratorCertification`,
  p_saveStateCode: (req) => `${host()}/api/MniProfile/SaveStateCode`,
  p_saveEpaWasteCode: (req) => `${host()}/api/MniProfile/SaveEpaWasteCode`,
  p_saveEpaUhc: (req) => `${host()}/api/MniProfile/SaveEpaUhc`,
  p_saveLdrRcraCode: (req) => `${host()}/api/MniProfile/SaveLdrRcraCode`,
  p_saveLdrUhc: (req) => `${host()}/api/MniProfile/SaveLdrUhc`,
  p_getAllProfilesList: (req) => `${host()}/api/MniProfile/GetProfileList`,
  g_wasteStreamRanks: ({ dates: [from, to] }) =>
    `${host()}/api/dashboard/waste-stream-rank?from=${dfA(from)}&to=${dfA(to)}`,
  // g_getAllTexasShippingWasteCodes: (req) =>
  //   `${host()}/api/MniProfile/GetAllTexasShippingWasteCodes`,
  p_documentLibrary: ({ dates: [from, to], index = 1, size = 20 }) =>
    `${host()}/api/FullCircleDocument/documentsLibrary?from=${dfA(
      from
    )}&to=${dfA(to)}&PageIndex=${index}&PageSize=${size}`,
  p_orderHistory: ({ dates: [from, to], index = 1, size, sort = "Asc" }) =>
    `${host()}/api/my-history?to=${dfA(
      to
    )}&PageIndex=${index}&PageSize=${size}&sort=${sort}&from=${dfA(from)}`,
  g_dashboardTreamentTypeTiles: () => `${host()}/api/dashboard/treatment-type`,
  g_myDocumentsCount: () => `${host()}/api/FullCircleDocument/count`,
  g_dashboardTotalWeightTile: () => `${host()}/api/fullcircletile/totalweight`,
  p_invoicesList: ({ dates: [from, to], index = 1, size = 20 }) =>
    `${host()}/api/invoice/list?fromDate=${dfA(from)}&to=${dfA(
      to
    )}&PageIndex=${index}&PageSize=${size}`,
  p_invoiceReport: ({ dates: [from, to], index = 1, size = 20 }) =>
    `${host()}/api/invoice/report?from=${dfA(from)}&to=${dfA(
      to
    )}&PageIndex=${index}&PageSize=${size}`,
  p_profileReport: ({ dates: [from, to], index = 1, size = 20 }) =>
    `${host()}/api/fullcircle/profile-report?from=${dfA(from)}&to=${dfA(
      to
    )}&PageIndex=${index}&PageSize=${size}`,
  g_topProfiles: () => `
    ${host()}/api/fullcircle/top-profiles
  `,
  g_FullAlerts: ({ dates: [from, to] }) => `
    ${host()}/api/dataload-alert?from=${dfA(from)}&to=${dfA(
    to
  )}&alertType=full_circle`,
  pa_logOut: () => `${host()}/api/user/logout`,
  pa_updateLastLogin: () => `${host()}/api/user/updatelastlogin`,
  g_userTypes: ({ source = "" }) =>
    `${host()}/api/user/usertypes?source=${source}`,
  g_onboardedcustomers: ({ searchText = "" }) =>
    `${host()}/api/user/onBoardedCustomers?SearchText=${searchText}`,
  g_generatorsbycustomer: ({ customerId = "", store_number = "" }) =>
    `${host()}/api/location/generatorsInfo?customer_id=${customerId}&store_number=${store_number}`,
  g_customerBySearchText: ({ searchText }) =>
    `${host()}/api/user/customersbysearchtext?searchText=${searchText}`,
  g_generatorsbystorenumber: ({ customerId, store_number }) =>
    `${host()}/api/location/generatorsbystorenumber?customer_id=${customerId}&store_number=${store_number}`,
  g_getbusinesssegment: () => `${host()}/api/user/getBusinessSegments`,
  g_getbusinesssegmententerprisecustomers: ({
    roleId,
    customerAzId,
    businessSegmentId,
  }) =>
    `${host()}/api/user/OnboardedEnterpriseCustomers?role=${roleId}&customer_azid=${customerAzId}&businessSegmentId=${businessSegmentId}`,
  g_getbusinesssegmentcustomers: ({ searchText = "", businessSegmentId = 0 }) =>
    `${host()}/api/user/getBusinessSegmentCustomers?searchText=${searchText}&businessSegId=${parseInt(
      businessSegmentId
    )}`,
  p_createUser: () => `${host()}/api/user`,
  p_searchUsers: () => `${host()}/api/user/usersRolesInfo`,
  pa_edituser: () => `${host()}/api/user/updateUserInfo`,
  p_getProfileById: (req) => `${host()}/api/MniProfile/GetProfileRequestById`,
  g_getProfileById: (req) =>
    `${host()}/api/MniProfile/GetProfileById?ProfileRequestId=${req}`,
  p_getCloneProfile: (req) => `${host()}/api/MniProfile/CloneProfile`,
  p_getPrintLabel: (req) => `${host()}/api/MniProfile/GetPrintLableDetails`,
  g_getAllProfileTemplates: () =>
    `${host()}/api/MniProfile/GetAllProfileTemplates`,
  p_recertifyProfile: (req) => `${host()}/api/MniProfile/ProfileRecertify`,
  g_getBrands: ({ customerId }) =>
    `${host()}/api/location/divisions?custId=${customerId}`,
  pu_deleteProfile: (req) => `${host()}/api/MniProfile/DeleteProfile`,
  p_annualServiceSchedule: ({ year, index = 1, size = 20 }) =>
    `${host()}/api/manifest/annualserviceschedule?year=${year}&PageIndex=${index}&PageSize=${size}`,
  pa_updateDefaultCustomer: ({
    customer_id,
    shouldUpdateDefaultCustomer,
    generatorId = "",
  }) =>
    `${host()}/api/user/updateDefaultCustomer?generatorId=${generatorId}&customer_id=${customer_id}&shouldUpdateDefaultCustomer=${shouldUpdateDefaultCustomer}`,
  g_weightPickedUp: ({ dates: [from, to] }) =>
    `${host()}/api/dashboard/manifest/tile/weightpickedup?from=${dfA(
      from
    )}&to=${dfA(to)}&today=${dfA(new Date())}`,
  g_totalSpends: ({ dates: [from, to] }) =>
    `${host()}/api/dashboard/order/tile/totalspends?from=${dfA(from)}&to=${dfA(
      to
    )}&today=${dfA(new Date())}`,
  p_profilelistingreport: (req) =>
    `${host()}/api/MniProfile/GenerateProfileListingReports?PageIndex=${
      req?.index
    }&PageSize=${req?.size}`,
  p_getAllDotNames: (req) => `${host()}/api/MniProfile/GetDotNames`,
  g_scheduled_Stops: ({ dates: [from, to] }) =>
    `${host()}/api/dashboard/order/tile/scheduled-stops?from=${dfA(
      from
    )}&to=${dfA(to)}&today=${dfA(new Date())}`,
  g_stops_Completed: ({ dates: [from, to] }) =>
    `${host()}/api/dashboard/order/tile/stops-completed?from=${dfA(
      from
    )}&to=${dfA(to)}&today=${dfA(new Date())}`,
  p_createannouncements: () => `${host()}/api/announcements/createannouncement`,
  pa_updateLastViewedAnnnouncement: ({ announcementId = 0 }) =>
    `${host()}/api/announcements/updatelastviewedannouncement?announcementId=${announcementId}`,
  p_getAllCEUsers: () => `${host()}/api/user/getAllCEUsers`,
  g_getannouncements: ({ index = 1, size = 20, sortColumn, sortOrder }) =>
    `${host()}/api/announcements?PageIndex=${index}&PageSize=${size}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
  pa_updateAnnouncemet: ({ deleteAnnouncement = false }) =>
    `${host()}/api/announcements/updateAnnouncement?deleteAnnouncement=${deleteAnnouncement}`,
  g_getprofilelist: () => `${host()}/api/report/profile-list`,
  g_getbusinesssegmententerpriseforselectedmail: ({ emailId = "" }) =>
    `${host()}/api/user/onBoardedEnterpriseCustomers?&email=${emailId}`,
  g_getenterpriselist: ({
    roleId = 0,
    businessSegmentId = 0,
    searchText = "",
    emailid = "",
  }) =>
    `${host()}/api/user/OnboardedEnterprises?role=${roleId}&selectedbusinessSegmentId=${businessSegmentId}&searchText=${searchText}&email=${emailid} `,
}

export default urls
export { host }
