import React, { useEffect, useState, useRef } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { logOut } from "msal"
import { useHarsco, isHomeDepot } from "shared"
import _ from "lodash"
import { sideMenuList, fullCirclMenuList, mandIMenuList } from "./config"
import { roles, BusinessSegmentId } from "config"
import { useSelector, useDispatch } from "react-redux"
import api from "api"
import { KNOW_YOUR_CSR } from "store/reducer/actionType"

let enhancer =
  (Component) =>
  ({ isTablet, toggleSidebar, ...props }) => {
    let history = useHistory()
    let location = useLocation()
    const {
      profile,
      userRole,
      isStoreManager,
      storeId,
      isFullCircle,
      isMandI,
    } = useHarsco()
    const user = profile
    let sideNavmenu = [...sideMenuList]
    sideNavmenu =
      !profile.isHomeDepotUser && profile.isStoreLevelUser
        ? sideNavmenu.filter((d) => d.path != "/reportgenerator")
        : sideNavmenu
    const [sideNav, setSideNav] = useState([...sideNavmenu])
    const dispatch = useDispatch()
    const knowyourc = useSelector(
      (state) => state.generatorDetails?.knowyourcsr
    )
    const [showSubMenu, setShowSubMenu] = useState(true)
    const { changeCustomer } = useSelector((state) => state)
    const isKnowFetched = useRef(false)
    const [isCsr, setIsCsr] = useState(changeCustomer?.customer?.v)

    const isMounted = useRef(false)
    const businessSegmentId = changeCustomer?.customer?.businessSegmentId

    const condition =
      user?.roleId !== roles.CUSTOMER_ADMIN &&
      user?.roleId !== roles.CE_ADMIN &&
      user?.roleId !== roles.PORTAL_ADMIN

    /**
     *  dependency array value 'changeCustomer' is responsible for invoking this method
     *  when global customer is changed
     */
    useEffect(() => {
      const { REACT_APP_CAPDB_CUSTOMER_ID } = process.env
      //this condition for admin tile
      if (condition) {
        sideNavmenu.forEach((d, i) => {
          if (d?.admin) {
            sideNavmenu?.splice(i, 1)
          }
        })
      }

      //removing new request history for CE_GENERIC_USER
      if (user?.roleId === roles.CE_GENERIC_USER) {
        sideNavmenu.forEach((d, i) => {
          if (d?.path === "/requestservice") {
            sideNavmenu?.splice(i, 1)
          }
        })
      } else {
        sideNavmenu.forEach((d, i) => {
          if (user?.roleId === roles.SITE_USER && d?.path === "/support") {
            sideNavmenu?.splice(i, 1)
          }
          if (d?.path === "/requestserviceView") {
            sideNavmenu?.splice(i, 1)
          }
        })
      }

      //this condition for checking home customer
      // if not home depot remove historical ldr
      // if home depot adding historical ldr
      if (!isHomeDepot()) {
        setSideNav(sideNavmenu.filter((d) => !d.homeDepot))
      } else {
        setSideNav(
          sideNavmenu.filter(
            (d) =>
              !(
                (d.path === "/documentlibrary" ||
                  d.path === "/documentlibrarystores") &&
                !d.homeDepot
              )
          )
        )
      }
      if (isFullCircle) {
        let menu = [...fullCirclMenuList]
        let sideMenu = []
        // isCapDBCustomerUser is checking for capdb customers from environment variables
        let isCapDBCustomerUser = REACT_APP_CAPDB_CUSTOMER_ID.includes(
          changeCustomer?.customer?.v
        )

        menu.forEach((d, i) => {
          //add or remove capDb tab in the sideMenu
          if (
            !(
              (condition && d?.admin) ||
              (!isCapDBCustomerUser && d.path === "/capDB")
            )
          ) {
            sideMenu = [...sideMenu, d]
          }
        })
        setSideNav(sideMenu)
      }
      if (isMandI) {
        let menu = [...mandIMenuList]
        let sideMenu = []
        // isCapDBCustomerUser is checking for capdb customers from environment variables
        // let isCapDBCustomerUser = REACT_APP_CAPDB_CUSTOMER_ID.includes(
        //   changeCustomer?.customer?.v
        // )

        menu.forEach((d, i) => {
          //add or remove capDb tab in the sideMenu
          if (
            !(
              (condition && d?.admin)
              //  ||
              // (!isCapDBCustomerUser && d.path === "/capDB")
            )
          ) {
            sideMenu = [...sideMenu, d]
          }
        })
        setSideNav(sideMenu)
      }
    }, [changeCustomer])

    useEffect(() => {
      if (isMounted.current) {
        history.push("/")
      } else {
        isMounted.current = true
      }
    }, [businessSegmentId])

    const onLogosClick = () => {
      const newWindow = window.open(
        `https://www.cleanearthinc.com/`,
        "_blank",
        "noopener,noreferrer"
      )
      if (newWindow) newWindow.opener = null
    }

    const onEditProfileClick = () => {
      history.push("/profile")
    }

    const isActive = (statename) => {
      const { userPreferences } = user
      const { is_quickTour_completed } = JSON.parse(userPreferences)
      if (
        profile?.businessSegmentId === BusinessSegmentId?.FULL_CIRCLE ||
        profile?.businessSegmentId === BusinessSegmentId["M&I"] ||
        is_quickTour_completed
      ) {
        if (statename.trim() === "/") {
          return location.pathname === statename
        } else return location.pathname.includes(statename)
      }
    }
    const knowyourcsr = async () => {
      try {
        let res = await api.g_getKnowyourCSR()
        dispatch({ type: KNOW_YOUR_CSR, payload: res })
        if (!res) return
        //return res
      } catch (error) {}
    }

    // useEffect(() => {
    //   if (changeCustomer?.customer?.businessSegmentId ===
    //     BusinessSegmentId["M&I"]) {
    //     knowyourcsr()
    //   }
    // }, [])

    useEffect(() => {
      if (isCsr !== changeCustomer?.customer?.v) {
        isKnowFetched.current = false
        setIsCsr(changeCustomer?.customer?.v)
      }
      if (
        changeCustomer?.customer?.businessSegmentId === BusinessSegmentId["M&I"]
      ) {
        if (isKnowFetched.current === false) {
          isKnowFetched.current = true
          knowyourcsr()
        }
      }
    }, [changeCustomer?.customer?.v])

    const isActiveSubMenu = (path) => {
      return location.pathname === path
    }

    const navigate = async (path, subMenu, val) => {
      if (path == "/logout") {
        await api.pa_logOut()
        return logOut()
      }
      _.isFunction(path) && path()
      _.isString(path) && history.push(path)
      isTablet && toggleSidebar()

      if (!val) {
        if (subMenu) setShowSubMenu(!showSubMenu)
        else setShowSubMenu(false)
      }

      if (path == "/capDB") {
        history.push("/")
        window.open("https://fullcircle.cleanearthinc.com/")
      }
    }

    return (
      <Component
        {...props}
        {...{
          user,
          sideNav,
          onLogosClick,
          onEditProfileClick,
          userRole,
          isStoreManager,
          isActive,
          navigate,
          storeId,
          isActiveSubMenu,
          showSubMenu,
          isFullCircle,
          isMandI,
          profile,
          BusinessSegmentId,
          knowyourc,
        }}
      />
    )
  }

export default enhancer
